import React, {PropsWithChildren, useMemo} from "react";
import {AriaButtonProps, useButton} from "react-aria";

import {useMode} from "../Mode/useMode";
import styles from "../../action/Select/Select.module.css";

export function SelectButton({
	buttonRef,
	...props
}: PropsWithChildren &
	AriaButtonProps & {buttonRef: React.MutableRefObject<null>}) {
	const {buttonProps} = useButton(props, buttonRef);
	const {style} = useMode("select");

	return (
		<button
			{...buttonProps}
			className={styles.selectButton}
			ref={buttonRef}
			style={style}
		>
			{props.children}
		</button>
	);
}
