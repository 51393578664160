import React, {useCallback, useRef} from "react";
import {AriaMenuProps, useMenuTrigger} from "react-aria";
import {MenuTriggerProps, useMenuTriggerState} from "react-stately";
import {Popover} from "../../internal/Popover/Popover";
import styles from "./MenuButton.module.css";
import {Menu} from "../../internal/Menu/Menu";
import {InnerMenuButton} from "../../internal/Menu/InnerMenuButton";

export interface IMenuButton<T>
	extends Omit<AriaMenuProps<T>, "selectionMode">,
		MenuTriggerProps {
	label?: string;
}

/** Accept user choice from dropdown menu */
export function MenuButton<T extends object>(props: IMenuButton<T>) {
	const buttonRef = useRef(null);
	const state = useMenuTriggerState(props);
	const {menuTriggerProps, menuProps} = useMenuTrigger<T>({}, state, buttonRef);

	const toggleMenuOpen = useCallback(() => {
		state.toggle();
	}, []);

	return (
		<>
			<InnerMenuButton
				{...menuTriggerProps}
				onPress={toggleMenuOpen}
				buttonRef={buttonRef}
				isOpen={state.isOpen}
			>
				{props.label}
				<span aria-hidden="true" className={styles.menuButtonArrow}>
					▼
				</span>
			</InnerMenuButton>
			{state.isOpen && (
				<Popover state={state} triggerRef={buttonRef} placement="bottom start">
					<Menu {...props} {...menuProps} />
				</Popover>
			)}
		</>
	);
}
