// Fast console log using a ring buffer. See Wikipedia: https://en.wikipedia.org/wiki/Circular_buffer
// Read pointer offset from beginning (4 bytes), write pointer offset from beginning (4 bytes), atomically accessed
// Console log on the worker side will BLOCK if the ring buffer is full.

// The 8-byte ring buffer header looks like so:
//   struct Header {
//     uint32_t readPointer;
//     uint32_t writePointer;
//   }
// Each line is written like so:
//   struct Line {
//     uint32_t length;
//     uint8_t bytes[length];
//   }
// The ring buffer starts immediately after the ring buffer header, and has length RING_BUFFER_SIZE_BYTES.

const DEFAULT_RING_BUFFER_SIZE = (1 << 20) - 8;

/**
 * Shepherds student logging (through Java's System.out) to the main thread.
 *
 * The main thread initializes the shared array buffer, and the worker thread attaches to it. The mechanics are
 * explained above and in the implementation of enqueueLine (worker thread only) and dequeueLines (main thread only).
 * There should be one main-thread ConsoleLog instance per messenger, and one corresponding instance on its worker.
 */
export class ConsoleLog {
	readonly RING_BUFFER_SIZE_BYTES; // dynamically set based on the actual chosen size
	sab: SharedArrayBuffer;
	ringBuffer: Uint8Array; // View of the ring buffer portion
	rwPointers: Int32Array; // View of the read/write pointer portion

	private constructor(sab: SharedArrayBuffer) {
		this.RING_BUFFER_SIZE_BYTES = sab.byteLength - 8;
		this.sab = sab;
		this.ringBuffer = new Uint8Array(sab).subarray(8);
		this.rwPointers = new Int32Array(sab).subarray(0, 2);
	}

	isSharedArrayInitialized() {
		return !!this.sab;
	}

	/**
	 * Instantiate the shared array buffer. Main thread only.
	 */
	static mainThreadInitLog(
		ringBufferSize: number = DEFAULT_RING_BUFFER_SIZE
	): ConsoleLog {
		return new ConsoleLog(
			new SharedArrayBuffer(
				ringBufferSize + 8 /* space for read/write pointers */
			)
		);
	}

	/**
	 * Set the shared array buffer to send console logs to. Worker only.
	 * @param sab_ The shared array buffer.
	 */
	static workerThreadAttachLog(sab_: SharedArrayBuffer): ConsoleLog {
		return new ConsoleLog(sab_);
	}

	static READ_POINTER_LOCATION = 0;
	static WRITE_POINTER_LOCATION = 1;

	currentReadPointer() {
		return Atomics.load(this.rwPointers, ConsoleLog.READ_POINTER_LOCATION);
	}

	currentWritePointer() {
		return Atomics.load(this.rwPointers, ConsoleLog.WRITE_POINTER_LOCATION);
	}

	static textEncoder = new TextEncoder();
	static textDecoder = new TextDecoder();

	/**
	 * Atomically write a line to the queue. Throws if the line is too long, and (busily) blocks while the queue is full.
	 * Worker thread only.
	 * @param line The string line to append.
	 */
	enqueueLine(line: string) {
		const stringBytes = ConsoleLog.textEncoder.encode(line);
		const bytes = new Uint8Array(stringBytes.length + 4);
		// write the string starting at byte 4
		bytes.set(stringBytes, 4);
		// write the string byte length at byte 0, little endian
		new DataView(bytes.buffer).setInt32(
			0,
			stringBytes.length,
			true /* little endian */
		);

		const length = bytes.byteLength;
		if (length >= this.RING_BUFFER_SIZE_BYTES - 5) {
			console.error(`Line is too long (${length} bytes); skipped`);
			return;
		}

		const writePointer = this.currentWritePointer();
		// [writePointer:writeEnd) will get written to, possibly wrapping around
		const writeEnd = (writePointer + length) % this.RING_BUFFER_SIZE_BYTES;

		let readPointer;
		do {
			// Note that this is spontaneously mutated by the main thread; that's how we can escape the loop :)
			readPointer = this.currentReadPointer();

			// In the first case (writeEnd < writePointer), writing the string will wrap around the ring buffer. For
			// simplicity we just wait until the read pointer matches the write pointer, i.e., the main thread has
			// finished dequeuing everything.

			// In the second case, we're just going to write to the range [writePointer:writeEnd).
			// We require that readPointer <= writePointer, which is a bit conservative, but eh:
			//  write pointer
			//        ↓         ↓ read pointer               ↓ writeEnd
			// ┌──────┬─────────┬────────────────────────────┬────────┐
			// │      W█████████R░░░░░░░░░░░░░░░░░░░░░░░░░░░WE        │
			// └──────┴─────────┴────────────────────────────┴────────┘
			//        ←────────────────length────────────────→  writeEnd
			// ←──────────────────────────────────────────────────────→ RING_BUFFER_SIZE_BYTES
		} while (
			writeEnd < writePointer
				? readPointer !== writePointer
				: readPointer > writePointer
		);

		// We now have space: write it
		if (writeEnd <= writePointer) {
			// Wrap around. Memcpy first range ...
			const firstRangeSize = this.RING_BUFFER_SIZE_BYTES - writePointer;
			this.ringBuffer.set(bytes.subarray(0, firstRangeSize), writePointer);
			// ... and memcpy second range
			this.ringBuffer.set(bytes.subarray(firstRangeSize), 0);
		} else {
			// Memcpy full range
			this.ringBuffer.set(bytes, writePointer);
		}

		// Commit it
		Atomics.store(this.rwPointers, ConsoleLog.WRITE_POINTER_LOCATION, writeEnd);
	}

	/**
	 * While there are lines to dequeue, pop off the ring buffer. You can read these lazily to prevent crashes on the main
	 * thread.
	 */
	*dequeueLines(): Generator<string> {
		const readNBytes = (n: number): Uint8Array => {
			if (n > this.RING_BUFFER_SIZE_BYTES - 4) {
				return new Uint8Array([]); // silently fail to avoid crashes. Shouldn't happen though
			}

			const readPointer = this.currentReadPointer();
			if (readPointer + n >= this.RING_BUFFER_SIZE_BYTES) {
				// wrap
				const initial = new Uint8Array(n);
				const part1Length = readPointer + n - this.RING_BUFFER_SIZE_BYTES;

				initial.set(this.ringBuffer.subarray(readPointer));
				initial.set(
					this.ringBuffer.subarray(0, part1Length),
					this.RING_BUFFER_SIZE_BYTES - readPointer
				);

				Atomics.store(
					this.rwPointers,
					ConsoleLog.READ_POINTER_LOCATION,
					(readPointer + n) % this.RING_BUFFER_SIZE_BYTES
				);

				return initial;
			} else {
				Atomics.store(
					this.rwPointers,
					ConsoleLog.READ_POINTER_LOCATION,
					readPointer + n
				);

				return new Uint8Array(
					this.ringBuffer.subarray(readPointer, readPointer + n)
				);
			}
		};

		while (this.logBufferHasLines()) {
			// Pop off a line
			const lengthBytes = readNBytes(4);
			// Decode, little endian
			const length =
				lengthBytes[0]! |
				(lengthBytes[1]! << 8) |
				(lengthBytes[2]! << 16) |
				(lengthBytes[3]! << 24);
			const string = readNBytes(length);

			yield ConsoleLog.textDecoder.decode(string);
		}
	}

	/**
	 * Whether there are lines to read off using dequeueLines.
	 */
	logBufferHasLines() {
		return this.currentReadPointer() !== this.currentWritePointer();
	}

	/**
	 * Clear the log from the main thread (i.e., skip all unread lines).
	 */
	mainThreadClearLog() {
		Atomics.store(
			this.rwPointers,
			ConsoleLog.READ_POINTER_LOCATION,
			this.currentWritePointer()
		);
	}

	getSab() {
		return this.sab;
	}
}
