import React from "react";
import {observer} from "mobx-react-lite";
import styles from "./MethodManifestModal.module.scss";

import type {FunctionTemplate} from "../../@types/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTurnDownLeft} from "@fortawesome/sharp-solid-svg-icons/faArrowTurnDownLeft";
import {faDash} from "@fortawesome/sharp-solid-svg-icons/faDash";
import {faXmark} from "@fortawesome/sharp-solid-svg-icons/faXmark";

type Props = {
	methodList: Record<string, FunctionTemplate[]>;
	setMethodCall: (methodObj: FunctionTemplate) => void;
	setMethodCallClass: (methodClass: string) => void;
	setIsCalling: (isCalling: boolean) => void;
	toggle: () => void;
};

function shouldDisableFn(functionCall: FunctionTemplate) {
	return [functionCall.returnType, ...functionCall.parameterTypes].some((f) => {
		switch (f) {
			case "void":
			case "int":
			case "long":
			case "short":
			case "byte":
			case "double":
			case "float":
			case "boolean":
			case "String":
				return false;
		}
		return true;
	});
}

function MethodList(props: Props) {
	function progressToMethodCall(
		className: string,
		methodObj: FunctionTemplate
	) {
		props.setMethodCallClass(className);
		props.setMethodCall(methodObj);
		props.setIsCalling(true);
	}

	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalHeaderContainer}>
				<div className={styles.modalHeader}>Method Call</div>
				<button className={styles.modalXIconWrapper}>
					<FontAwesomeIcon
						icon={faXmark}
						className={styles.modalXIcon}
						onClick={props.toggle}
					/>
				</button>
			</div>
			<div className={styles.methodListContainer}>
				{Object.entries(props.methodList).map(([key, value]) => {
					return (
						<div key={key}>
							<div className={styles.className}>
								{key.replace(/\$\$\$\d+/, "")}
							</div>
							{value.map((method) => {
								return (
									<div
										key={
											method.name + "(" + method.parameterTypes.join(", ") + ")"
										}
										className={[
											styles.classMethod,
											shouldDisableFn(method) ? styles.methodCallDisabled : "",
										].join(" ")}
										onClick={() =>
											progressToMethodCall(key.replace(/\$\$\$\d+/, ""), method)
										}
									>
										{method.returnType === "void" ? (
											<FontAwesomeIcon
												className={styles.methodListIcon}
												icon={faDash}
											/>
										) : (
											<FontAwesomeIcon
												className={styles.methodListIcon}
												icon={faArrowTurnDownLeft}
											/>
										)}
										{method.returnType} {method.name}{" "}
										<span className={styles.methodParentheses}>
											({method.parameterTypes.join(", ")})
										</span>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
			<div className={styles.buttonContainer}>
				<button className={styles.cancelButton} onClick={props.toggle}>
					Cancel
				</button>
			</div>
		</div>
	);
}

export default observer(MethodList);
