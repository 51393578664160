import {CSSWithVariables} from "../../types";
import React, {PropsWithChildren} from "react";
import styles from "./Grid.module.css";
import {SpaceTokenName} from "../../types";
import {makeSpaceCSSVariable} from "../../internal/flattenObjectToCSS";

export interface IGrid extends PropsWithChildren {
	readonly gap?: 0 | SpaceTokenName;
}

/** Align children as grid with custom or default `gap`. */
export function Grid({children, gap = "space-2"}: IGrid) {
	return (
		<div
			className={styles.grid}
			style={
				{
					"--grid-gap": makeSpaceCSSVariable(gap),
				} as CSSWithVariables
			}
		>
			{children}
		</div>
	);
}
