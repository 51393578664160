// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ide-module__ideWrapper--gJa6l {
  /* Eat the remaining space on the page without overflowing. */
  flex: 1 1 auto;
  min-width: 0;
  height: 100vh;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/Ide/Ide.module.css"],"names":[],"mappings":"AAAA;EACC,6DAAA;EACA,cAAA;EACA,YAAA;EAEA,aAAA;EACA,kBAAA;AAAD","sourcesContent":[".ideWrapper {\n\t/* Eat the remaining space on the page without overflowing. */\n\tflex: 1 1 auto;\n\tmin-width: 0;\n\n\theight: 100vh;\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ideWrapper": `Ide-module__ideWrapper--gJa6l`
};
export default ___CSS_LOADER_EXPORT___;
