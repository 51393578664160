import React from "react";
import {observer} from "mobx-react-lite";

import MonacoEditor from "../CodeEditor/MonacoEditor";
import CodeMirrorEditor from "../CodeEditor/CodeMirrorEditor";
import LogConsole from "../Console/LogConsole";
import MethodManifestModal from "../Modals/MethodManifestModal";
import styles from "./Ide.module.css";
import {useCsContext} from "../../state/CsInstance";

const Ide: React.FC = () => {
	const {featureFlagStore} = useCsContext();

	return (
		<div className={styles.ideWrapper}>
			{featureFlagStore.useCodeMirror ? <CodeMirrorEditor /> : <MonacoEditor />}
			<LogConsole />
			<MethodManifestModal />
		</div>
	);
};

export default observer(Ide);
