import React, {
	ReactNode,
	useCallback,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import throttle from "lodash/throttle";
import styles from "./FitSwap.module.css";

export interface IFitSwap {
	readonly children: [ReactNode, ReactNode];
}

/**
 * Must have exactly two children. Displays the first child if it fits in 
 * the available space. Otherwise, displays the second child (whether that
 * child fits or not).
 * 
 * Note: Both children are technically rendered by react so as to keep the
 * state intact when switching back and forth.
 *
 * @example
 * // Example usage:
 * <FitSwap>
 *     <div>This is the primary content. It will be visible if it fits.</div>
 *     <div>This shows up if the primary content doesn't fit</div>
 * </FitSwap>
 */
export const FitSwap = (props: IFitSwap) => {
	const {children} = props;
	const [primaryChild, secondaryChild] = children;
	const [showMainContent, setShowMainContent] = useState(true);
	const parentRef = useRef<HTMLDivElement>(null);
	const childrenRef = useRef<HTMLDivElement>(null);
	const primaryContentRef = useRef<HTMLDivElement>(null);
	
	const callback = useCallback(() => {
		const parentElement = parentRef.current;
		const childrenElement = childrenRef.current;
		const primaryContentElement = primaryContentRef.current;
		if (!parentElement || !childrenElement || !primaryContentElement) {
			return;
		}
		if (showMainContent) {
			if (childrenElement.scrollWidth > parentElement.clientWidth) {
				setShowMainContent(false);
			}
		} else {
			if (parentElement.clientWidth >= primaryContentElement.scrollWidth) {
				setShowMainContent(true);
			}
		}
	}, [children, showMainContent, setShowMainContent]);
	
	useLayoutEffect(callback, []);
	
	useLayoutEffect(() => {
		const parentElement = parentRef.current;
		const primaryContentElement = primaryContentRef.current;
		if (!parentElement || !primaryContentElement) {
			return;
		}
		const resizeObserver = new ResizeObserver(throttle(callback, 50, {leading: true, trailing: true}));
		resizeObserver.observe(parentElement);
		resizeObserver.observe(primaryContentElement);
		return () => {
			resizeObserver.disconnect();
		}
	}, [callback]);
	
	return (
		<div ref={parentRef} className={styles.parent}>
			<div ref={childrenRef}>
				<div
					ref={primaryContentRef}
					className={showMainContent ? styles.children : styles.hiddenChildren}
					aria-hidden={!showMainContent}
				>
					{primaryChild}
				</div>
				<div
					className={!showMainContent ? styles.children : styles.hiddenChildren}
					aria-hidden={showMainContent}
				>
					{secondaryChild}
				</div>
			</div>
		</div>
	)
}
