import React, {
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useRef,
} from "react";
import {useOverlayTrigger} from "react-aria";
import {OverlayTriggerProps, useOverlayTriggerState} from "react-stately";
import {
	IPopover,
	Popover as InternalPopover,
} from "../../internal/Popover/Popover";
import {Dialog} from "../../internal/Dialog/Dialog";

export interface IUsePopover
	extends Omit<OverlayTriggerProps, "defaultOpen" | "onOpenChange">,
		Pick<IPopover, "shouldFlip" | "placement"> {
	/* Descriptive title for accessibility technology */
	readonly ariaLabel: string;
	/** Whether the Popover is shown */
	readonly isOpen: boolean;
	/** Setter for `isOpen` */
	readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Get a controlled Popover with associated triggerProps to spread over the button or triggering element.
 *
 * @param isOpen Whether the Popover is open
 * @param setIsOpen State setter for `isOpen`
 * @param ariaLabel Descriptive title for accessibility technology
 * @returns `Popover`, spreadable `triggerProps` which semantically connect the trigger to the Popover, and `ref` for where the Popover will attach
 */
export function usePopover({
	isOpen,
	setIsOpen,
	ariaLabel,
	...popoverProps
}: IUsePopover) {
	const ref = useRef(null);

	const state = useOverlayTriggerState({isOpen, onOpenChange: setIsOpen});
	const {triggerProps} = useOverlayTrigger({type: "dialog"}, state, ref);

	const Popover = ({children}: PropsWithChildren) =>
		isOpen ? (
			<InternalPopover state={state} triggerRef={ref} {...popoverProps}>
				<Dialog padding={0} backgroundColor="transparent">
					{children}
				</Dialog>
			</InternalPopover>
		) : (
			<></>
		);

	return {
		Popover,
		ref,
		triggerProps,
	};
}
