import React, {MutableRefObject, PropsWithChildren} from "react";
import {AriaButtonProps, useButton} from "react-aria";
import {useMode} from "../Mode/useMode";
import styles from "../../action/MenuButton/MenuButton.module.css";
import clsx from "clsx";

interface IInnerMenuButton extends PropsWithChildren, AriaButtonProps {
	buttonRef: MutableRefObject<null>;
	isOpen: boolean;
}

export function InnerMenuButton({
	buttonRef,
	isOpen,
	...props
}: IInnerMenuButton) {
	const {buttonProps} = useButton(props, buttonRef);
	const {style} = useMode("menuButton");

	return (
		<button
			{...buttonProps}
			ref={buttonRef}
			className={clsx(styles.menuButton, {
				[styles.menuButtonWithOpenMenu]: isOpen,
			})}
			style={style}
		>
			{props.children}
		</button>
	);
}
