import {createContext} from "react";
import {Palette, ComponentMode} from "../../types";

interface IModeContext<P extends Palette> {
	readonly mode: ComponentMode<P>;
	readonly layeredMode: ComponentMode<P>;
}
export const ModeContext = createContext<IModeContext<Palette>>({
	mode: {},
	layeredMode: {},
});
