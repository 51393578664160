// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileTree-module__fileTreeContainer--fjo4O {
  width: 100%;
  min-height: 10rem;
  background-color: white;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 1rem;
  font-family: var(--font-family);
  flex-shrink: 1; /* Take up no more than the remaining height */
  overflow-y: scroll; /* to allow scrolling */
  scrollbar-width: none; /* hide scrollbar: Firefox */
  user-select: none;
  border-left: 1px solid var(--theater-border-color);
  border-right: 1px solid var(--theater-border-color);
}

.FileTree-module__fileTreeContainer--fjo4O::-webkit-scrollbar {
  display: none; /* hide scrollbar: Safari and Chrome */
}

.FileTree-module__fileHeaderContainer--mWvPP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.875rem;
  margin-bottom: 0.75rem;
}

.FileTree-module__fileHeader--V0oSO {
  color: var(--file-navigator-color);
  font-family: var(--font-family);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FileTree/FileTree.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,iBAAA;EAEA,uBAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;EACA,eAAA;EACA,+BAAA;EACA,cAAA,EAAA,8CAAA;EACA,kBAAA,EAAA,uBAAA;EACA,qBAAA,EAAA,4BAAA;EACA,iBAAA;EAEA,kDAAA;EACA,mDAAA;AADD;;AAIA;EACC,aAAA,EAAA,sCAAA;AADD;;AAIA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EAEA,gBAAA;EACA,sBAAA;AAFD;;AAKA;EACC,kCAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAFD","sourcesContent":[".fileTreeContainer {\n\twidth: 100%;\n\tmin-height: 10rem;\n\n\tbackground-color: white;\n\tflex: 1 0 auto;\n\tflex-direction: column;\n\tpadding: 0.5rem;\n\tfont-size: 1rem;\n\tfont-family: var(--font-family);\n\tflex-shrink: 1; /* Take up no more than the remaining height */\n\toverflow-y: scroll; /* to allow scrolling */\n\tscrollbar-width: none; /* hide scrollbar: Firefox */\n\tuser-select: none;\n\n\tborder-left: 1px solid var(--theater-border-color);\n\tborder-right: 1px solid var(--theater-border-color);\n}\n\n.fileTreeContainer::-webkit-scrollbar {\n\tdisplay: none; /* hide scrollbar: Safari and Chrome */\n}\n\n.fileHeaderContainer {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\n\theight: 1.875rem;\n\tmargin-bottom: 0.75rem;\n}\n\n.fileHeader {\n\tcolor: var(--file-navigator-color);\n\tfont-family: var(--font-family);\n\tfont-size: 1rem;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: normal;\n\tletter-spacing: 0.01rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileTreeContainer": `FileTree-module__fileTreeContainer--fjo4O`,
	"fileHeaderContainer": `FileTree-module__fileHeaderContainer--mWvPP`,
	"fileHeader": `FileTree-module__fileHeader--V0oSO`
};
export default ___CSS_LOADER_EXPORT___;
