import {Padding, Palette} from "../types";

const keyToPalette: Record<string, keyof Palette> = {
	accentColor: "color",
	backgroundColor: "color",
	border: "border",
	borderColor: "color",
	borderRadius: "borderRadius",
	boxShadow: "boxShadow",
	color: "color",
	outline: "outline",
	outlineOffset: "space",
	outlineColor: "color",
	paddingBlock: "space",
	paddingInline: "space",
};

export function flattenObjectToCSSVariables(
	object: Record<string, any>,
	palette?: Record<string, any>,
	parent?: string,
	result: Record<string, any> = {}
) {
	for (const key in object) {
		const flattened = parent ? `${parent}-${key}` : `--${key}`;

		if (typeof object[key] == "object") {
			flattenObjectToCSSVariables(
				object[key],
				palette ?? {},
				flattened,
				result
			);
		} else {
			result[flattened] = object[key];

			if (palette && key in keyToPalette) {
				const paletteKey = keyToPalette[key];

				if (object[key] in palette[paletteKey]) {
					result[flattened] = `var(--theme-${paletteKey}-${object[key]})`;
				}
			}
		}
	}
	return result;
}

export function makeSpaceCSSVariable(token: Padding) {
	if (token === 0) return "0";
	const splits = token.split(" ");
	let padding = `var(--theme-${splits[0]})`;
	
	if (splits.length > 0) {
		for (let i = 1; i < splits.length; i++) {
			padding = padding + ` var(--theme-${splits[i]})`;
		}
	}
	
	return padding;
}
