import React, {useMemo} from "react";
import {PropsWithChildren, useContext, useRef} from "react";
import {
	AriaCheckboxGroupItemProps,
	VisuallyHidden,
	useCheckboxGroupItem,
	useFocusRing,
} from "react-aria";
import {CheckboxGroupContext} from "./CheckboxGroup";
import styles from "./CheckboxGroup.module.css";
import clsx from "clsx";
import {CheckboxContext} from "./CheckboxContext";
import {useMode} from "../../internal/Mode/useMode";

export interface ICheckbox
	extends PropsWithChildren,
		AriaCheckboxGroupItemProps {
	isCustom?: boolean;
}

/**
 * A default or custom checkbox for a Checkbox. If custom,
 * wrap the component with {@link Checkbox} and consume the CheckboxContext:
 *
 * ```
 * <Checkbox isCustom value="exampleValue">
 *   <CheckboxCard value="exampleValue" />
 * </Radio>
 *
 * function CheckboxCard({value}: {value: string}) {
 *		const {isSelected, isKeyboardFocused, isFocused, isPressed} = useContext(CheckboxContext);
 * 		// Use values to style and render card
 * }
 * ```
 */
export function Checkbox({isCustom, ...props}: ICheckbox) {
	const ref = useRef(null);
	const state = useContext(CheckboxGroupContext);
	const {inputProps, isDisabled, isPressed, isSelected} = useCheckboxGroupItem(
		props,
		state,
		ref
	);
	const {isFocusVisible, isFocused, focusProps} = useFocusRing();

	const VisuallyHiddenIfCustom = useMemo(
		() =>
			isCustom
				? ({children}: PropsWithChildren) => (
						<VisuallyHidden>{children}</VisuallyHidden>
				  )
				: ({children}: PropsWithChildren) => children,
		[isCustom]
	);
	const {style} = useMode("checkbox");

	return (
		<label
			className={clsx(styles.checkboxLabel, {
				[styles.checkboxLabelDisabled]: isDisabled,
			})}
		>
			<VisuallyHiddenIfCustom>
				<input
					{...inputProps}
					{...focusProps}
					ref={ref}
					className={styles.checkboxInput}
					style={style}
				/>
			</VisuallyHiddenIfCustom>
			<CheckboxContext.Provider
				value={{
					isFocused,
					isKeyboardFocused: isFocusVisible,
					isDisabled,
					isPressed,
					isSelected,
				}}
			>
				{props.children}
			</CheckboxContext.Provider>
		</label>
	);
}
