/**
 * This file contains the extension for the smart indenting feature in the editor.
 */

import {Extension} from "@codemirror/state";
import {EditorSelection, EditorView} from "@uiw/react-codemirror";
import {indentSelection} from "@codemirror/commands";

/**
 * Hotkey handler for smart indenting in the editor.
 *
 * `Shift + i + meta` will indent the whole document.
 *
 * @returns An extension that handles the indenting of the selection in the editor.
 */
export const indentSelectionHandler = () =>
	EditorView.domEventHandlers({
		keydown(event, view) {
			if (event.key.toLowerCase() === "i" && event.shiftKey && event.metaKey) {
				selectAndIndentWholeDocument(view);
				return true;
			}
			return false;
		},
	});

/**
 * Selects the whole document and indents it.
 * @param view The editor view to indent the whole document in.
 */
export const selectAndIndentWholeDocument = (view: EditorView) => {
	view.dispatch({
		selection: EditorSelection.create([
			EditorSelection.range(0, view.state.doc.length - 1),
		]),
	});
	indentSelection(view);
};

const indentExtension = (): Extension => [indentSelectionHandler()];

export default indentExtension;
