import React, {useEffect} from "react";
import {useCsContext} from "../../state/CsInstance";
import {observer} from "mobx-react-lite";
import {faEllipsis} from "@fortawesome/sharp-solid-svg-icons/faEllipsis";
import {faExpandWide} from "@fortawesome/sharp-solid-svg-icons/faExpandWide";
import {faPictureInPicture} from "@awesome.me/kit-8bed8353be/icons/kit/custom";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TheaterSize} from "../../state/theaterStore";
import {serializeToJson} from "../../util/messengerWorkerUtils";

import PixiCanvasButtons from "../PixiCanvasButtons/PixiCanvasButtons";
import styles from "./TheaterControls.module.scss";
import TheaterSettingsModal from "../TheaterModal/TheaterSettingsModal";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

type TheaterControlsProps = {
	buttonClass?: string;
};

const TheaterControls: React.FC<TheaterControlsProps> = (props) => {
	const {
		theaterStore,
		userInputStore,
		uiStore,
		compileStore,
		getOrCreateMessenger,
	} = useCsContext();
	const {buttonClass} = props;

	// TODO useCallback these
	function requestUpdateFromMessenger(numUpdates = 1) {
		const jsonString = serializeToJson(
			numUpdates,
			userInputStore.mouseX,
			userInputStore.mouseY,
			userInputStore.isMouseDown,
			userInputStore.getKeysPressed()
		);
		getOrCreateMessenger().update(numUpdates, jsonString, true);
	}

	function onClickPlayButton(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		userInputStore.isPixiFocused = true;

		theaterStore.isRunning = !theaterStore.isRunning;
		if (theaterStore.isRunning) {
			getOrCreateMessenger().onPlay(); // for onPlay()
		} else {
			getOrCreateMessenger().onPause(); // for onPause()
		}
		requestUpdateFromMessenger();
		getOrCreateMessenger().togglePixi();
		event.currentTarget.blur(); // deselect
	}

	function onClickStepButton() {
		theaterStore.isRunning = false;
		getOrCreateMessenger().stopPixi();
		requestUpdateFromMessenger();
	}

	function onClickResetButton() {
		getOrCreateMessenger().stopPixi();
		getOrCreateMessenger().resetCode();
		theaterStore.resetTheaterState();

		// TODO this is a hack to reset the console log, which should not live here
		compileStore.error = "";
		theaterStore.showConsole = false;
	}

	// When clicking outside the theater settings modal, close it.
	useEffect(() => {
		if (!uiStore.theaterSettingsOpen) return; // already closed
		function handleClick(e: MouseEvent) {
			if (e.target && uiStore.shouldCloseTheaterSettingsModal) {
				// close the modal unless a theater settings button was clicked
				uiStore.theaterSettingsOpen = false;
			}
			uiStore.shouldCloseTheaterSettingsModal = true; // reset the flag
		}

		// Defer the attachment of the document click listener to the next tick, after the current click (opening
		// the modal) is finished
		setTimeout(() => document.addEventListener("click", handleClick), 0);
		return () => document.removeEventListener("click", handleClick); // remove listener after closed
	}, [uiStore, uiStore.theaterSettingsOpen]);

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<div className={styles.theaterControlsContainer}>
				<PixiCanvasButtons
					onClickRunButton={onClickPlayButton}
					onClickStepButton={onClickStepButton}
					onClickResetButton={onClickResetButton}
					buttonClass={buttonClass}
				/>
				<div className={styles.theaterSecondaryControlsContainer}>
					<button
						className={[styles.theaterSecondaryControlButton].join(" ")}
						onClick={() => {
							if (theaterStore.theaterSize === TheaterSize.Large) {
								theaterStore.theaterSize = TheaterSize.Medium;
							} else if (theaterStore.theaterSize === TheaterSize.Medium) {
								theaterStore.theaterSize = TheaterSize.Large;
							}
						}}
					>
						{theaterStore.theaterSize === TheaterSize.Medium ? (
							<FontAwesomeIcon icon={faExpandWide} />
						) : (
							<FontAwesomeIcon icon={faPictureInPicture as IconDefinition} />
						)}
					</button>
					<div
						style={{
							position: "relative",
						}}
					>
						<button
							className={[styles.theaterSecondaryControlButton].join(" ")}
							onClick={() =>
								(uiStore.theaterSettingsOpen = !uiStore.theaterSettingsOpen)
							}
						>
							<FontAwesomeIcon icon={faEllipsis} />
						</button>
						<TheaterSettingsModal />
					</div>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default observer(TheaterControls);
