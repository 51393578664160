import {Messenger, MessengerProps} from "./messenger";
import CsInstance from "../state/CsInstance";

export class Manager {
	private _messenger?: Messenger;

	private _csInstance: CsInstance;

	constructor(csInstance: CsInstance) {
		this._csInstance = csInstance;
	}

	getOrCreateMessenger(messengerProps: MessengerProps = {}) {
		// TODO check if current messenger is dead
		if (!this._messenger) {
			this._csInstance.theaterStore.messengerLoading = true;
			this._messenger = new Messenger(this._csInstance, messengerProps);
		}
		return this._messenger;
	}

	prepNewVersionedJarMessenger(
		jarData: Uint8Array,
		version: number,
		javaConsoleLogCallback: (
			input: string
		) => void = this._csInstance.defaultConsoleLogCallback.bind(
			this._csInstance
		),
		studentInputCallback: (
			input: number
		) => void = this._csInstance.defaultConsoleInputCallback.bind(
			this._csInstance
		)
	) {
		this.getOrCreateMessenger({
			javaConsoleLogCallback,
			studentInputCallback,
		}).initCode(jarData, version);
	}

	isMessengerReady() {
		return false;
	}

	cleanupMessenger() {
		if (this._messenger) {
			this._messenger.destroy();
			this._messenger = undefined;
		}
	}
}
