import {CSSWithVariables} from "../../types";
import React, {PropsWithChildren} from "react";
import styles from "./Stack.module.css";
import {SpaceTokenName} from "../../types";
import {makeSpaceCSSVariable} from "../../internal/flattenObjectToCSS";
export interface IStack extends PropsWithChildren {
	gap?: 0 | SpaceTokenName;
	moveLastChildToBottom?: boolean;
}

/** Space children in a column. */
export function Stack({
	children,
	gap = "space-2",
	moveLastChildToBottom = false,
}: IStack) {
	const style = {
		"--stack-gap": makeSpaceCSSVariable(gap),
		"--stack-last-child-margin-block-start": moveLastChildToBottom
			? "auto"
			: undefined,
	} as CSSWithVariables;

	// Why a second outer div? We want nested Stacks to be spaced by the parent's --stack-gap, not their own.
	// By wrapping in a second div, the outer Stack can target the div as a child instead of the div with the
	// custom gap.
	return (
		<div className={styles.stack}>
			<div className={styles.stackInner} style={style}>
				{children}
			</div>
		</div>
	);
}
