import React, {useEffect, useRef} from "react";
import {useCsContext} from "../../state/CsInstance";

type Props = {
	height: number;
	width: number;
};

const PixiApp: React.FC<Props> = (props: Props) => {
	const csInstance = useCsContext();
	const canvasRef = useRef<HTMLCanvasElement>(null);

	// Only do this once
	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) {
			console.error("canvas not rendered when attempting to create PixiApp");
			return;
		}
		// Gives control to the webworker
		const view: OffscreenCanvas = canvas.transferControlToOffscreen();
		csInstance.getOrCreateMessenger().initCanvas({
			width: 800,
			height: 600,
			view,
		});

		// On unmount, release canvas to avoid piling up canvases
		return () => {
			csInstance.getOrCreateMessenger().destroyCanvas();
		};
	}, [csInstance]);

	return <canvas className="csStage" ref={canvasRef} style={props} />;
};

export default PixiApp;
