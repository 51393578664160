import React from "react";
import {useCsContext} from "../../state/CsInstance";
import {TheaterSize} from "../../../src/state/theaterStore";
import {observer} from "mobx-react-lite";

import {faBook} from "@fortawesome/sharp-solid-svg-icons/faBook";
import {faImages} from "@fortawesome/sharp-solid-svg-icons/faImages";
import {faSidebar} from "@fortawesome/sharp-solid-svg-icons/faSidebar";
import {faReadme} from "@fortawesome/free-brands-svg-icons/faReadme";
import {faFileArrowDown} from "@fortawesome/sharp-solid-svg-icons/faFileArrowDown";
import {faGear} from "@fortawesome/sharp-solid-svg-icons/faGear";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import type {IconProp} from "@fortawesome/fontawesome-svg-core";

import sidebarMethods from "../../util/sidebarMethods";

import styles from "./Sidebar.module.scss";

type SidebarProps = {
	showUploadModal: () => void;
};
function Sidebar(props: SidebarProps) {
	const {featureFlagStore, theaterStore, codeStore} = useCsContext();
	const {showUploadModal} = props;

	return (
		<div className={styles.sidebarContainer}>
			<div className={styles.sidebarPrimaryButtonsContainer}>
				<img
					crossOrigin="anonymous"
					src="https://cs-curriculum-assets.artofproblemsolving.com/PixiSprites/aops.png"
					alt="AopsTheater Logo"
					className={styles.logo}
				/>
				<div className={styles.divider} />
				<SidebarButton
					title={"Side Panel"}
					icon={
						<FontAwesomeIcon icon={faSidebar} className={styles.sidebarIcon} />
					}
					onClick={() => {
						if (theaterStore.theaterSize === TheaterSize.None) {
							theaterStore.theaterSize = TheaterSize.Medium;
						} else {
							theaterStore.theaterSize = TheaterSize.None;
						}
					}}
				/>
				<SidebarButton
					title={"Documentation"}
					icon={
						<FontAwesomeIcon icon={faBook} className={styles.sidebarIcon} />
					}
					onClick={() => sidebarMethods.documentation.onClick()}
				/>
				<SidebarButton
					title={"Image Assets"}
					icon={
						<FontAwesomeIcon icon={faImages} className={styles.sidebarIcon} />
					}
					onClick={() => sidebarMethods.imageAssets.onClick()}
				/>
				{featureFlagStore.showExtraButtons && (
					<>
						<div className={styles.helperButtonsContainer} />
						<SidebarButton
							title="README"
							icon={
								<FontAwesomeIcon
									// typecast for compatibility with different versions of FontAwesome
									icon={faReadme as IconProp}
									className={styles.sidebarIcon}
								/>
							}
							variant="clear"
							isActive={false}
						/>
						<SidebarButton
							title="Download"
							icon={
								<FontAwesomeIcon
									icon={faFileArrowDown}
									className={styles.sidebarIcon}
								/>
							}
							variant="clear"
							onClick={() => {
								const outputYaml = codeStore.outputActivityYaml();

								// https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
								const element = document.createElement("a");
								const file = new Blob([outputYaml], {type: "text/plain"});
								element.href = URL.createObjectURL(file);
								element.download = `${codeStore.project}.yaml`;
								document.body.appendChild(element); // Required for this to work in FireFox
								element.click();
							}}
						/>
					</>
				)}
			</div>
			<div className={styles.sidebarPrimaryButtonsContainer}>
				<SidebarButton
					title="Settings"
					icon={
						<FontAwesomeIcon icon={faGear} className={styles.sidebarIcon} />
					}
					isActive={featureFlagStore.showExtraButtons}
					onClick={showUploadModal}
				/>
			</div>
		</div>
	);
}

type SidebarButtonProps = {
	title: string;
	icon: JSX.Element;
	variant?: "default" | "clear";
	isActive?: boolean;
	onClick?: () => void;
};

function SidebarButton(props: SidebarButtonProps) {
	const {icon, title, variant = "default", isActive = true, onClick} = props;

	const classNames = [styles.sidebarButton];
	if (variant === "clear") {
		classNames.push(styles.sidebarButtonClear);
	}

	return (
		<button
			className={classNames.join(" ")}
			onClick={onClick}
			disabled={!isActive}
			title={title}
		>
			{icon}
		</button>
	);
}

export default observer(Sidebar);
