import React, {useMemo, useRef} from "react";
import type {PropsWithChildren} from "react";
import {AriaListBoxOptions, useListBox} from "react-aria";
import {ListState} from "react-stately";
import styles from "./ListBox.module.css";
import {ListBoxOption} from "./ListBoxOption";
import {CSSWithVariables} from "../../types";

import {useMode} from "../Mode/useMode";

export interface IListBox<T> extends AriaListBoxOptions<T> {
	readonly state: ListState<T>;
	readonly listBoxRef?: React.RefObject<HTMLUListElement>;
	readonly hasSelectedCheckmark?: boolean;
}

/**
 * ListBox
 */
export function ListBox<T>({hasSelectedCheckmark, ...props}: IListBox<T>) {
	const ref = useRef(null);
	const {listBoxRef = ref, state} = props;
	const {listBoxProps} = useListBox(props, state, listBoxRef);
	const collectionItems = useMemo(
		() => [...state.collection],
		[state.collection]
	);
	const {style} = useMode("select");

	return (
		<ul
			{...listBoxProps}
			ref={listBoxRef}
			className={styles.listBox}
			style={style}
		>
			{collectionItems.map((item) => (
				<ListBoxOption
					hasSelectedCheckmark={hasSelectedCheckmark}
					key={item.key}
					item={item}
					state={state}
				/>
			))}
		</ul>
	);
}
