// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TheaterActivityTitle-module__classTitleHeader--uodum {
  height: var(--class-title-height);
  padding: var(--class-title-padding);
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--class-title-size);
  font-weight: 500;
  color: var(--class-title-color);
  letter-spacing: 0.01rem;
  background-color: var(--class-title-background-color);
  border-bottom: var(--thin) solid var(--class-title-border-color);
  border-right: var(--thin) solid var(--dragbar-background-color);
  border-left: var(--thin) solid var(--class-title-border-color);
}

.TheaterActivityTitle-module__classTitle--Mb34F {
  padding: var(--class-title-text-padding);
  color: var(--Neutral-700, #3f3f46);
  font-family: "Roboto Flex";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.01rem;
}

.TheaterActivityTitle-module__classTitleButton--cywLL {
  background-color: var(--class-title-background-color);
}

.TheaterActivityTitle-module__classTitleIcon--jFxsS {
  height: var(--class-title-size);
}`, "",{"version":3,"sources":["webpack://./src/components/Theater/TheaterActivityTitle.module.scss"],"names":[],"mappings":"AAAA;EAEC,iCAAA;EACA,mCAAA;EACA,cAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EAGA,kCAAA;EACA,gBAAA;EACA,+BAAA;EACA,uBAAA;EAGA,qDAAA;EACA,gEAAA;EACA,+DAAA;EACA,8DAAA;AAJD;;AAOA;EACC,wCAAA;EACA,kCAAA;EACA,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAJD;;AAOA;EACC,qDAAA;AAJD;;AAOA;EACC,+BAAA;AAJD","sourcesContent":[".classTitleHeader {\n\t// Layout\n\theight: var(--class-title-height);\n\tpadding: var(--class-title-padding);\n\tflex: 0 0 auto;\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-items: center;\n\n\t// Typography\n\tfont-size: var(--class-title-size);\n\tfont-weight: 500;\n\tcolor: var(--class-title-color);\n\tletter-spacing: 0.01rem;\n\n\t// Style\n\tbackground-color: var(--class-title-background-color);\n\tborder-bottom: var(--thin) solid var(--class-title-border-color);\n\tborder-right: var(--thin) solid var(--dragbar-background-color);\n\tborder-left: var(--thin) solid var(--class-title-border-color);\n}\n\n.classTitle {\n\tpadding: var(--class-title-text-padding);\n\tcolor: var(--Neutral-700, #3f3f46);\n\tfont-family: \"Roboto Flex\";\n\tfont-size: 1rem;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tline-height: normal;\n\tletter-spacing: 0.01rem;\n}\n\n.classTitleButton {\n\tbackground-color: var(--class-title-background-color);\n}\n\n.classTitleIcon {\n\theight: var(--class-title-size);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classTitleHeader": `TheaterActivityTitle-module__classTitleHeader--uodum`,
	"classTitle": `TheaterActivityTitle-module__classTitle--Mb34F`,
	"classTitleButton": `TheaterActivityTitle-module__classTitleButton--cywLL`,
	"classTitleIcon": `TheaterActivityTitle-module__classTitleIcon--jFxsS`
};
export default ___CSS_LOADER_EXPORT___;
