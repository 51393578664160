/**
 * Efficient successor to the original Lottie loading screen.
 *
 * All the interesting work is done in the vertex and fragment shaders. There are 20 cubes in the AoPS logo. Each cube
 * is rendered as 12 triangles (two per side). The vertex shader generates the vertices themselves, including their
 * position; the only controlling value is the uniform u_time, which is calculated from the requestAnimationFrame.
 */

import VERTEX from "./LoadingScreen.vert.glsl";
import FRAGMENT from "./LoadingScreen.frag.glsl";
import React from "react";
import styles from "./LoadingScreen.module.scss";

const CUBE_COUNT = 20;
const VERTICES_PER_CUBE = 36;
const ANIMATION_TIME_MS = 8000;

let canvas: HTMLCanvasElement;
let gl: WebGL2RenderingContext;

let program: WebGLProgram | null;
let timeUnif: WebGLUniformLocation | null; // float
let viewUnif: WebGLUniformLocation | null; // mat4
let rotationUnif: WebGLUniformLocation | null; // int

let doRotation = false;

function initGL() {
	gl = canvas.getContext("webgl2", {
		premultipliedAlpha: false,
		antialias: true,
	})!;

	program = gl.createProgram()!;
	[
		[VERTEX as string, gl.VERTEX_SHADER] as const,
		[FRAGMENT as string, gl.FRAGMENT_SHADER] as const,
	].forEach(([src, type]) => {
		const shader = gl.createShader(type)!;
		gl.shaderSource(shader, src);
		gl.compileShader(shader);
		gl.attachShader(program!, shader);
	});

	gl.linkProgram(program);

	timeUnif = gl.getUniformLocation(program, "u_time");
	viewUnif = gl.getUniformLocation(program, "u_view");
	rotationUnif = gl.getUniformLocation(program, "u_do_rotation");
}

function prepareCanvas() {
	if (canvas) return;
	canvas = document.createElement("canvas");
	canvas.style.position = "absolute";

	canvas.addEventListener("webglcontextlost", (e) => {
		e.preventDefault();
	});
	// if we lose the WebGL, recreate it when possible
	canvas.addEventListener("webglcontextrestored", initGL);
	initGL();
}

let raf = 0; // requestAnimationFrame handle
let rafStart = 0; // start time of the current animation

function startAnimation() {
	rafStart = performance.now();
	cancelAnimationFrame(raf); // prevent multiple running animations
	raf = requestAnimationFrame(doLoadingScreenFrame);
}

function stopAnimation() {
	cancelAnimationFrame(raf);
}

/**
 * Render the animation at the given time.
 */
function doLoadingScreenFrame(timeMs: number) {
	gl.useProgram(program);
	gl.viewport(0, 0, canvas.width, canvas.height);

	const animationTime = timeMs - rafStart;

	gl.uniform1f(timeUnif, (animationTime / ANIMATION_TIME_MS) % 1);
	gl.uniform1i(rotationUnif, doRotation ? 1 : 0);

	const scale = 0.15; // % of one half-width that one vertical side length of one cube takes up
	const aspect = canvas.width / canvas.height;
	const b = scale * 0.5;
	const a = (scale * Math.sqrt(3)) / 2;

	const depthScale = 0.001; // ensure our depth values fall into an ok range

	// prettier-ignore
	// eslint-disable-next-line no-lone-blocks
	{
		// Orthogonal projection matrix facing (0,0,0) from the direction (1,1,1)
		gl.uniformMatrix4fv(viewUnif, false, new Float32Array([
			-a, -b * aspect, depthScale, 0,
			0, scale * aspect, depthScale, 0,
			a, -b * aspect, depthScale, 0,
			0, 0, 0, 1,
		]));
	}

	// Clear to transparent black and reset depth buffer
	gl.clearColor(0, 0, 0, 0);
	gl.clearDepth(-1);
	gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

	gl.enable(gl.DEPTH_TEST);
	gl.depthFunc(gl.GEQUAL); // our depth is reversed in the shader

	gl.drawArrays(gl.TRIANGLES, 0, CUBE_COUNT * VERTICES_PER_CUBE);

	cancelAnimationFrame(raf);
	raf = requestAnimationFrame(doLoadingScreenFrame);
}

/**
 * Fit the canvas to the screen.
 */
function fitCanvas() {
	if (!canvas?.parentElement)
		// not yet attached -- can't fit anything
		return;

	const parent = canvas.parentElement;
	const dpr = window.devicePixelRatio || 1;
	const width = parent.clientWidth;
	const height = parent.clientHeight;

	canvas.width = width * dpr; // to display nicely on high-DPI screens
	canvas.height = height * dpr;

	canvas.style.width = `${width}px`;
	canvas.style.height = `${height}px`;
	canvas.style.mixBlendMode = "add";
}

type LoadingScreenProps = {
	/**
	 * Whether to rotate around the cubes.
	 */
	rotate?: boolean;
};

/**
 * Wrapper around the Lottie loading screen, but for performance reasons, only displays it after some delay.
 * @constructor
 */
const LoadingScreen: React.FC<LoadingScreenProps> = (props) => {
	// Get canvas and attach it, and add a callback for when the parent element is resized
	const outer = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		if (!outer.current) return;
		prepareCanvas();
		outer.current.appendChild(canvas);
		fitCanvas();

		const resizeObserver = new ResizeObserver(fitCanvas);
		resizeObserver.observe(outer.current);

		startAnimation();
		return () => {
			stopAnimation();
			resizeObserver.disconnect();
		};
	}, [outer]);

	React.useEffect(() => {
		doRotation = props.rotate ?? false;
	}, [props.rotate]);

	return <div ref={outer} className={styles.loadingScreenContainer}></div>;
};

export default LoadingScreen;
