import React, {useCallback, useEffect} from "react";
import {useCsContext} from "../../state/CsInstance";
import {observer} from "mobx-react-lite";
import {debounce} from "lodash";
import CompileButton from "../CompileButton/CompileButton";
// Frontend
import styles from "./Editor.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/sharp-solid-svg-icons/faXmark";
import {faEdit} from "@fortawesome/sharp-regular-svg-icons/faEdit";
import {faPlus} from "@fortawesome/sharp-regular-svg-icons/faPlus";
import {faEllipsis} from "@fortawesome/sharp-regular-svg-icons/faEllipsis";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
// Stores
import {DEFAULT_EDITOR_FONT_SIZE} from "../../state/theaterStore";
// CodeMirror
import ReactCodeMirror, {EditorState, EditorView} from "@uiw/react-codemirror";
import {java} from "@codemirror/lang-java";
import {search} from "@codemirror/search";
import readOnlyRangesExtension, {
	addLineGreyout,
	getReadOnlyRanges,
} from "../Ide/snippetsExtension";
import * as cmEvents from "@uiw/codemirror-extensions-events";
import useCodeMirrorTheme from "../../util/CodeMirrorTheme";
import indentExtension from "../Ide/indentExtension";
import {focusFile} from "../FileTree/File";
import ContextMenu from "../ContextMenu";
import CodeEditorMenu from "../CodeEditorMenu/CodeEditorMenu";
import {Diagnostic, linter} from "@codemirror/lint";
import {CheckSuccessReply} from "../../shared/Types";
import AddActorModal from "../Modals/AddActorModal";
import UseModal from "../UseModal";
import MarkdownViewer from "./MarkdownViewer";
import {EDITOR_HEADER_HEIGHT_IN_PIXELS, getHeightStyle} from "../Ide/Ide";

type TabProps = {
	key: string;
};

const TAB_SIZE = 4;

const NEW_FILE_BOILERPLATE = `package StudentCode;
import AopsTheater.*;
public class $classname extends Actor {
    public $classname() {
    
    }
    public void step() {
    
    }
}`;

/**
 * Set the editor code to the given value. This is used to update the editor when a file is opened/closed.
 * @param value The new editor value.
 */
export function setCodeMirrorEditorValue(value: string) {
	const view = EditorView.findFromDOM(document.body);
	if (!view) {
		return;
	}
	view.setState(EditorState.create({doc: value}));
}

type Props = {
	percentageHeight: number; // in percentage of PanelGroup height
};

const EditorWrapper: React.FC<Props> = (props: Props) => {
	const {
		codeStore,
		theaterStore,
		compileStore,
		featureFlagStore,
		userInputStore,
	} = useCsContext();
	const {percentageHeight} = props;
	const heightStyle = getHeightStyle(
		percentageHeight,
		EDITOR_HEADER_HEIGHT_IN_PIXELS
	);

	const [rightClickCoords, setRightClickCoords] = React.useState({x: 0, y: 0});
	const [contextMenuOpen, setContextMenuOpen] = React.useState(false);
	const [codeEditorMenuOpen, setCodeEditorMenuOpen] = React.useState(false);
	const {isOpen: isAddActorOpen, toggle: addActorToggle} = UseModal();

	const currFile = codeStore.visibleFileData[codeStore.focusedFile];

	// If no files are open in a tab
	if (!currFile) {
		setCodeMirrorEditorValue("\nPlease open a file to start editing\n");
	}

	// Handle closing the context menu when clicking outside of it
	document.addEventListener(
		"click",
		function (e) {
			if (!document.getElementById("contextMenu")?.contains(e.target as Node)) {
				if (contextMenuOpen) {
					setContextMenuOpen(false);
				}
			}
		},
		false
	);

	useEffect(() => {
		document.addEventListener(
			"contextmenu",
			function (e) {
				// Prevent the default context menu from appearing when right-clicking on the custom menu
				if (
					document.getElementById("contextMenu")?.contains(e.target as Node)
				) {
					e.preventDefault();
				}
				// Handle right-clicking on the editor to open the context menu.
				if (
					document
						.getElementById("codeMirrorEditor")
						?.contains(e.target as Node)
				) {
					setRightClickCoords({x: e.clientX, y: e.clientY});
					setContextMenuOpen(true);
					e.preventDefault();
				} else {
					setContextMenuOpen(false);
				}
			},
			false
		);
	}, []);

	// Make sure to save the file data when the user leaves the page
	useEffect(() => {
		document.addEventListener("beforeunload", () => {
			debouncedHandleEditorChange.flush();
		});
		return document.removeEventListener("beforeunload", () => {
			debouncedHandleEditorChange.flush();
		});
	});

	/**
	 * Add a file to the list of open files.
	 * @param fileName
	 */
	const addFileOpen = useCallback(
		(fileName: string) => {
			if (!codeStore.filesOpen.includes(fileName)) {
				codeStore.filesOpen = codeStore.filesOpen.concat(fileName);
			}
		},
		[codeStore]
	);

	// Handle adding the focused file to the list of open files on render
	useEffect(() => {
		if (
			codeStore.focusedFile &&
			!codeStore.filesOpen.includes(codeStore.focusedFile)
		) {
			// We must have just opened this file, so add it to the file list
			addFileOpen(codeStore.focusedFile);

			// TODO scroll to focused file if necessary
		}
	}, [codeStore, codeStore.focusedFile, addFileOpen]);

	// Handle loading files and saving them locally on render
	useEffect(() => {
		const knownFiles = Object.keys(codeStore.fileData);
		if (knownFiles.length !== codeStore.fileNames.length) {
			// Update fileData when fileNames change
			// TODO can only handle adding files
			const newFiles = codeStore.fileNames.filter(
				(name) => !knownFiles.includes(name)
			);
			newFiles.forEach((fileName) => {
				codeStore.fileData = {
					...codeStore.fileData,
					[fileName]: {
						name: fileName,
						value: createNewFileContents(fileName.replace(".java", "")),
					},
				};
				addFileOpen(fileName);
			});
			codeStore.saveFileDataLocally();
		}
	}, [codeStore, codeStore.fileNames, addFileOpen, compileStore]);

	/**
	 * Create a new file with the given name from the boilerplate.
	 * @param fileName
	 */
	function createNewFileContents(fileName: string) {
		return NEW_FILE_BOILERPLATE.replace(/\$classname/g, fileName);
	}

	const theme = useCodeMirrorTheme({dark: false});

	/**
	 * Update the editor to grey out non-editable regions.
	 */
	const updateNoneditableViewRegions = useCallback((_e: EditorView) => {
		// get non-editable lines, then grey them out
		getReadOnlyRanges(_e.state).forEach((range) => {
			const numLines = _e.state.doc.length;
			const {from = 0, to = numLines} = range;

			for (let i = from; i <= to; i++) {
				_e.dispatch({effects: addLineGreyout.of(i)});
			}
		});
	}, []);

	/**
	 * Renders a tab for a file.
	 * @param tabProps
	 * @returns
	 */
	const renderTab = (tabProps: TabProps) => {
		const currentFileInfo = codeStore.visibleFileData[tabProps.key];
		if (!currentFileInfo) {
			return null;
		}

		return (
			<ErrorBoundary key={tabProps.key} FallbackComponent={ErrorComponent}>
				<span
					key={tabProps.key}
					className={[
						styles.tabButtonWrapper,
						codeStore.focusedFile === currentFileInfo.name
							? styles.tabButtonWrapperFocused
							: "",
					].join(" ")}
					onClick={() => {
						focusFile(codeStore, currentFileInfo);
					}}
				>
					<button className={styles.tabButtonContent}>
						<FontAwesomeIcon
							icon={faEdit}
							className={[styles.icon, styles.fileIcon].join(" ")}
						/>
						<div className={styles.tabButtonText}>
							{currentFileInfo.name.split(".")[0]}
						</div>
					</button>
					<button
						className={styles.closeTabButton}
						onClick={(e) => {
							e.stopPropagation();
							removeFileOpen(currentFileInfo.name);
						}}
					>
						<FontAwesomeIcon icon={faXmark} className={styles.icon} />
					</button>
				</span>
			</ErrorBoundary>
		);
	};

	/**
	 * Renders the tabs and dividers for the open files
	 * @returns An array of tab elements and dividers
	 */
	const renderTabs = () => {
		const filesOpen = codeStore.filesOpen.filter((f) => !!f);
		let tabs = filesOpen
			.map((filename) => {
				return renderTab({key: filename});
			})
			.filter((tab) => !!tab);

		// Limit the number of tabs to 10
		tabs = tabs.splice(0, 10);

		tabs.push(
			<span
				key={"newFile"}
				className={[styles.tabButtonWrapper, styles.newFileTab].join(" ")}
				onClick={() => {
					addActorToggle();
				}}
			>
				<button
					className={[styles.tabButtonContent, styles.newFileTab].join(" ")}
				>
					<FontAwesomeIcon icon={faPlus} className={styles.icon} />
				</button>
			</span>
		);

		const focusedIndex = filesOpen.findIndex(
			(f) => f === codeStore.focusedFile
		);

		const tabsAndDividers = tabs.map((tab, i) => {
			// add dividers after every element, except for the element before the focused element and the
			// focused element
			if (
				i === focusedIndex ||
				i === focusedIndex - 1 ||
				i === tabs.length - 1
			) {
				return [tab, <div className={styles.hiddenTabDivider} key={i} />];
			}
			return [tab, <div className={styles.tabDivider} key={i} />];
		});

		return tabsAndDividers.flat();
	};

	/**
	 * Update the file store with the new editor value and set the editor changed flag.
	 */
	function handleEditorChange(value: string | undefined) {
		codeStore.editorChanged = true;
		codeStore.setFileData(codeStore.focusedFile, {
			name: codeStore.focusedFile,
			value: value || "",
			attributes: codeStore.fileData[codeStore.focusedFile]?.attributes,
		});
		codeStore.saveFileDataLocally();
	}
	const debouncedHandleEditorChange = debounce(handleEditorChange, 100);

	/**
	 * Attempt to compile the code. If the code is already compiling or the editor hasn't changed, do nothing.
	 */
	async function attemptToCompileCode() {
		// Flush the debounced handle editor change function to ensure the latest changes are saved
		debouncedHandleEditorChange.flush();

		// If the code is already compiling or the editor hasn't changed, do nothing
		if (compileStore.isCompiling || !codeStore.editorChanged) return;

		theaterStore.isRunning = false;
		codeStore.editorChanged = false;

		// Save local file list upon submitting code to the server
		codeStore.saveFileDataLocally();
		await codeStore.compileCode();
	}

	/**
	 * Remove a file from the list of open files.
	 * @param fileName
	 */
	function removeFileOpen(fileName: string) {
		const filesOpenCopy = [...codeStore.filesOpen];
		const idx = codeStore.filesOpen.indexOf(fileName);
		filesOpenCopy.splice(idx, 1);
		codeStore.filesOpen = filesOpenCopy;

		// If all tabs are closed, set focused file to empty string
		if (filesOpenCopy.length === 0) {
			codeStore.focusedFile = "";
		} else if (codeStore.focusedFile === fileName) {
			if (idx - 1 < 0) {
				// If the focused file was the first tab, set focused file to the new first tab
				codeStore.focusedFile = filesOpenCopy[0]!;
			} else {
				// Otherwise, set focused file to the tab to the left of the closed tab
				codeStore.focusedFile = filesOpenCopy[idx - 1]!;
			}
		}
		// Set the editor value to the new focused file, or empty string if no files are open
		setCodeMirrorEditorValue(
			codeStore.fileData[codeStore.focusedFile]?.value || ""
		);
	}

	// Hotkey handling
	const hotkeyHandlingExtension = cmEvents.content({
		keydown: (e) => {
			if (!e.metaKey) {
				return;
			}
			if (e.key === "=") {
				e.preventDefault();
				theaterStore.editorFontSize += 1;
			} else if (e.key === "-") {
				e.preventDefault();
				theaterStore.editorFontSize -= 1;
			} else if (e.key === "0") {
				e.preventDefault();
				theaterStore.editorFontSize = DEFAULT_EDITOR_FONT_SIZE;
			} else if (
				e.key.toUpperCase() === "S" ||
				e.key.toUpperCase() === "ENTER"
			) {
				// meta+S or meta+shift+enter to compile
				e.preventDefault();
				attemptToCompileCode();
			}
		},
	});

	const readOnlyExtensionsToApply = featureFlagStore.useReadOnlyRanges
		? [readOnlyRangesExtension()]
		: [];

	const theLinter = linter(
		async (view) => {
			const r: CheckSuccessReply | null = await compileStore.callDiagnosticsApi(
				codeStore.fileData,
				codeStore.hiddenFileNames
			);
			if (!r) return [];

			const fullFileName = "StudentCode." + codeStore.focusedFile;
			const filesWithErrors: Set<string> = new Set(); // TODO: use for headers

			return r.diagnostics
				.filter((d) => {
					const fullName = d.fileName + ".java";
					filesWithErrors.add(fullName);
					return fullName === fullFileName;
				})
				.map(({type, message, start, end}): Diagnostic => {
					return {
						from: start,
						to: end,
						message,
						severity: type,
						renderMessage: (_view) => {
							const element = document.createElement("div");
							element.innerHTML = message;
							element.className = styles.errorFont;
							return element;
						},
					};
				});
		},
		{delay: 500}
	);

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<AddActorModal isOpen={isAddActorOpen} toggle={addActorToggle} />
			<div className={styles.editorWrapper}>
				<ContextMenu
					setContextMenuOpen={setContextMenuOpen}
					contextMenuOpen={contextMenuOpen}
					x={rightClickCoords.x}
					y={rightClickCoords.y}
				/>
				<div className={styles.editorHeader}>
					<div className={styles.fileTabContainer}>{renderTabs()}</div>
					<div
						className={styles.showTabsButton}
						onClick={() => {
							setCodeEditorMenuOpen(!codeEditorMenuOpen);
						}}
					>
						<FontAwesomeIcon icon={faEllipsis} className={styles.icon} />
					</div>
					<CodeEditorMenu
						setCodeEditorMenuOpen={setCodeEditorMenuOpen}
						codeEditorMenuOpen={codeEditorMenuOpen}
					/>
				</div>
				<div className={styles.editorToolbar}>
					{featureFlagStore.showExtraButtons && (
						<button
							className={styles.resetButton}
							onClick={codeStore.resetProject}
						>
							Reset
						</button>
					)}
					{!featureFlagStore.hideCompileButton && (
						<CompileButton
							onClick={attemptToCompileCode}
							disabled={!codeStore.editorChanged}
						/>
					)}
				</div>
				<div className={styles.editor}>
					{currFile?.attributes?.isMarkdown ? (
						<MarkdownViewer file={currFile} />
					) : (
						<ReactCodeMirror
							id="codeMirrorEditor"
							height={heightStyle}
							width="100%"
							extensions={[
								search({top: true}),
								java(), // Java syntax highlighting
								hotkeyHandlingExtension,
								indentExtension(),
								...readOnlyExtensionsToApply,
							].concat(featureFlagStore.showDiagnostics ? [theLinter] : [])}
							theme={theme}
							basicSetup={{tabSize: TAB_SIZE}}
							onChange={(value) => debouncedHandleEditorChange(value)}
							editable={
								// Can only edit if theater not focused and there are open files
								!userInputStore.isPixiFocused && codeStore.filesOpen.length > 0
							}
							onCreateEditor={(editor: EditorView) => {
								// Load the editor with the focused file's contents. The delay is necessary to ensure the codeStore has been initialized.
								setTimeout(() => {
									const editorValue =
										currFile?.value ||
										"\nPlease open a file to start editing\n";
									editor.dispatch({
										changes: {
											from: 0,
											to: editor.state.doc.length,
											insert: editorValue,
										},
									});
								}, 500);

								// mark up the lines on load
								updateNoneditableViewRegions(editor);
								// TODO we shouldn't have these here -- it's in the wrong part of the lifecycle
								// these should go in the map function so they're persisted, instead of being recalculated
								// https://codemirror.net/docs/ref/#rangeset.RangeSet.map
								editor.dom.addEventListener("load", () =>
									updateNoneditableViewRegions(editor)
								);
								editor.dom.addEventListener("mousemove", () =>
									updateNoneditableViewRegions(editor)
								);
								editor.dom.addEventListener("contextmenu", () =>
									updateNoneditableViewRegions(editor)
								);
								editor.dom.addEventListener("keydown", () =>
									updateNoneditableViewRegions(editor)
								);
								editor.dom.addEventListener("keyup", () =>
									updateNoneditableViewRegions(editor)
								);
								editor.dom.addEventListener("click", () => {
									userInputStore.isPixiFocused = false;
								});
							}}
						/>
					)}
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default observer(EditorWrapper);
