import CsInstance from "../state/CsInstance";
import type {
	MessengerTicket,
	WorkerResponse,
	WorkerTicket,
	MessengerResponse,
	ClassManifest,
	TestResults,
} from "../@types/Types";
import {WorkerTicketT} from "../@types/TypeboxTypes";
import {
	JavaFunctionNames,
	JavaFunctionArgs,
	serializeToJson,
} from "../util/messengerWorkerUtils";
import {Value} from "@sinclair/typebox/value";
import {ConsoleLog} from "../util/consoleLogUtils";

const WORKER_RELATIVE_PATH = "/dist/bundle_cs_worker.js";

export type MessengerProps = {
	javaConsoleLogCallback?: (msg: string) => void;
	studentInputCallback?: (msg: number) => void;
};

/**
 * Removes unwanted functions from the method manifest.
 */
function filterMethods(manifest: ClassManifest): ClassManifest {
	manifest = {...manifest};
	delete manifest["Object"];
	return manifest;
}

export class Messenger {
	private _initialized = false;
	private _ticketMap: Map<number, MessengerTicket<JavaFunctionNames>> =
		new Map();

	private _log: ConsoleLog;
	private _worker?: Worker; // undefined if not yet initialized

	// signals to send tickets that are waiting for the worker to be ready
	private _waitingOnWorker: (() => void)[] = [];

	private _workerError = false;
	private _workerTerminated = false;

	private _nextTicketId = 1;
	private _pendingStopPixiAcknowledgement = false;

	// set these to override default behavior
	javaConsoleLogCallback: (id: string) => void = () => {};
	studentInputCallback: (id: number) => void = () => {};
	onDestroy?: () => void;

	private _csInstance: CsInstance;

	constructor(csInstance: CsInstance, options: MessengerProps = {}) {
		this._csInstance = csInstance;
		const {javaConsoleLogCallback, studentInputCallback} = options;

		if (javaConsoleLogCallback) {
			this.javaConsoleLogCallback = javaConsoleLogCallback;
		}
		if (studentInputCallback) {
			this.studentInputCallback = studentInputCallback;
		}
		this._log = ConsoleLog.mainThreadInitLog();

		this.initWorker();
	}

	/**
	 * Initialize worker, via serverUrl if necessary.  Does NOT need to be awaited.
	 *
	 * Due to CORS issues, we can't load the worker directly from the server, so
	 * instead we fetch the code and load it in via blob.  Server fetching means
	 * async; however, we want to be able to use the messenger asap.  So we use
	 * an array of resolve() functions to signal when the worker is ready.
	 */
	async initWorker() {
		// Try to create the worker
		try {
			const serverUrl = this._csInstance.serverUrl;
			if (serverUrl) {
				// get literal code from server and load it in manually via blob
				const url = serverUrl + WORKER_RELATIVE_PATH;

				const fetchRes = await fetch(url);
				const fetchText = await fetchRes.text();
				// Remove bundle's .map file reference since it doesn't exist locally
				const fetchTextNoMap = fetchText.replace(
					/\/\/# sourceMappingURL=.*$/,
					""
				);
				const blob = new Blob([fetchTextNoMap], {
					type: "application/javascript",
				});
				this._worker = new Worker(URL.createObjectURL(blob));

				console.info("blob-based worker creation successful");
			} else {
				this._worker = new Worker(WORKER_RELATIVE_PATH);
			}
		} catch (e) {
			// If it fails we have no way to recover, so just throw below
		}

		// the worker failed somehow???  TODO @tim handle this in v2
		if (!this._worker) {
			throw new Error("Worker creation failed");
		}

		this._sendTicketToWorker({
			func: "workerThreadSetSharedArray",
			args: [this._log.getSab()],
		});

		this._waitingOnWorker.forEach((resolve) => resolve());
		this._waitingOnWorker = [];

		this._attachWorkerListeners();
	}

	destroy() {
		this._worker?.terminate();
		this._workerTerminated = true;
	}

	get initialized() {
		return this._initialized;
	}

	get workerError() {
		return this._workerError;
	}

	sendStudentInput(id: number, input: string | null) {
		const ticket: MessengerResponse<"sendStudentInput"> = {
			id: id,
			timestamp: Date.now(),
			func: "sendStudentInput",
			result: [input],
		};
		this._sendRawTicketToWorker(ticket);
	}

	resetJavaConsoleLog() {
		this._sendTicketToWorker({
			func: "resetJavaConsoleLog",
			args: [],
		});
	}

	getMethodsJSON(x: number, y: number) {
		this._sendTicketToWorker({func: "getMethodsJSON", args: [x, y]});
	}

	invokeMethod(jsonString: string) {
		this._sendTicketToWorker({
			func: "invokeMethod",
			args: [jsonString],
		});
	}

	resetCode() {
		this._initialized = false;

		const {theaterStore} = this._csInstance;
		theaterStore.clearLog();
		theaterStore.showConsole = false;

		this._sendTicketToWorker({func: "resetCode", args: []});
	}

	update(numUpdates: number, jsonString: string, overrideAck = false) {
		if (!overrideAck && this._pendingStopPixiAcknowledgement) return; // theater is stopped -- refuse to send more updates

		if (this.workerError || !this._initialized) {
			console.error("Not ready");
			return;
		}
		this._sendTicketToWorker({func: "update", args: [jsonString]}, numUpdates);
	}

	runTests() {
		this._sendTicketToWorker({
			func: "runTests",
			args: [this._csInstance.codeStore.project || ""],
		});
	}

	private _attachWorkerListeners() {
		const {theaterStore, userInputStore, compileStore, getOrCreateMessenger} =
			this._csInstance;
		if (this._workerTerminated || !this._worker) {
			return;
		}

		setInterval(() => {
			if (this._log.logBufferHasLines()) {
				const startTime = performance.now();
				let i = 0;
				for (const p of this._log.dequeueLines()) {
					if (
						++i % 1024 === 0 &&
						performance.now() - startTime > 10 /* don't block too long */
					) {
						break;
					}

					theaterStore.addLine(p);
				}
			}
		}, 10);

		this._worker.onmessage = (e) => {
			const response: WorkerResponse | WorkerTicket = e.data;

			if (Value.Check(WorkerTicketT, response)) {
				switch (response.func) {
					case "requestStudentInput":
						this.studentInputCallback(response.id);
						break;
					case "initialized":
						theaterStore.messengerLoading = false;
						// We're ready to run things, tell any consumer that has been waiting
						this._csInstance.onCsReadyCallback();
						break;
					case "requestUpdate":
						this.update(
							response.arg as number,
							serializeToJson(
								response.arg as number,
								userInputStore.mouseX,
								userInputStore.mouseY,
								userInputStore.isMouseDown,
								userInputStore.getKeysPressed()
							)
						);
						break;
					case "forceClearLog":
						theaterStore.clearLog();
						this._log.mainThreadClearLog();
						break;
					default:
						console.log("Messenger: Unknown Response Initiated by Worker");
						break;
				}
				return;
			}
			let responseTicket = this._ticketMap.get(response.id);
			if (!responseTicket) {
				// shouldn't be a response without a ticket
				console.error(
					"Error: We are missing the Messenger Ticket number:",
					response.id,
					response
				);
			}
			this._ticketMap.delete(response.id);

			// CheerpJ will let you continue to run the code
			// don't allow that
			if (response.status === "Error") {
				// Put in the console as an error.
				// TODO Unfortunately this overwrites future normal console logs; mobx
				// stores need to be refactored to handle this better.
				compileStore.setErrorStatusAndMessage(response.result);

				// Pause ticker and disable pause/play buttons but allow reset button
				theaterStore.isRunning = false;
				getOrCreateMessenger().stopPixi();
				theaterStore.runtimeErrorOccurred = true;

				return;
			}

			responseTicket = responseTicket!;
			responseTicket.status = "Processed";
			switch (response.func) {
				case "initCode":
					this._initialized = true;
					break;
				case "workerThreadSetSharedArray":
					break;
				case "initCanvas":
					this._initialized = true;
					break;
				case "destroyCanvas":
					this._initialized = false;
					break;
				case "update":
					break;
				case "resetCode":
					this._initialized = true;
					this._workerError = false;
					break;
				case "getMethodsJSON":
					this._csInstance.theaterStore.showManifest = true;
					this._csInstance.theaterStore.methodManifest = filterMethods(
						JSON.parse(response.result)
					);
					break;
				case "invokeMethod":
					this._csInstance.theaterStore.invokeResult = response.result;
					break;
				case "stopPixi":
					this._pendingStopPixiAcknowledgement = false;
					break;
				case "togglePixi":
					break;
				case "runTests":
					this._csInstance.theaterStore.showConsole = true;
					this._csInstance.theaterStore.consoleExpanded = true;

					// Allow consumers to access test results.
					// response.result should match the output of runTests() in the worker
					const result: TestResults = response.result;
					this._csInstance.testsStore.testResults = result;
					this._csInstance.onTestResultsCallback(
						this._csInstance.codeStore.project,
						result
					);
					break;
				default:
					console.log(
						"Messenger: Unknown API call to main Thread: ",
						response.func
					);
					break;
			}
		};
	}

	initCode(jarData: Uint8Array, version: number) {
		this._sendTicketToWorker({
			func: "initCode",
			args: [jarData, version],
		});
	}

	initCanvas(options: {width: number; height: number; view: OffscreenCanvas}) {
		this._sendTicketToWorker(
			{
				func: "initCanvas",
				args: [options],
			},
			0,
			options.view
		);
	}

	destroyCanvas() {
		this._sendTicketToWorker({
			func: "destroyCanvas",
			args: [],
		});
	}

	togglePixi() {
		this._sendTicketToWorker({
			func: "togglePixi",
			args: [],
		});
	}

	stopPixi() {
		this._pendingStopPixiAcknowledgement = true;
		this._sendTicketToWorker({
			func: "stopPixi",
			args: [],
		});
	}

	private _sendTicketToWorker<FuncName extends JavaFunctionNames>(
		funcProps: {func: FuncName; args: JavaFunctionArgs<FuncName>},
		numUpdates = 0,
		view?: OffscreenCanvas
	) {
		if (this._workerTerminated) {
			return;
		}

		const newTicket: MessengerTicket<FuncName> = {
			id: this._nextTicketId,
			timestamp: Date.now(),
			func: funcProps.func,
			args: funcProps.args,
			status: "Requested",
			numUpdates,
		};
		this._ticketMap.set(this._nextTicketId, newTicket);

		this._sendRawTicketToWorker(newTicket, view);

		this._nextTicketId++;
	}

	/**
	 * This method is used to send a raw ticket to the worker.
	 *
	 * It will wait for the worker to be ready if it is not.  You do NOT need to
	 * await this method since this._waitingOnWorker will resolve itself when the
	 * worker is ready.
	 */
	private async _sendRawTicketToWorker(
		ticket:
			| MessengerTicket<JavaFunctionNames>
			| MessengerResponse<JavaFunctionNames>,
		view?: OffscreenCanvas
	) {
		if (!this._worker) {
			await new Promise((resolve) => {
				this._waitingOnWorker.push(resolve as () => void);
			});
		}

		// this._worker should be set now; if not, something went seriously wrong
		if (!this._worker) {
			throw new Error("Worker not initialized");
		}

		if (view) {
			// Second argument of postMessage is a list of transferables, which means that ownership of the object
			// is passed to the worker. In this case we're sending an OffscreenCanvas, so the worker can now
			// manipulate it directly, while the main thread can no longer interact with it.
			this._worker.postMessage(ticket, [view]);
			return;
		}
		this._worker.postMessage(ticket);
	}
}
