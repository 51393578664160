import React, {useRef, useEffect} from "react";
import {useOption} from "react-aria";
import styles from "./ListBox.module.css";
import {ListState} from "react-stately";
import type {Node} from "react-stately";
import clsx from "clsx";

interface IListBoxOption<T> {
	readonly state: ListState<T>;
	readonly item: Node<T>;
	readonly hasSelectedCheckmark?: boolean;
}

/**
 * ListBoxOption
 */
export function ListBoxOption<T>({
	item,
	state,
	hasSelectedCheckmark = true,
}: IListBoxOption<T>) {
	const ref = useRef<HTMLLIElement | null>(null);

	useEffect(() => {
		// Patch for this known react-spectrum issue:
		// https://github.com/adobe/react-spectrum/issues/1513
		if (ref.current) {
			ref.current.addEventListener("touchstart", (e: TouchEvent) => {
				/**
				 * This workaround is described here:
				 * https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1641830053
				*/
				if (
					(e.currentTarget as HTMLElement)?.getAttribute('type') !== 'submit' &&
					typeof (e.currentTarget as HTMLElement)?.getAttribute('href') !== 'string'
				  ) {
					e.preventDefault();
				  }
			}, {passive: false});
		}
	}, []);

	const {optionProps, isSelected, isFocused, isDisabled} = useOption(
		{key: item.key},
		state,
		ref
	);

	return (
		<li
			{...optionProps}
			ref={ref}
			className={clsx(styles.listBoxOption, {
				[styles.listBoxOptionSelected]: isSelected,
				[styles.listBoxOptionFocused]: isFocused,
				[styles.listBoxOptionDisabled]: isDisabled,
			})}
		>
			{item.rendered}
			{isSelected && hasSelectedCheckmark ? (
				<span style={{paddingLeft: "var(--theme-space-1)"}}>✓</span>
			) : null}
		</li>
	);
}
