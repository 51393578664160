import React from "react";
import {observer} from "mobx-react-lite";
import {useCsContext} from "../../state/CsInstance";

import styles from "./File.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/sharp-solid-svg-icons/faRotate";
import {faTrash} from "@fortawesome/sharp-solid-svg-icons/faTrash";

import {File as FileType} from "../../shared/Types";
import {setCodeMirrorEditorValue} from "../CodeEditor/CodeMirrorEditor";
import CodeStore from "../../state/codeStore";

type FileProps = {
	fileData: FileType;
	toggleResetModal: () => void;
};

/**
 * Focuses a file in the editor
 * @param CodeStore The file store
 * @param fileData The file to focus. Contains the filename and value.
 */
export function focusFile(codeStore: CodeStore, fileData: FileType) {
	const filename = fileData.name;
	// If the file is already focused, do nothing
	if (codeStore.focusedFile === filename) {
		return;
	}
	codeStore.focusedFile = filename;
	setCodeMirrorEditorValue(fileData.value);
}

const File: React.FC<FileProps> = (props) => {
	const {codeStore} = useCsContext();
	const filename = props.fileData.name;

	// If this file is not meant to be visible, don't render it
	if (codeStore.visibleFileNames.indexOf(props.fileData.name) < 0) {
		return <></>;
	}

	const onClickFile = () => {
		focusFile(codeStore, props.fileData);
	};

	const classNames = [styles.fileNameWrapper];
	if (codeStore.focusedFile === filename) {
		classNames.push(styles.focusedFile);
	}

	const isOriginal = props.fileData?.attributes?.isOriginal;
	const buttonTitle = isOriginal ? "Reset File" : "Delete File";
	const buttonIcon = isOriginal ? faRotate : faTrash;

	return (
		<div className={classNames.join(" ")} onClick={onClickFile}>
			<div className={styles.innerWrapper}>
				<span className={styles.fileName}>{filename}</span>
				<div className={styles.optionsContainer}>
					<button
						className={styles.option}
						title={buttonTitle}
						onClick={props.toggleResetModal}
					>
						<FontAwesomeIcon icon={buttonIcon} className={styles.optionIcon} />
					</button>
				</div>
			</div>
		</div>
	);
};

export default observer(File);
