import SimpleModal from "./SimpleModal";
import styles from "./SimpleModal.module.scss";
import React from "react";
import {validateClassName} from "../../util/validation";
import {useCsContext} from "../../state/CsInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/sharp-solid-svg-icons/faCircleInfo";

interface AddActorModalType {
	isOpen: boolean;
	toggle: () => void;
}

/**
 * Modal displayed when adding a new actor:
 * ┌────────────────────────────────────────┐
 * │  Add Actor                             │
 * │  Create actor called <name>            │
 * │  ┌─────────────────────────────────┐   │
 * │  └─────────────────────────────────┘   │
 * │  <name> is an invalid class name       │
 * │  ┌─────────────┐     ┌─────────────┐   │
 * │  │    Cancel   │     │   Confirm   │   │
 * │  └─────────────┘     └─────────────┘   │
 * └────────────────────────────────────────┘
 */
export default function AddActorModal(props: AddActorModalType) {
	const {codeStore} = useCsContext();

	const onClickSuccess = () => {
		codeStore.addActorClass(newClass);
		codeStore.editorChanged = true;
		props.toggle();
	};

	const onClickCancel = () => {
		props.toggle();
	};

	const [newClass, setNewClass] = React.useState("");
	const [inputReceivedSinceOpening, setInputReceivedSinceOpening] =
		React.useState(false);
	const validationResult = validateClassName(
		newClass,
		codeStore.fileNames // includes hidden files, to prevent naming conflict confusion
	);

	React.useEffect(() => {
		if (props.isOpen) {
			// reset value upon opening
			setNewClass("");
			setInputReceivedSinceOpening(false);
			// focus on the input field upon opening
			document.getElementById("addActorModalInput")?.focus();
		}
	}, [props.isOpen]);

	const newClassDisplayText =
		inputReceivedSinceOpening || newClass ? newClass : "___";

	return (
		<>
			<SimpleModal
				isOpen={props.isOpen}
				toggle={props.toggle}
				onSuccess={onClickSuccess}
				onCancel={onClickCancel}
				disableSubmit={validationResult?.type === "error"}
				title={"New Actor Subclass"}
			>
				<div className={styles.modalText}>
					public class{" "}
					<span className={styles.className}>{newClassDisplayText}</span>{" "}
					extends <span className={styles.className}>Actor</span>
				</div>
				<input
					id={"addActorModalInput"}
					type={"text"}
					onChange={(evt) => {
						setNewClass(evt.target.value);
						setInputReceivedSinceOpening(true);
					}}
					value={newClass}
					className={[
						inputReceivedSinceOpening && validationResult?.type === "error"
							? styles.inputError
							: "",
						styles.input,
					].join(" ")}
				/>

				{
					// Only show the error message if it's non-empty and we've received at least some input
					// since the modal was last opened.
					validationResult && inputReceivedSinceOpening && (
						<p
							className={[
								validationResult?.type === "error"
									? styles.invalidClassNameWarning
									: styles.suspiciousClassNameWarning,
								styles.classNameWarning,
							].join(" ")}
						>
							{validationResult?.type === "suspicious" ? (
								<>
									<FontAwesomeIcon icon={faCircleInfo} />{" "}
								</>
							) : (
								""
							)}
							{validationResult?.message}
						</p>
					)
				}
			</SimpleModal>
		</>
	);
}
