// @ts-ignore TODO violates the types folder
import {ActorList, ShapeList, TextList} from "../@types/Types";

import {mapFilenameToImageModule} from "../@types/AssetTypes";
import {requestUpdate} from "../util/messengerWorkerUtils";
import {
	Application,
	Assets,
	Container,
	Sprite,
	Text,
	Graphics,
} from "@pixi/webworker";

// TODO (shawn): consider experimentation
const TARGET_FPS = 60;
const MAX_FRAMES_PER_UPDATE = 3;

/**
 * MobX store for worker/Pixi communication and state.
 */
class WorkerStore {
	private _isRunning = true;

	private _app: Application | null = null;

	private _spriteContainer: Container = new Container();
	private _background: Sprite = new Sprite();
	private _canvasText: Record<string, Text> = {};
	private _canvasActors: Record<string, Sprite> = {};
	private _canvasShapes: Record<string, Graphics> = {};
	private _handle: number | null = null;
	private _frameCount = 0;

	constructor() {}

	// methods

	/**
	 * Initializes the canvas with the given options.
	 * @param options.width The width of the canvas.
	 * @param options.height The height of the canvas.
	 * @param options.view The OffscreenCanvas to use.
	 */
	initCanvas(options: {width: number; height: number; view: OffscreenCanvas}) {
		if (this._app) {
			return;
		}
		this._app = new Application({
			...options,
		});

		this._app.stage.addChild(this._spriteContainer);
		this._spriteContainer.addChild(this._background);
		this._spriteContainer.sortableChildren = true;

		this.isRunning = false;
	}

	destroyCanvas() {
		this._app?.destroy();
		this._app = null;
	}

	/**
	 * Perform as many updates as is necessary to catch up with the reference frame rate. If
	 * more than MAX_FRAMES_PER_UPDATE are believed to be required to catch up, only perform that
	 * many updates. TODO (shawn, etc.): Consider whether this is intelligent.
	 * @param timeSinceOrigin Time as reported by performance.now() -- ms since creating the worker
	 * @param isStart Whether this is the first frame
	 */
	requestAnimation(timeSinceOrigin: number, isStart = false) {
		if (this._isRunning) {
			const framesSinceTimeOrigin = Math.floor(
				timeSinceOrigin / (1000 / TARGET_FPS)
			);
			const stepCount = isStart
				? 1
				: Math.min(
						MAX_FRAMES_PER_UPDATE,
						framesSinceTimeOrigin - this._frameCount
					);
			if (stepCount > 0) {
				requestUpdate(stepCount);
			}
			this._frameCount = framesSinceTimeOrigin;
			this._handle = self.requestAnimationFrame(
				this.requestAnimation.bind(this)
			);
		}
	}

	async updateCanvas(theaterObject: any) {
		if (theaterObject.stage) {
			this._background.texture = await Assets.load(
				mapFilenameToImageModule(theaterObject.stage.image)
			);
			this._background.x = 0;
			this._background.y = 0;
		}

		// Actors
		await this.updateActors(theaterObject.actors ?? []);

		// Texts
		this.updateTexts(theaterObject.texts ?? []);

		// Shapes
		this.updateShapes(theaterObject.shapes ?? []);
		this._spriteContainer.sortChildren();
	}

	// Updates the attributes of each theater object (sprite) by UID
	// If no UID is found in the current list of sprites, a new sprite is created
	async updateTheater(theater: string) {
		// This probably shouldn't happen in finished product, but currently, the Java Engine will return an empty
		// string when you go out of bounds.
		if (!theater) {
			return;
		}

		const theaterObject = JSON.parse(theater);
		await this.updateCanvas(theaterObject);
	}
	resetTheater(theater: string) {
		const theaterObject = JSON.parse(theater);
		if (this._app) {
			this._spriteContainer.removeChildren();
			this._background = new Sprite();
			this._spriteContainer.addChild(this._background);
			this._canvasText = {};
			this._canvasActors = {};
			this._canvasShapes = {};
		}
		this.updateCanvas(theaterObject);
	}

	async updateActors(actorList: ActorList) {
		await Promise.all(
			actorList.map(async (actor) => {
				if (actor.removed) {
					const found = this._canvasActors[actor.uuid];
					if (found) {
						this._spriteContainer.removeChild(found);
						delete this._canvasActors[actor.uuid];
						found.destroy();
					}
					return;
				}

				let sprite = this._canvasActors[actor.uuid];
				if (!sprite) {
					// create if it doesn't exist
					sprite = this._canvasActors[actor.uuid] = new Sprite();
					this._spriteContainer.addChild(sprite);
					sprite.anchor.set(0.5);
				}

				sprite.texture = actor.image
					? await Assets.load(mapFilenameToImageModule(actor.image))
					: sprite.texture;
				sprite.x = actor.x ?? sprite.x;
				sprite.y = actor.y ?? sprite.y;
				sprite.zIndex = actor.z ?? sprite.zIndex;
				sprite.rotation = actor.rotation ?? sprite.rotation;
				sprite.tint = actor.tint ?? sprite.tint;
				sprite.alpha = actor.alpha ?? sprite.alpha;

				if (actor.width) {
					sprite.width = actor.width;
					sprite.scale.x = Math.sign(actor.width) * Math.abs(sprite.scale.x);
				}

				if (actor.height) {
					sprite.height = actor.height;
					sprite.scale.y = Math.sign(actor.height) * Math.abs(sprite.scale.y);
				}
			})
		);
	}

	updateTexts(textList: TextList) {
		textList.forEach((text) => {
			if (text.removed) {
				const found = this._canvasText[text.uuid];
				if (found) {
					this._spriteContainer.removeChild(found);
					delete this._canvasText[text.uuid];
					found.destroy();
				}
				return;
			}

			let canvasText = this._canvasText[text.uuid];
			if (!canvasText) {
				canvasText = this._canvasText[text.uuid] = new Text(text.content);
				canvasText.style.fontFamily = "monospace";
				this._spriteContainer.addChild(canvasText);
				canvasText.anchor.set(0.5);
			}

			canvasText.style.fontFamily = text.font ?? canvasText.style.fontFamily;
			canvasText.style.fontSize = text.fontSize ?? canvasText.style.fontSize;
			canvasText.style.fontStyle = text.italic ? "italic" : "normal";
			canvasText.style.fontWeight = text.bold ? "bold" : "normal";
			canvasText.style.align = text.alignment ?? canvasText.style.align;
			canvasText.style.wordWrap = text.wordWrap ?? canvasText.style.wordWrap;
			canvasText.style.wordWrapWidth =
				text.wordWrapWidth ?? canvasText.style.wordWrapWidth;
			canvasText.style.fill = text.fontColor ?? canvasText.style.fill;
			canvasText.text = text.content ?? canvasText.text;
			canvasText.x = text.x ?? canvasText.x;
			canvasText.y = text.y ?? canvasText.y;
			canvasText.zIndex = text.z ?? canvasText.zIndex;
			canvasText.rotation = text.rotation ?? canvasText.rotation;
			canvasText.alpha = text.alpha ?? canvasText.alpha;
		});
	}

	updateShapes(shapeList: ShapeList) {
		shapeList.forEach((shape) => {
			if (shape.removed) {
				const found = this._canvasShapes[shape.uuid];
				if (found) {
					this._spriteContainer.removeChild(found);
					delete this._canvasShapes[shape.uuid];
					found.destroy();
				}
				return;
			}

			let canvasShape = this._canvasShapes[shape.uuid];
			if (canvasShape) {
				canvasShape.clear();
			} else {
				canvasShape = this._canvasShapes[shape.uuid] = new Graphics();
				this._spriteContainer.addChild(canvasShape);
			}

			// Note: No fine-grained diffs are sent. If any property is changed, the entire shape is re-sent.
			switch (shape.type) {
				case "rectangle":
					canvasShape.clear();
					canvasShape.lineStyle(
						shape.borderWeight,
						shape.borderColor,
						shape.borderAlpha
					);
					canvasShape.beginFill(shape.fillColor, shape.fillAlpha);
					canvasShape.drawRect(
						shape.x - shape.width / 2,
						shape.y - shape.height / 2,
						shape.width,
						shape.height
					);
					canvasShape.endFill();
					canvasShape.pivot.set(shape.x, shape.y);
					canvasShape.position.set(shape.x, shape.y);
					canvasShape.rotation = shape.rotation;
					break;
				case "polygon":
					canvasShape.clear();
					canvasShape.lineStyle(
						shape.borderWeight,
						shape.borderColor,
						shape.borderAlpha
					);
					canvasShape.beginFill(shape.fillColor, shape.fillAlpha);
					canvasShape.drawPolygon(shape.vertices);
					canvasShape.endFill();
					break;
				case "ellipse":
					canvasShape.clear();
					canvasShape.lineStyle(
						shape.borderWeight,
						shape.borderColor,
						shape.borderAlpha
					);
					canvasShape.beginFill(shape.fillColor, shape.fillAlpha);
					canvasShape.drawEllipse(
						shape.x,
						shape.y,
						shape.width / 2,
						shape.height / 2
					);
					canvasShape.endFill();
					canvasShape.pivot.set(shape.x, shape.y);
					canvasShape.position.set(shape.x, shape.y);
					canvasShape.rotation = shape.rotation;
					break;
				case "line":
					canvasShape.clear();
					canvasShape.lineStyle(shape.weight, shape.color, shape.alpha);
					canvasShape.moveTo(shape.x1, shape.y1);
					canvasShape.lineTo(shape.x2, shape.y2);
					break;
			}
			canvasShape.zIndex = shape.z;
		});
	}

	togglePixi() {
		this._isRunning = !this._isRunning;

		if (this._isRunning) {
			this.requestAnimation(performance.now(), true);
		} else {
			this.cancelAnimation();
		}
	}

	cancelAnimation() {
		if (this._handle) {
			self.cancelAnimationFrame(this._handle);
			this._handle = null;
		}
	}

	// autogen getters and setters for MobX actions

	get isRunning() {
		return this._isRunning;
	}

	set isRunning(value) {
		this._isRunning = value;
	}
}

export default WorkerStore;
