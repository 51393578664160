// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileLeaf-module__superclassRow--jvp4o {
  display: flex;
  height: 2.5rem;
  padding: 0px 8px 0px 4px;
  align-items: center;
  gap: 8px;
  border-radius: 0.25rem;
  cursor: pointer;
}
.FileLeaf-module__superclassRow--jvp4o:hover {
  background: var(--file-tree-hover-background-color);
}

.FileLeaf-module__superclassHeader--caYLT {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.FileLeaf-module__fileContainer--YrxFV {
  display: flex;
  flex-direction: column;
}

.FileLeaf-module__fileRow--HC1hk {
  display: flex;
  height: 2.5rem;
  padding-left: 2rem;
  align-items: center;
  gap: 16px;
}
.FileLeaf-module__fileRow--HC1hk:hover {
  background: var(--file-tree-hover-background-color);
}
.FileLeaf-module__fileRow--HC1hk.FileLeaf-module__focusedFile--ZYvPM {
  background: var(--file-tree-hover-background-color);
}

.FileLeaf-module__line--vcNdc {
  width: 1px;
  height: 2.75rem;
  background: var(--file-tree-line-color);
}

.FileLeaf-module__chevron--g9cZl {
  height: 0.75rem;
  width: 0.75rem;
  min-width: 0.75rem;
  min-height: 0.75rem;
  color: var(--file-tree-chevron-icon-color);
}

.FileLeaf-module__editIcon--le0UO {
  color: var(--file-tree-file-icon-color);
}

.FileLeaf-module__fileLinesContainer--gqHYu {
  display: flex;
  width: 20px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--file-tree-file-icon-color);
}`, "",{"version":3,"sources":["webpack://./src/components/FileTree/FileLeaf.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,cAAA;EACA,wBAAA;EACA,mBAAA;EACA,QAAA;EACA,sBAAA;EACA,eAAA;AACD;AACC;EACC,mDAAA;AACF;;AAGA;EACC,aAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;AAAD;;AAGA;EACC,aAAA;EACA,sBAAA;AAAD;;AAGA;EACC,aAAA;EACA,cAAA;EAEA,kBAAA;EACA,mBAAA;EACA,SAAA;AADD;AAGC;EACC,mDAAA;AADF;AAIC;EACC,mDAAA;AAFF;;AAMA;EACC,UAAA;EACA,eAAA;EACA,uCAAA;AAHD;;AAMA;EACC,eAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,0CAAA;AAHD;;AAMA;EACC,uCAAA;AAHD;;AAMA;EACC,aAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EAEA,uCAAA;AAJD","sourcesContent":[".superclassRow {\n\tdisplay: flex;\n\theight: 2.5rem;\n\tpadding: 0px 8px 0px 4px;\n\talign-items: center;\n\tgap: 8px;\n\tborder-radius: 0.25rem;\n\tcursor: pointer;\n\n\t&:hover {\n\t\tbackground: var(--file-tree-hover-background-color);\n\t}\n}\n\n.superclassHeader {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 8px;\n\tflex: 1 0 0;\n}\n\n.fileContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.fileRow {\n\tdisplay: flex;\n\theight: 2.5rem;\n\n\tpadding-left: 2rem;\n\talign-items: center;\n\tgap: 16px;\n\n\t&:hover {\n\t\tbackground: var(--file-tree-hover-background-color);\n\t}\n\n\t&.focusedFile {\n\t\tbackground: var(--file-tree-hover-background-color);\n\t}\n}\n\n.line {\n\twidth: 1px;\n\theight: 2.75rem;\n\tbackground: var(--file-tree-line-color);\n}\n\n.chevron {\n\theight: 0.75rem;\n\twidth: 0.75rem;\n\tmin-width: 0.75rem;\n\tmin-height: 0.75rem;\n\tcolor: var(--file-tree-chevron-icon-color);\n}\n\n.editIcon {\n\tcolor: var(--file-tree-file-icon-color);\n}\n\n.fileLinesContainer {\n\tdisplay: flex;\n\twidth: 20px;\n\theight: 16px;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 10px;\n\n\tcolor: var(--file-tree-file-icon-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"superclassRow": `FileLeaf-module__superclassRow--jvp4o`,
	"superclassHeader": `FileLeaf-module__superclassHeader--caYLT`,
	"fileContainer": `FileLeaf-module__fileContainer--YrxFV`,
	"fileRow": `FileLeaf-module__fileRow--HC1hk`,
	"focusedFile": `FileLeaf-module__focusedFile--ZYvPM`,
	"line": `FileLeaf-module__line--vcNdc`,
	"chevron": `FileLeaf-module__chevron--g9cZl`,
	"editIcon": `FileLeaf-module__editIcon--le0UO`,
	"fileLinesContainer": `FileLeaf-module__fileLinesContainer--gqHYu`
};
export default ___CSS_LOADER_EXPORT___;
