import React, {CSSProperties} from "react";
import {observer} from "mobx-react-lite";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import Theater from "../Theater/Theater";
import DragBar from "../Dragbar/DragBar";
import Ide from "../Ide/Ide";

import styles from "./CsController.module.css";

export type Props = {
	width?: CSSProperties["width"];
	height?: CSSProperties["height"];
};

// Main Component
const CsController = (props: Props) => {
	const {width, height} = props;
	return (
		<div className={styles.csControllerWrapper} style={{width, height}}>
			<ErrorBoundary fallback={<ErrorComponent />}>
				<Theater />
				<DragBar />
				<Ide />
			</ErrorBoundary>
		</div>
	);
};

export default observer(CsController);
