import React, {createContext} from "react";
import type {PropsWithChildren} from "react";
import {CheckboxGroupState, useCheckboxGroupState} from "react-stately";
import {AriaCheckboxGroupProps, useCheckboxGroup} from "react-aria";

interface ICheckboxGroupLabel
	extends PropsWithChildren,
		Omit<AriaCheckboxGroupProps, "description" | "errorMessage"> {
	readonly label: React.ReactNode;
}

interface ICheckboxGroupAriaLabel
	extends PropsWithChildren,
		Omit<AriaCheckboxGroupProps, "description" | "errorMessage"> {
	readonly "aria-label": string;
}

export type ICheckboxGroup = ICheckboxGroupLabel | ICheckboxGroupAriaLabel;

/**
 * Accept user selection of one or more Checkboxes via mouse or keyboard
 */
export function CheckboxGroup(props: ICheckboxGroup) {
	const state = useCheckboxGroupState(props);
	const {groupProps, labelProps} = useCheckboxGroup(props, state);

	return (
		<div {...groupProps}>
			<span {...labelProps}>{props.label}</span>
			<CheckboxGroupContext.Provider value={state}>
				{props.children}
			</CheckboxGroupContext.Provider>
		</div>
	);
}

export const CheckboxGroupContext = createContext<CheckboxGroupState>({
	value: [],
	isDisabled: false,
	isReadOnly: false,
	isSelected: () => false,
	setValue: () => {},
	addValue: () => {},
	removeValue: () => {},
	toggleValue: () => {},
	validationState: "valid",
});
