/**
 * Entry point for building the client code. Does not automatically start things
 * up; instead exports a render function that gets passed a DOM element.
 * Used to embed the client in non-React environments.
 */

import React from "react";
import CsHome from "./pages/Home";
import {createRoot} from "react-dom/client";
import {CsInstanceTypeEnum} from "./state/CsInstance";
// import TorchboardRouter from "./pages/Router";
//  import {useTorchAppState} from "./state/TorchboardAppState";
// import {Session} from "./shared/Session";

type StartupProps = {
	pageData: {
		// session?: Session;
		baseUrl: string;
	};
	serverUrl?: string;
};

function render(container: HTMLElement, props: StartupProps) {
	// This was the starter code in Torchboard, Grid-Crypt, etc.
	// much of which is probably valid but the components referenced here don't exist yet in this project.

	// const {pageData} = props;
	// const appState = useTorchAppState.getState();
	// const mainEl = React.createElement(TorchboardRouter, {
	// 	session: pageData.session,
	// 	appState,
	// });
	// ReactDOM.render(mainEl, container);

	const root = createRoot(container);
	const mainEl = React.createElement(CsHome, {serverUrl: props.serverUrl});
	root.render(mainEl);
}

/**
 * For rendering a snippet: no theater, just the editor and console.
 */
function renderSnippet(container: HTMLElement, props: StartupProps) {
	const root = createRoot(container);
	const mainEl = React.createElement(CsHome, {
		serverUrl: props.serverUrl,
		csInstanceType: CsInstanceTypeEnum.Editor,
	});
	root.render(mainEl);
}

export {render, renderSnippet};
