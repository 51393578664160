import React, {ReactNode} from "react";
import styles from "./SimpleModal.module.scss";
import Modal from "./Modal";

/**
 * Also export a pre-styled modal with a title, text, and submit/cancel buttons.
 */
export interface SimpleModalType {
	children?: ReactNode;
	isOpen: boolean;
	toggle: () => void;
	title: string;
	onSuccess: () => void;
	onCancel?: () => void;
	isErrorVariant?: boolean;
	disableSubmit?: boolean;
	options?: {
		submitText?: string;
		cancelText?: string;
	};
}

export default function SimpleModal(props: SimpleModalType) {
	const onKeyDownHandler = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case "Enter":
				onClickSuccess();
				break;
			case "Escape":
				onClickCancel();
				break;
			default:
				break;
		}
	};

	const onClickSuccess = () => {
		props.onSuccess();
		props.toggle();
	};

	const onClickCancel = () => {
		props.onCancel && props.onCancel();
		props.toggle();
	};

	return (
		<Modal isOpen={props.isOpen} toggle={props.toggle}>
			<div
				className={[
					styles.topBar,
					props.isErrorVariant ? styles.errorVariant : "",
				].join(" ")}
			/>
			<div className={styles.modalContent}>
				<div className={styles.modalTextContainer}>
					<div className={styles.modalTitle}>{props.title}</div>
					<div className={styles.modalBody} onKeyDown={onKeyDownHandler}>
						{/* This is where the modal content goes */}
						{props.children}
					</div>
				</div>

				<div className={styles.modalButtonContainer}>
					<button
						className={[styles.modalButton, styles.buttonCancel].join(" ")}
						onClick={onClickCancel}
					>
						{props.options?.cancelText ?? "Cancel"}
					</button>
					<button
						className={[
							styles.modalButton,
							styles.buttonConfirm,
							props.isErrorVariant ? styles.errorVariant : "",
						].join(" ")}
						disabled={props.disableSubmit ?? false}
						onClick={onClickSuccess}
					>
						{props.options?.submitText ?? "Confirm"}
					</button>
				</div>
			</div>
		</Modal>
	);
}
