import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useCsContext} from "../../../src/state/CsInstance";
import styles from "./DragBar.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsLeftRight} from "@fortawesome/sharp-solid-svg-icons/faArrowsLeftRight";

function DragBar() {
	const {theaterStore} = useCsContext();

	useEffect(() => {
		let mouseDown = false;
		let startX = 0;
		const downListener = (e: MouseEvent) => {
			startX = e.clientX;
			mouseDown = true;
		};

		const moveListener = (e: MouseEvent) => {
			if (!mouseDown) {
				return;
			}
			const distanceMoved = e.clientX - startX;
			if (distanceMoved > 40) {
				theaterStore.expandTheater();
				mouseDown = false;
			}
			if (distanceMoved < -40) {
				theaterStore.collapseTheater();
				mouseDown = false;
			}
		};

		const upListener = () => {
			mouseDown = false;
		};

		const element = document.getElementById("dragBar");
		if (!element) {
			throw new Error("DragBar not found");
		}

		// Attach listeners
		element.addEventListener("mousedown", downListener);
		document.addEventListener("mousemove", moveListener);
		document.addEventListener("mouseup", upListener);

		return () => {
			element.removeEventListener("mousedown", downListener);
			document.removeEventListener("mousemove", moveListener);
			document.removeEventListener("mouseup", upListener);
		};
	});

	return (
		<div className={styles.dragBar} id="dragBar">
			<div className={[styles.dragBarHandle, "fa-layers"].join(" ")}>
				<FontAwesomeIcon
					icon={faArrowsLeftRight}
					className={styles.iconOutline}
				/>
				<FontAwesomeIcon icon={faArrowsLeftRight} className={styles.icon} />
			</div>
		</div>
	);
}

export default observer(DragBar);
