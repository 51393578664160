import React, {PropsWithChildren} from "react";
import type {CSSWithVariables} from "../../types";
import styles from "./Axis.module.css";
import {SpaceTokenName} from "../../types";
import {makeSpaceCSSVariable} from "../../internal/flattenObjectToCSS";

export interface IAxis extends PropsWithChildren {
	readonly breakpointWidth?: CSSWithVariables["width"] & string;
	readonly gap?: 0 | SpaceTokenName;
	readonly justify?: CSSWithVariables["justifyContent"];
	readonly alignItems?: CSSWithVariables["alignItems"];
	readonly columnUntilRow?: boolean;
}

/**
 * Lay children in a row. When they do not fit, instead of overflowing, switch to
 * a column.
 *
 * If `columnUntilRow`, lay children in a column. Switch to a row when
 * parent width is below `breakpointWidth`.
 *
 */
export function Axis({
	children,
	breakpointWidth,
	gap = "space-2",
	justify,
	alignItems,
	columnUntilRow = false,
}: IAxis) {
	const style = {
		"--axis-breakpointWidth": breakpointWidth,
		"--axis-gap": makeSpaceCSSVariable(gap),
		"--axis-justify": justify,
		"--axis-alignItems": alignItems,
	} as CSSWithVariables;
	return (
		<div
			className={columnUntilRow ? styles.verticalAxis : styles.axis}
			style={style}
		>
			{children}
		</div>
	);
}
