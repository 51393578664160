import React from "react";
import styles from "./CodeEditorMenu.module.scss";
import ModalConfirm from "../Modals/ModalConfirm";
import UseModal from "../UseModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/sharp-regular-svg-icons/faEdit";
import {useCsContext} from "../../../src/state/CsInstance";
import {focusFile} from "../FileTree/File";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faCat} from "@fortawesome/sharp-regular-svg-icons/faCat";
import {fa0} from "@fortawesome/sharp-regular-svg-icons/fa0";
import {faChevronRight} from "@fortawesome/sharp-regular-svg-icons/faChevronRight";
import {faMinus} from "@fortawesome/sharp-regular-svg-icons/faMinus";
import {faPlus} from "@fortawesome/sharp-regular-svg-icons/faPlus";
import {faWindows} from "@fortawesome/free-brands-svg-icons/faWindows";
import {faCommand} from "@fortawesome/sharp-regular-svg-icons/faCommand";
import {DEFAULT_EDITOR_FONT_SIZE} from "../../../src/state/theaterStore";

export type CodeEditorMenuProps = {
	setCodeEditorMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	codeEditorMenuOpen: boolean;
};
const resetTitle = "Are you sure you want to reset this project?";
const resetText =
	"This will undo all changes you made to all the files in this project.";
const clearTitle = "Are you sure you want to reset the IDE?";
const clearText =
	"This will undo all changes you made to ALL the projects. This should only be done as a last resort.";

const CodeEditorMenu = ({
	setCodeEditorMenuOpen,
	codeEditorMenuOpen,
}: CodeEditorMenuProps) => {
	const {codeStore, theaterStore} = useCsContext();
	const {isOpen, toggle} = UseModal();
	const [shouldResetAll, setShouldResetAll] = React.useState(false);
	const [codeEditorSubmenuOpen, setCodeEditorSubmenuOpen] =
		React.useState(false);
	const [resetDisabled, setResetDisabled] = React.useState(true);

	function onKeyDownHandler(event: KeyboardEvent) {
		if (event.key === "Shift") {
			setResetDisabled(false);
		}
	}

	function onKeyUpHandler(event: KeyboardEvent) {
		if (event.key === "Shift") {
			setResetDisabled(true);
		}
	}

	// Menu measurements (menusurements) for positioning the tab submenu
	const menuWidth = document.getElementById("codeEditorMenu")?.offsetWidth;
	const menuHeight = document.getElementById("codeEditorMenu")?.offsetHeight;

	/**
	 * Creates a menu item for the code editor menu.
	 * @param text The text to display in the menu item.
	 * @param hotkey The hotkey associated with the menu item keyboard command.
	 */
	function codeEditorMenuItem(text: string, hotkey?: IconProp) {
		// Determines if the user is on an iOS device so we can show the correct metakey icon.
		function isIos() {
			return (
				navigator.userAgent.includes("Mac") ||
				navigator.userAgent.includes("iPhone") ||
				navigator.userAgent.includes("iPad")
			);
		}

		const metaKey = isIos() ? faCommand : faWindows;

		/**
		 * Pre-text area for the menu item. If the text is "Open Tabs", it will display the number of open tabs. Otherwise, it will be empty.
		 */
		function preTextArea() {
			if (text === "Open Tabs") {
				return (
					<div className={styles.fileCount}>
						{codeStore.filesOpen.filter((f) => !!f).length}
					</div>
				);
			}
			return <div className={[styles.fileCount, styles.empty].join(" ")}></div>;
		}

		/**
		 * Post-text area for the menu item. If the text is "Open Tabs", it will display the chevron icon. Otherwise, it will display the keyboard commands for the menu item action if applicable and will be empty if not.
		 */
		function postTextArea() {
			if (!hotkey) {
				hotkey = faCat; // Dummy value, we still need to render the hotkey div for formatting purposes
			}
			if (text === "Open Tabs") {
				return (
					<div className={styles.openTabsIcon}>
						<FontAwesomeIcon icon={faChevronRight} className={styles.icon} />
					</div>
				);
			}

			return (
				<div
					className={styles.hotkey}
					style={hotkey === faCat ? {visibility: "hidden"} : {}}
				>
					<FontAwesomeIcon icon={metaKey as IconProp} className={styles.icon} />
					<FontAwesomeIcon icon={faPlus} className={styles.plusIcon} />
					<FontAwesomeIcon icon={hotkey} className={styles.icon} />{" "}
				</div>
			);
		}

		function onMenuItemClick() {
			switch (text) {
				case "Zoom in":
					theaterStore.editorFontSize += 1;
					setCodeEditorMenuOpen(false);
					break;

				case "Zoom out":
					theaterStore.editorFontSize = Math.max(
						theaterStore.editorFontSize - 1,
						1
					);
					setCodeEditorMenuOpen(false);
					break;

				case "Zoom to 50%":
					theaterStore.editorFontSize = DEFAULT_EDITOR_FONT_SIZE / 2;
					setCodeEditorMenuOpen(false);

					break;

				case "Zoom to 100%":
					theaterStore.editorFontSize = DEFAULT_EDITOR_FONT_SIZE;
					setCodeEditorMenuOpen(false);
					break;

				case "Zoom to 200%":
					theaterStore.editorFontSize = DEFAULT_EDITOR_FONT_SIZE * 2;
					setCodeEditorMenuOpen(false);
					break;

				case "Open Tabs":
					setCodeEditorSubmenuOpen(!codeEditorSubmenuOpen);
					break;

				case "Reset this project":
					setShouldResetAll(false);
					toggle();
					setCodeEditorMenuOpen(false);
					break;

				case "Reset IDE":
					setShouldResetAll(true);
					toggle();
					setCodeEditorMenuOpen(false);
					break;
			}
		}

		function disabled() {
			if (text === "Reset this project" || text === "Reset IDE") {
				return resetDisabled;
			}
			return false;
		}

		return (
			<div
				className={
					disabled()
						? [styles.option, styles.disabled].join(" ")
						: styles.option
				}
				onClick={() => (disabled() ? null : onMenuItemClick())}
			>
				{preTextArea()}
				<div className={styles.text}>{text}</div>
				{postTextArea()}
			</div>
		);
	}

	/**
	 * Creates a submenu item for the code editor menu.
	 * @param text The text to display in the submenu item, which is the name of the file to open.
	 */
	function codeEditorSubmenuItem(text: string) {
		function onClickSubmenuItem() {
			if (codeStore.focusedFile === text) {
				return;
			}
			const fileData = codeStore.visibleFileData[text];
			if (!fileData) {
				return;
			}
			focusFile(codeStore, fileData);
			setCodeEditorMenuOpen(false);
			setCodeEditorSubmenuOpen(false);
		}

		return (
			<div className={styles.option} key={text} onClick={onClickSubmenuItem}>
				<FontAwesomeIcon icon={faEdit} />
				<div className={styles.text}>{text}</div>
			</div>
		);
	}

	function onClickReset() {
		if (shouldResetAll) {
			codeStore.resetLocalStorageAndReload();
		} else {
			codeStore.resetProject();
		}
	}

	React.useEffect(() => {
		window.addEventListener("keydown", onKeyDownHandler);
		window.addEventListener("keyup", onKeyUpHandler);
		return () => {
			window.removeEventListener("keydown", onKeyDownHandler);
			window.removeEventListener("keyup", onKeyUpHandler);
		};
	}, []);

	return (
		<>
			<ModalConfirm
				isOpen={isOpen}
				title={shouldResetAll ? clearTitle : resetTitle}
				text={shouldResetAll ? clearText : resetText}
				isErrorVariant={true}
				toggle={toggle}
				onSuccess={onClickReset}
			/>
			<div
				className={styles.menu}
				id="codeEditorMenu"
				style={{
					visibility: codeEditorMenuOpen ? "visible" : "hidden",
				}}
			>
				<div className={styles.optionContainer}>
					<div className={styles.containerHeader}>Text Resizing</div>
					{codeEditorMenuItem("Zoom in", faPlus)}
					{codeEditorMenuItem("Zoom out", faMinus)}
					{codeEditorMenuItem("Zoom to 50%")}
					{codeEditorMenuItem("Zoom to 100%", fa0)}
					{codeEditorMenuItem("Zoom to 200%")}
				</div>

				<div className={styles.optionContainer}>
					<div className={styles.containerHeader}>Resetting</div>
					{/* {codeEditorMenuItem("Reset this project")} */}
					{codeEditorMenuItem("Reset IDE")}
				</div>

				<div className={styles.divider}></div>

				<div className={styles.optionContainer}>
					{codeEditorMenuItem("Open Tabs")}
				</div>
			</div>
			<div
				className={[styles.menu, styles.tabSubMenu].join(" ")}
				style={{
					right: menuWidth,
					top: menuHeight,
					visibility:
						codeEditorSubmenuOpen && codeEditorMenuOpen ? "visible" : "hidden",
				}}
			>
				<div
					className={[styles.submenuContent, styles.optionContainer].join(" ")}
				>
					<div className={styles.containerHeader}>Open Tabs</div>
					{codeStore.filesOpen
						.filter((f) => !!f)
						.map((fileName) => {
							return codeEditorSubmenuItem(fileName);
						})}
				</div>
			</div>
		</>
	);
};

export default CodeEditorMenu;
