import {useContext, useMemo} from "react";
import {ModeContext} from "./ModeContext";
import {flattenObjectToCSSVariables} from "../flattenObjectToCSS";
import {CSSWithVariables} from "../../types";
import {ComponentMode, Palette} from "../../types";
import {merge} from "lodash";
import {ThemeContext} from "../../theme/ThemeContext";

export interface IUseMode<P extends Palette> {
	readonly mode: ComponentMode<P>;
	readonly style: CSSWithVariables;
}

export function useMode<P extends Palette>(
	requestedComponent?: keyof ComponentMode<P>
): IUseMode<P> {
	const context = useContext(ModeContext);
	const {palette} = useContext(ThemeContext);

	const mode = useMemo(
		() => merge({}, context.layeredMode, context.mode),
		[context.layeredMode, context.mode]
	);

	const style = useMemo(
		() =>
			requestedComponent
				? flattenObjectToCSSVariables(
						{[requestedComponent]: mode[requestedComponent]},
						palette
				  )
				: flattenObjectToCSSVariables(mode, palette),
		[requestedComponent, palette, mode]
	);

	return {mode, style};
}
