import styles from "./ConsoleToast.module.scss";
import React from "react";

type Props = {
	message: string | JSX.Element;
	type: "error" | "success";
};

/**
 * A toast message that appears right above the console.
 */
const ConsoleToast: React.FC<Props> = (props: Props) => {
	const {message, type} = props;

	const classNames = [styles.consoleToastContainer, styles[type]];

	return <div className={classNames.join(" ")}>{message}</div>;
};

export default ConsoleToast;
