/**
 * This file handles snippets -- code files that students can only edit a
 * section of. In order to support snippets, this file includes an extension for
 * CodeMirror that prevents modification of certain ranges of text, as well as
 * decorations for those lines so it's clear in the UI.
 */

import {EditorState, Extension, Transaction} from "@codemirror/state";
import {EditorView} from "@codemirror/view";
import {getAvailableRanges} from "range-analyzer";
import {Decoration, StateEffect, StateField} from "@uiw/react-codemirror";

/**
 * Returns an array of {from, to} that define the ranges of the document that
 * are read-only in the current state. Used for verification of transactions of
 * state_1 to state_2 in CodeMirror via preventModifyCodeRanges.
 *
 * @param targetState The state to analyze
 *
 * @returns An array of {from, to} objects that define the read-only ranges.
 * Numbers are internal to CodeMirror and map to the n-th character in the
 * string -- use EditorState to generate them where possible.
 */
export const getReadOnlyRanges = (
	targetState: EditorState
): Array<{from: number | undefined; to: number | undefined}> => {
	try {
		const incomingLines = [...targetState.doc.iterLines()];

		// calculations for later use
		const classStartLineNum =
			incomingLines.findIndex(
				(line) =>
					line.startsWith("public class") ||
					line.includes("// snippet starts here")
			) + 1;
		const classCloseLineNum = incomingLines
			.reverse()
			.findIndex((line) =>
				line.startsWith("}" || line.includes("// snippet ends here"))
			);

		const editableStart = classStartLineNum;
		const editableEnd = incomingLines.length - classCloseLineNum;

		return [
			{
				from: undefined, //same as: targetState.doc.line(0).from or 0
				to: targetState.doc.line(editableStart).to,
			},
			{
				from: targetState.doc.line(editableEnd).from,
				to: undefined, // same as: targetState.doc.line(targetState.doc.lines).to
			},
		];
	} catch (e) {
		return [];
	}
};

/**
 * Calculate, from a selection, which parts of the selection are editable and
 * deletes those.
 */
export const smartDelete = () =>
	EditorState.transactionFilter.of((tr: Transaction) => {
		if (
			tr.isUserEvent("delete.selection") &&
			!tr.isUserEvent("delete.selection.smart") // not caused by smartDelete
		) {
			const initialSelections = tr.startState.selection.ranges.map((range) => ({
				from: range.from,
				to: range.to,
			}));

			if (initialSelections.length > 0) {
				const readOnlyRanges = getReadOnlyRanges(tr.startState);
				const availableRanges = getAvailableRanges(
					readOnlyRanges,
					initialSelections[0]!,
					{from: 0, to: tr.startState.doc.line(tr.startState.doc.lines).to}
				) as Array<{from: number; to: number}>;

				return availableRanges.map((range) =>
					tr.startState.update({
						changes: {
							from: range.from,
							to: range.to,
						},
						annotations: Transaction.userEvent.of(
							`${tr.annotation(Transaction.userEvent)}.smart`
						),
					})
				);
			}
		}

		return tr;
	});

/**
 * Looks at read-only ranges before and after a transaction, pulling them out
 * and diffing them to ensure they're the same.
 */
export const preventModifyTargetRanges = () =>
	EditorState.changeFilter.of((tr: Transaction) => {
		try {
			const readOnlyRangesBeforeTransaction = getReadOnlyRanges(tr.startState);
			const readOnlyRangesAfterTransaction = getReadOnlyRanges(tr.state);

			for (let i = 0; i < readOnlyRangesBeforeTransaction.length; i++) {
				const targetFromBeforeTransaction =
					readOnlyRangesBeforeTransaction[i]!.from ?? 0;
				const targetToBeforeTransaction =
					readOnlyRangesBeforeTransaction[i]!.to ??
					tr.startState.doc.line(tr.startState.doc.lines).to;

				// get the target ranges before and after transaction
				const targetFromAfterTransaction =
					readOnlyRangesAfterTransaction[i]!.from ?? 0;
				const targetToAfterTransaction =
					readOnlyRangesAfterTransaction[i]!.to ??
					tr.state.doc.line(tr.state.doc.lines).to;

				// diff content of the target ranges
				if (
					tr.startState.sliceDoc(
						targetFromBeforeTransaction,
						targetToBeforeTransaction
					) !==
					tr.state.sliceDoc(
						targetFromAfterTransaction,
						targetToAfterTransaction
					)
				) {
					return false;
				}
			}
		} catch (e) {
			return false;
		}
		return true;
	});

/**
 * A registered event handler for pasting text into the editor. Reads the
 * clipboard, and then if the user had selected text (i.e. is going to delete
 * text and replace it) that is non-editable, pastes at the editable part of the
 * range if possible.
 *
 * @returns An event handler that implements non-editable region aware pasting.
 */
export const smartPaste = () =>
	EditorView.domEventHandlers({
		paste(event, view) {
			const clipboardData =
				event.clipboardData || (window as any).clipboardData;
			const pastedData = clipboardData.getData("Text");
			const initialSelections = view.state.selection.ranges.map((range) => ({
				from: range.from,
				to: range.to,
			}));

			// if user is pasting into a highlighted range
			if (initialSelections.length > 0) {
				const readOnlyRanges = getReadOnlyRanges(view.state);
				const result = getAvailableRanges(
					readOnlyRanges,
					initialSelections[0]!,
					{from: 0, to: view.state.doc.line(view.state.doc.lines).to}
				) as Array<{from: number; to: number}>;
				if (result.length > 0) {
					view.dispatch({
						changes: {
							from: result[0]!.from,
							to: result[0]!.to,
							insert: pastedData,
						},
						annotations: Transaction.userEvent.of(`input.paste.smart`),
					});
				}
			}
			return true;
		},
	});

/**
 * Greying out non-editable sections of code.
 */

// things to define so CodeMirror is happy
const lineGreyoutDecoration = Decoration.line({
	// Using --neutral-variant-500 at 20% opacity until design visits this. The opacity lets the user see highlights on selected text.
	attributes: {style: "background-color: rgb(100, 115, 139, .2)"},
} as const);
export const addLineGreyout = StateEffect.define<number>();

// define the actual extension
export const lineGreyoutField = () =>
	StateField.define({
		create() {
			return Decoration.none;
		},
		update(lines, tr) {
			lines = lines.map(tr.changes);
			for (const e of tr.effects) {
				if (e.is(addLineGreyout)) {
					lines = lines.update({
						add: [lineGreyoutDecoration.range(e.value)],
					});
				}
			}
			return lines;
		},
		provide: (f) => EditorView.decorations.from(f),
	});

export function greyoutLine(lineNo: number, editorView: EditorView) {
	const docPosition = editorView.state.doc.line(lineNo).from;
	editorView.dispatch({effects: addLineGreyout.of(docPosition)});
}

const readOnlyRangesExtension = (): Extension => [
	smartPaste(),
	smartDelete(),
	preventModifyTargetRanges(),
	lineGreyoutField(),
];
export default readOnlyRangesExtension;
