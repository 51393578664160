// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoordinateDisplay-module__text--dhOOj {
  position: absolute;
  color: white;
  background-color: rgba(44, 43, 47, 0.5647058824);
  border-radius: 0.125rem;
  height: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0.25rem;
  font-family: "Roboto Flex", sans-serif;
  pointer-events: none;
  white-space: nowrap;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CoordinateDisplay/CoordinateDisplay.module.css"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,YAAA;EACA,gDAAA;EACA,uBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sCAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;AACD","sourcesContent":[".text {\n\tposition: absolute;\n\tcolor: white;\n\tbackground-color: #2c2b2f90;\n\tborder-radius: 0.125rem;\n\theight: 1.125rem;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 0.125rem 0.25rem;\n\tfont-family: \"Roboto Flex\", sans-serif;\n\tpointer-events: none;\n\twhite-space: nowrap;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `CoordinateDisplay-module__text--dhOOj`
};
export default ___CSS_LOADER_EXPORT___;
