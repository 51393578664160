import React, {useMemo, useRef} from "react";
import type {ReactNode} from "react";
import {AriaAccordionProps, useAccordion} from "@react-aria/accordion";
import {useTreeState} from "react-stately";
import {Stack} from "../../layout/Stack/Stack";
import {ChevronDown, ChevronUp} from "../../internal/icons";
import {AccordionItem} from "../../internal/Accordion/AccordionItem";
import styles from "./Accordion.module.css";
import {useMode} from "../../internal/Mode/useMode";
import {SpaceTokenName} from "../../types";

export interface IAccordion<T> extends AriaAccordionProps<T> {
	gap?: 0 | SpaceTokenName;
	openedIcon?: ReactNode;
	closedIcon?: ReactNode;
}

/**
 * Accept user opening and closing one or multiple `Item`s, each representing a section.
 */
export function Accordion<T extends object>({
	gap = "space-2",
	openedIcon = <ChevronUp />,
	closedIcon = <ChevronDown />,
	...props
}: IAccordion<T>) {
	const ref = useRef(null);
	const state = useTreeState(props);
	const {accordionProps} = useAccordion(props, state, ref);
	const {style} = useMode("accordion");

	const accordionItems = useMemo(
		() =>
			[...state.collection].map((item) => (
				<AccordionItem
					key={item.key}
					item={item}
					state={state}
					openedIcon={openedIcon}
					closedIcon={closedIcon}
				/>
			)),
		[state.collection]
	);

	return (
		<div
			{...accordionProps}
			onKeyDownCapture={(e) => {
				if ("value" in e.target) {
					// https://github.com/adobe/react-spectrum/issues/5745
					return;
				}
				accordionProps.onKeyDownCapture?.(e);
			}}
			className={styles.accordion}
			style={style}
			ref={ref}
		>
			<Stack gap={gap}>{accordionItems}</Stack>
		</div>
	);
}
