import {createGenericContext} from "./createGenericContext";

import CompileStore from "./compileStore";
import UserInputStore from "./userInputStore";
import CodeStore from "./codeStore";
import TheaterStore from "./theaterStore";
import UiStore from "./uiStore";
import FeatureFlagStore, {FeatureFlagStoreOpts} from "./featureFlagStore";
import TestsStore from "./testsStore";

import {parse as parseYaml} from "yaml";
import {Value} from "@sinclair/typebox/value";

import {Manager} from "../components/messengerManager";
import {CsProjectTypebox} from "../@types/TypeboxTypes";
import {autorun} from "mobx";
import {TestResults} from "../@types/Types";

export enum CsInstanceTypeEnum {
	Full = "Full", // default
	Theater = "Theater", // not yet implemented
	Editor = "Editor", // for snippets
}

/**
 * The main class that holds all the mobx stores and messenger manager.  This is
 * our main export for the CS package.
 *
 * Note: There may be a future in which there are multiple instances of this
 * class running on the same page.
 */
export default class CsInstance {
	private _codeStore: CodeStore;
	private _compileStore: CompileStore;
	private _userInputStore: UserInputStore;
	private _theaterStore: TheaterStore;
	private _uiStore: UiStore;
	private _featureFlagStore: FeatureFlagStore;
	private _testsStore: TestsStore;

	private _manager = new Manager(this);
	private _serverUrl = "";
	private _securityToken = "";

	// To keep track of what subcomponents to show
	private _useTheater = false;
	private _useEditor = false;

	// list of cleanup handlers to run when the instance is destroyed
	private _cleanupHandlers: (() => void)[] = [];

	// Callbacks which can be overwritten by consumers
	private _onCsReadyCallback: () => void = () => {};
	private _onTestResultsCallback: (
		projectName: string | null,
		testResults: TestResults
	) => void = () => {};

	/**
	 * Represents the entirety of state necessary for the CS classroom. Make sure
	 * to call destroy() before discarding the instance.
	 * @param props.serverUrl - URL of the CS compilation server
	 * @param props.csInstanceType - Type of CS instance to create.  Defaults to 'Full'.
	 * @param props.featureOpts - Feature flags to enable/disable
	 * @param props.securityToken - Security token to validate all server requests
	 * @param props.onCsReadyCallback - Callback to run once the CS instance is
	 *     ready to use.
	 * @param props.onTestResultsCallback - Callback to run when any tests are
	 *     run.  Allows consumer to access results of user-run tests.
	 */
	constructor(props: {
		serverUrl: string;
		csInstanceType?: CsInstanceTypeEnum;
		featureOpts?: FeatureFlagStoreOpts;
		securityToken?: string;
		onCsReadyCallback?: () => void;
		onTestResultsCallback?: (
			projectName: string | null,
			testResults: TestResults
		) => void;
	}) {
		this._featureFlagStore = new FeatureFlagStore(props.featureOpts);
		this._serverUrl = props.serverUrl;
		this._securityToken = props.securityToken || "";
		this.setCsInstanceType(props.csInstanceType || CsInstanceTypeEnum.Full);

		this._codeStore = new CodeStore({
			csInstance: this,
			serverUrl: props.serverUrl,
		});
		this._compileStore = new CompileStore({
			csInstance: this,
			serverUrl: props.serverUrl,
		});
		this._userInputStore = new UserInputStore();
		this._theaterStore = new TheaterStore();
		this._uiStore = new UiStore();
		this._testsStore = new TestsStore();

		this._onCsReadyCallback =
			props.onCsReadyCallback || this._onCsReadyCallback;
		this._onTestResultsCallback =
			props.onTestResultsCallback || this._onTestResultsCallback;
		this._setupReactions();
	}

	destroy() {
		this._cleanupHandlers.forEach((handler) => handler());
	}

	private _setupReactions() {
		const reactions = [
			// load new JAR into messenger whenever a new JAR is available
			() => {
				if (this._compileStore.versionedJarData) {
					this._manager.prepNewVersionedJarMessenger(
						...this._compileStore.versionedJarData
					);
					this._theaterStore.resetTheaterState();
				}
			},
		];
		this._cleanupHandlers = reactions.map((callback) => autorun(callback));
	}

	/**
	 * Parses a CS YAML and loads it into this CS instance, changing the activity
	 * to the one described in the YAML. If the YAML is invalid, does nothing.
	 @param yaml The Yaml string correlating to a CS Project to be parsed and displayed in the IDE.
	 @returns The name of the CS Project inputted.
	 */
	intakeProjectYaml(yaml: string): string {
		let yamlObj;
		try {
			// validate the yaml using TypeBox
			yamlObj = parseYaml(yaml);
		} catch (e) {
			throw new Error("Malformed Yaml");
		}

		if (!Value.Check(CsProjectTypebox, yamlObj)) {
			console.error("Here is the parsed result of the input yaml:", yamlObj);
			throw new Error(
				"Invalid activity YAML" +
					JSON.stringify([...Value.Errors(CsProjectTypebox, yamlObj)], null, 2)
			);
		}

		// drop it into FileStore
		this._codeStore.cacheProjectTemplate(yamlObj);

		return yamlObj.name;
	}

	getOrCreateMessenger = this._manager.getOrCreateMessenger.bind(this._manager);

	defaultConsoleLogCallback(s: string) {
		this._theaterStore.clearLog();
	}
	defaultConsoleInputCallback(s: number) {
		this._theaterStore.studentInputRequested = true;
	}

	private setCsInstanceType(csInstanceType: CsInstanceTypeEnum) {
		this._useTheater = [
			CsInstanceTypeEnum.Theater,
			CsInstanceTypeEnum.Full,
		].includes(csInstanceType);
		this._useEditor = [
			CsInstanceTypeEnum.Editor,
			CsInstanceTypeEnum.Full,
		].includes(csInstanceType);
	}

	/**
	 * Changes the compilation server URL for this CS instance
	 * @param url - URL of the CS compilation server
	 */
	setServerUrl(url: string) {
		this._serverUrl = url;
		this._codeStore.serverUrl = url;
		this._compileStore.serverUrl = url;
	}

	/**
	 * Changes the CS project to the given project, updating the
	 * state of all components attached to this CS instance.
	 * @param projectName - Name of the project to switch to
	 * @param shouldCompile - Whether to recompile the project after switching.
	 */
	switchProject(projectName: string, shouldCompile = true) {
		this._theaterStore.showManifest = false;
		this._codeStore.switchProject(projectName, shouldCompile);
	}

	get serverUrl() {
		return this._serverUrl;
	}

	get securityToken() {
		return this._securityToken;
	}

	set securityToken(token: string) {
		this._securityToken = token;
	}

	get codeStore() {
		return this._codeStore;
	}

	get compileStore() {
		return this._compileStore;
	}

	get userInputStore() {
		return this._userInputStore;
	}

	get theaterStore() {
		return this._theaterStore;
	}

	get uiStore() {
		return this._uiStore;
	}

	get featureFlagStore() {
		return this._featureFlagStore;
	}

	get testsStore() {
		return this._testsStore;
	}

	get manager() {
		return this._manager;
	}

	get useTheater() {
		return this._useTheater;
	}

	get useEditor() {
		return this._useEditor;
	}

	get onCsReadyCallback() {
		return this._onCsReadyCallback;
	}

	get onTestResultsCallback() {
		return this._onTestResultsCallback;
	}
}

/**
 * React Context which allows subcomponents to easily access the top-level
 * CsInstance.  Its type needs to be `CsInstance | null` to allow for
 * initialization here, even though it should never be used without a Provider
 * with a valid CsInstance value.
 */

export const [useCsContext, CsProvider] = createGenericContext<CsInstance>();
