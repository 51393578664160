/**
 * Detects and removes // SNIPPET START/END comments from filetext.
 * @param fileContent lines of code to remove snippet comments from
 * @returns new file content with snippet comments removed
 */
export function removeSnippetComments(fileContent: string) {
	return fileContent
		.split("\n")
		.filter((t) => !/^\s*\/\/\s*SNIPPET\s+(START|END)$/g.test(t))
		.join("\n");
}
