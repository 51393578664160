import React, {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useCsContext} from "../../state/CsInstance";

import type {FunctionTemplate} from "../../@types/Types";
import Modal from "./Modal";
import MethodCall from "./MethodCall";
import MethodList from "./MethodList";

function MethodManifestModal() {
	const {theaterStore} = useCsContext();
	const [isCalling, setIsCalling] = useState<boolean>(false);
	const [methodCall, setMethodCall] = useState<FunctionTemplate>(
		{} as FunctionTemplate
	);
	const [methodCallClass, setMethodCallClass] = useState<string>("");

	useEffect(() => {
		setIsCalling(false);
	}, [theaterStore.methodManifest]);

	if (!theaterStore.showManifest) return <></>;

	return (
		<>
			<Modal
				isOpen={theaterStore.showManifest}
				toggle={() => theaterStore.toggleShowManifest()}
				pinToTop={true}
			>
				{isCalling ? (
					<MethodCall
						setIsCalling={setIsCalling}
						functionCall={methodCall}
						setMethodCall={setMethodCall}
						methodCallClass={methodCallClass}
						setMethodCallClass={setMethodCallClass}
						toggle={() => theaterStore.toggleShowManifest()}
					/>
				) : (
					<MethodList
						methodList={theaterStore.methodManifest}
						setMethodCall={setMethodCall}
						setMethodCallClass={setMethodCallClass}
						setIsCalling={setIsCalling}
						toggle={() => theaterStore.toggleShowManifest()}
					/>
				)}
			</Modal>
		</>
	);
}

export default observer(MethodManifestModal);
