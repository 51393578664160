/**
 * CS Home, for CS locals and other non-embedded CS instances.
 * Consumers should use this package's exported CsInstance, CsProvider, etc.
 */

import React from "react";
import CsController from "../components/CsController/CsController";
import CsInstance, {CsInstanceTypeEnum, CsProvider} from "../state/CsInstance";
import "../global.scss";
import {observer} from "mobx-react-lite";

export type CsHomeProps = {
	serverUrl?: string;
	csInstanceType?: CsInstanceTypeEnum;
	securityToken?: string;
};

const CsHome = (props: CsHomeProps) => {
	const cs = React.useMemo(
		() =>
			new CsInstance({
				serverUrl: props.serverUrl || "",
				csInstanceType: props.csInstanceType || CsInstanceTypeEnum.Editor,
				featureOpts: {
					showActivityDropdown: true,
					loadPrevProject: true,
				},
				securityToken: props.securityToken,
			}),
		[props]
	);
	React.useEffect(() => {
		// Pull serverside Yamls and add it to the project list
		(async () => {
			const yaml = await cs.codeStore.getServerSideYamls();

			for (const entry of yaml) cs.intakeProjectYaml(entry);
			cs.switchProject("0 Aquapedia");
		})();
	}, [cs]);

	return (
		<CsProvider value={cs}>
			<CsController />
		</CsProvider>
	);
};

export default observer(CsHome);
