import React, {PropsWithChildren, createContext, useCallback} from "react";
import {useRadioGroup, AriaRadioGroupProps} from "react-aria";
import {RadioGroupState, useRadioGroupState} from "react-stately";
import {Stack} from "../../layout/Stack/Stack";

interface IRadioGroupLabel extends PropsWithChildren, AriaRadioGroupProps {
	readonly label: React.ReactNode;
}

interface IRadioGroupAriaLabel extends PropsWithChildren, AriaRadioGroupProps {
	readonly "aria-label": string;
	readonly label?: React.ReactNode;
}

export type IRadioGroup = IRadioGroupLabel | IRadioGroupAriaLabel;

/** Accept user selection of one Radio via mouse or keyboard. */
export function RadioGroup(props: IRadioGroup) {
	const state = useRadioGroupState(props);
	const {radioGroupProps, labelProps} = useRadioGroup(props, state);

	const MaybeStack = useCallback(
		props.label
			? ({children}: PropsWithChildren) => (
					<Stack>
						<span {...labelProps}>
							{props.label} {props.isRequired && "*"}
						</span>
						{children}
					</Stack>
			  )
			: ({children}: PropsWithChildren) => <div>{children}</div>,
		[props.label]
	);

	return (
		<div {...radioGroupProps}>
			<MaybeStack>
				<div
					aria-label={"aria-label" in props ? props["aria-label"] : undefined}
				>
					<RadioGroupContext.Provider value={state}>
						{props.children}
					</RadioGroupContext.Provider>
				</div>
			</MaybeStack>
		</div>
	);
}

export const RadioGroupContext = createContext<RadioGroupState>({
	name: "",
	isDisabled: false,
	isReadOnly: false,
	isRequired: false,
	validationState: null,
	selectedValue: null,
	setSelectedValue: () => {},
	lastFocusedValue: null,
	setLastFocusedValue: () => {},
});
