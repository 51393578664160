// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TheaterControls-module__theaterControlsContainer--kK_85 {
  padding: var(--theater-controls-padding);
  border: var(--thin) solid var(--theater-border-color);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  gap: 1.5rem;
  scrollbar-width: none; /* hide scrollbar: Firefox */
}

.TheaterControls-module__theaterControlsContainer--kK_85::-webkit-scrollbar {
  display: none; /* hide scrollbar: Safari and Chrome */
}

.TheaterControls-module__theaterSecondaryControlsContainer--agL02 {
  display: flex;
  gap: 0.5rem;
}

.TheaterControls-module__theaterSecondaryControlButton--uXEzJ {
  height: 2.25rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 2px solid var(--theater-secondary-buttons-border-color);
  border-radius: 0.25rem;
  background-color: var(--theater-secondary-buttons-background-color);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
}
.TheaterControls-module__theaterSecondaryControlButton--uXEzJ:hover {
  border-color: var(--theater-secondary-buttons-hover-border-color);
}
.TheaterControls-module__theaterSecondaryControlButton--uXEzJ:active {
  border-color: var(--theater-secondary-buttons-active-border-color);
  background-color: var(--theater-secondary-buttons-active-background-color);
}`, "",{"version":3,"sources":["webpack://./src/components/TheaterControls/TheaterControls.module.scss"],"names":[],"mappings":"AAAA;EACC,wCAAA;EACA,qDAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EACA,WAAA;EAEA,qBAAA,EAAA,4BAAA;AAAD;;AAEA;EACC,aAAA,EAAA,sCAAA;AACD;;AAEA;EACC,aAAA;EACA,WAAA;AACD;;AAEA;EAEC,eAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAGA,gEAAA;EACA,sBAAA;EACA,mEAAA;EACA,+CAAA;AAFD;AAIC;EACC,iEAAA;AAFF;AAKC;EACC,kEAAA;EACA,0EAAA;AAHF","sourcesContent":[".theaterControlsContainer {\n\tpadding: var(--theater-controls-padding);\n\tborder: var(--thin) solid var(--theater-border-color);\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tflex: 0 0 auto;\n\tgap: 1.5rem;\n\n\tscrollbar-width: none; /* hide scrollbar: Firefox */\n}\n.theaterControlsContainer::-webkit-scrollbar {\n\tdisplay: none; /* hide scrollbar: Safari and Chrome */\n}\n\n.theaterSecondaryControlsContainer {\n\tdisplay: flex;\n\tgap: 0.5rem;\n}\n\n.theaterSecondaryControlButton {\n\t// Layout\n\theight: 2.25rem;\n\taspect-ratio: 1/1;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\t// Style\n\toutline: 2px solid var(--theater-secondary-buttons-border-color);\n\tborder-radius: 0.25rem;\n\tbackground-color: var(--theater-secondary-buttons-background-color);\n\tbox-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);\n\n\t&:hover {\n\t\tborder-color: var(--theater-secondary-buttons-hover-border-color);\n\t}\n\n\t&:active {\n\t\tborder-color: var(--theater-secondary-buttons-active-border-color);\n\t\tbackground-color: var(--theater-secondary-buttons-active-background-color);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theaterControlsContainer": `TheaterControls-module__theaterControlsContainer--kK_85`,
	"theaterSecondaryControlsContainer": `TheaterControls-module__theaterSecondaryControlsContainer--agL02`,
	"theaterSecondaryControlButton": `TheaterControls-module__theaterSecondaryControlButton--uXEzJ`
};
export default ___CSS_LOADER_EXPORT___;
