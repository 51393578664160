// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingScreen-module__loadingScreenContainer--yM4S4 {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingScreen/LoadingScreen.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;AACD","sourcesContent":[".loadingScreenContainer {\n\twidth: 100%;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingScreenContainer": `LoadingScreen-module__loadingScreenContainer--yM4S4`
};
export default ___CSS_LOADER_EXPORT___;
