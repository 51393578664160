import React, {useMemo, useRef} from "react";
import {PropsWithChildren} from "react";
import styles from "./Button.module.css";
import {AriaButtonProps, useButton} from "react-aria";
import clsx from "clsx";
import {useMode} from "../../internal/Mode/useMode";
import { CSSWithVariables, Padding } from "../../types";
import { makeSpaceCSSVariable } from "../../internal/flattenObjectToCSS";

export interface IButton extends PropsWithChildren, AriaButtonProps {
	readonly buttonRef?: React.MutableRefObject<null>;
	readonly padding?: Padding;
}

/**
 * Accept clicks and keyboard presses from user.
 */
export function Button({buttonRef, ...props}: IButton) {
	const ref = buttonRef ? buttonRef : useRef(null);
	const {buttonProps} = useButton(props, ref);

	const paddingStyle = {
		"--button-padding": makeSpaceCSSVariable(props.padding ?? "space-2"),
	} as CSSWithVariables;
	
	const {style} = useMode("button");
	const mergedStyle = useMemo(
		() => ({...paddingStyle, ...style}),
		[style, paddingStyle]
	);

	return (
		<button
			{...buttonProps}
			className={clsx(styles.button)}
			style={mergedStyle}
			ref={ref}
		>
			{props.children}
		</button>
	);
}
