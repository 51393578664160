import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import File from "./File";
import {useCsContext} from "../../state/CsInstance";
import styles from "./FileLeaf.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/sharp-regular-svg-icons/faChevronDown";
import {faChevronRight} from "@fortawesome/sharp-regular-svg-icons/faChevronRight";
import {faFileLines} from "@fortawesome/sharp-regular-svg-icons/faFileLines";
import {faWrench} from "@fortawesome/sharp-regular-svg-icons/faWrench";
import {faEdit} from "@fortawesome/sharp-regular-svg-icons/faEdit";

type FileLeafProps = {
	superclassDictionary: {[key: string]: string[]};
	nodeName: string;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setResetFile: React.Dispatch<React.SetStateAction<string>>;
};

/**
 *  FileLeaf is a recursive component that renders a file or a superclass node in the file tree.
 * 	It handles the logic and styling to nest files under their superclasses.
 *
 * @param props
 *  - superclassDictionary: A dictionary mapping superclass names to their children files
 * 	- nodeName: The name of the file or superclass node
 *  - setModalOpen: A function to toggle the reset modal
 *  - setResetFile: A function to set the file to reset
 * @returns A FileLeaf component
 */

const FileLeaf: React.FC<FileLeafProps> = (props) => {
	const {codeStore} = useCsContext();
	const {fileData, focusedFile} = codeStore;
	const {superclassDictionary, nodeName, setModalOpen, setResetFile} = props;
	const [showFiles, setShowFiles] = useState(true);

	// If the node is one of the root superclasses, render the fancy chevron and collapsing functionality
	if (
		nodeName === "Actor" ||
		nodeName === "Stage" ||
		nodeName === "Utilities"
	) {
		if (!superclassDictionary[nodeName]) {
			return <></>;
		}
		return (
			<>
				<div key={nodeName}>
					<div
						className={styles.superclassRow}
						onClick={() => {
							setShowFiles(!showFiles);
						}}
					>
						<FontAwesomeIcon
							icon={showFiles ? faChevronDown : faChevronRight}
							className={styles.chevron}
						/>
						<div className={styles.superclassHeader}>
							<div className={styles.fileLinesContainer}>
								{nodeName === "Utilities" ? (
									<FontAwesomeIcon icon={faWrench} />
								) : (
									<FontAwesomeIcon icon={faFileLines} />
								)}
							</div>
							{nodeName}
						</div>
					</div>
					{showFiles &&
						superclassDictionary[nodeName]?.map((file) => {
							return (
								<FileLeaf
									superclassDictionary={superclassDictionary}
									nodeName={file}
									setModalOpen={setModalOpen}
									setResetFile={setResetFile}
									key={file}
								/>
							);
						})}
				</div>
			</>
		);
	} else {
		// If the node is not one of the root superclasses, it should be a file.
		const className = nodeName.split(".")[0]; // Drop the .java extension to get the class name
		if (!className || !superclassDictionary) {
			return <></>;
		}
		// If the className appears as a key in the superclassDictionary, it is a superclass and we should render its children
		const isSuperclass =
			className in superclassDictionary &&
			superclassDictionary[className]!.length > 0;

		const fileRowClassNames = [styles.fileRow];
		if (focusedFile === nodeName) {
			fileRowClassNames.push(styles.focusedFile);
		}

		return (
			<div key={nodeName}>
				<div className={fileRowClassNames.join(" ")}>
					{isSuperclass ? (
						<FontAwesomeIcon
							icon={showFiles ? faChevronDown : faChevronRight}
							className={styles.chevron}
							onClick={() => {
								setShowFiles(!showFiles);
							}}
						/>
					) : (
						<>
							<div className={styles.line}></div>
						</>
					)}
					<FontAwesomeIcon icon={faEdit} className={styles.editIcon} />
					<File
						fileData={fileData[nodeName]!}
						toggleResetModal={() => {
							setModalOpen((modalOpen) => !modalOpen);
							setResetFile(nodeName);
						}}
					/>
				</div>
				{isSuperclass &&
					showFiles &&
					superclassDictionary[className]?.map((file) => {
						return (
							<div
								style={{
									paddingLeft: "2rem",
								}}
								key={file}
							>
								<FileLeaf
									superclassDictionary={superclassDictionary}
									nodeName={file}
									setModalOpen={setModalOpen}
									setResetFile={setResetFile}
								/>
							</div>
						);
					})}
			</div>
		);
	}
};

export default observer(FileLeaf);
