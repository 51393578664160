// UseModal.tsx

import {useState} from "react";

export default function UseModal() {
	const [isOpen, setisOpen] = useState(false);

	const toggle = () => {
		setisOpen(!isOpen);
	};

	return {
		isOpen,
		toggle,
	};
}
