import React, {PropsWithChildren, useCallback, useMemo, useRef} from "react";
import {useSelectState} from "react-stately";
import {AriaSelectProps, HiddenSelect, useSelect} from "react-aria";
import {Popover} from "../../internal/Popover/Popover";
import {ListBox} from "../../internal/Listbox/ListBox";
import styles from "./Select.module.css";
import {Stack} from "../../layout/Stack/Stack";
import {SelectButton} from "../../internal/Select/SelectButton";

/** Symbol that may appear in the select button. */
const selectSymbolOptions: {[key: string]: string | null} = {
	none: null,
	DoubleArrow: "↕",
	DownArrow: "▼",
} as const;
type SelectSymbolName = keyof typeof selectSymbolOptions;

export interface ISelectBase
	extends Omit<AriaSelectProps<object>, "placeholder"> {
	readonly selectIcon?: SelectSymbolName | React.ReactNode;
	readonly hasSelectedCheckmark?: boolean;
	readonly placeholder?: React.ReactNode;
	readonly startIcon?: React.ReactNode;
}

export interface ISelectWithLabel extends ISelectBase {
	readonly label: React.ReactNode;
}

export interface ISelectWithAriaLabel extends ISelectBase {
	readonly "aria-label": string;
	readonly label?: React.ReactNode;
}

export type ISelect = ISelectWithLabel | ISelectWithAriaLabel;

/**
 * Accept user input via mouse or keyboard to select one item.
 * @param props.selectIcon - Show a select icon inside the button. Default is `DoubleArrow`.
 * @param props.hasSelectedCheckmark - Show a checkmark next to selected option in dropdown
 * @param props.placeholder - Placeholder text when no item is selected. Default is "..."
 * @param props.startIcon - Optional icon to show on the left of the button
 */
export function Select({
	hasSelectedCheckmark = true,
	selectIcon = "DoubleArrow",
	placeholder,
	startIcon,
	...props
}: ISelect) {
	const ref = useRef(null);
	const state = useSelectState(props);
	const {labelProps, triggerProps, valueProps, menuProps} = useSelect(
		props,
		state,
		ref
	);

	// Get the select symbol element (either a selectSymbol option or injected ReactNode)
	const selectSymbol = useMemo(
		() => (typeof selectIcon === "string" && selectIcon in selectSymbolOptions
					? selectSymbolOptions[selectIcon]
					: selectIcon),
		[selectIcon]
	);

	const MaybeStack = useCallback(
		props.label
			? ({children}: PropsWithChildren) => (
					<Stack>
						<div {...labelProps}>{props.label}</div>
						{children}
					</Stack>
			  )
			: ({children}: PropsWithChildren) => <div>{children}</div>,
		[props.label]
	);

	return (
		<MaybeStack>
			<HiddenSelect
				isDisabled={props.isDisabled}
				state={state}
				triggerRef={ref}
				label={props.label}
				name={props.name}
			/>
			<SelectButton {...triggerProps} buttonRef={ref}>
				<span {...valueProps} className={selectSymbol ? styles.clippedWidth : styles.fullWidth}>
					{state.selectedItem ? (
						<div className={styles.selectedDisplay}>
							{startIcon}
							<span className={styles.selectOption}>{state.selectedItem.rendered}</span>
						</div>
					) : (
						<div className={styles.selectOptionPlaceholder}>
							{placeholder ?? "..."}
						</div>
					)}
				</span>
				{selectSymbol && 
					<span aria-hidden="true" className={styles.selectArrow}>
						{selectSymbol}
					</span>
				}
			</SelectButton>
			{state.isOpen && (
				<Popover state={state} triggerRef={ref} placement="bottom start">
					<ListBox
						{...menuProps}
						hasSelectedCheckmark={hasSelectedCheckmark}
						state={state}
					/>
				</Popover>
			)}
		</MaybeStack>
	);
}
