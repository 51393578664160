import {createContext} from "react";

export interface IRadioContext {
	isFocused: boolean;
	isKeyboardFocused: boolean;
	isSelected: boolean;
	isPressed: boolean;
	isDisabled: boolean;
}

export const RadioContext = createContext<IRadioContext>({
	isFocused: false,
	isKeyboardFocused: false,
	isSelected: false,
	isPressed: false,
	isDisabled: false,
});
