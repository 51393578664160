import {AccordionItemAriaProps, useAccordionItem} from "@react-aria/accordion";
import clsx from "clsx";
import React, {ReactNode, Key, useRef} from "react";
import {useHover, FocusRing} from "react-aria";
import {TreeState} from "react-stately";
import styles from "./AccordionItem.module.css";

export interface IAccordionItem<T> extends AccordionItemAriaProps<T> {
	state: Omit<TreeState<T>, "disabledKeys">;
	openedIcon?: ReactNode;
	closedIcon?: ReactNode;
}

// Empty set of disabled keys. Disabling is anti-user.
const disabledKeys = new Set<Key>();

/** Section for an `Accordion` */
export function AccordionItem<T>({
	openedIcon,
	closedIcon,
	...props
}: IAccordionItem<T>) {
	const ref = useRef<HTMLButtonElement>(null);
	const {buttonProps, regionProps} = useAccordionItem<T>(
		props,
		{...props.state, disabledKeys},
		ref
	);
	const isOpen = props.state.expandedKeys.has(props.item.key);
	const {isHovered, hoverProps} = useHover({});

	return (
		<div
			className={clsx(styles.accordionItem, {
				[styles.accordionItemOpen]: isOpen,
				[styles.accordionItemHovered]: isHovered,
			})}
		>
			<FocusRing within focusRingClass={styles.accordionItemFocusRing}>
				<button
					ref={ref}
					{...buttonProps}
					{...hoverProps}
					className={styles.accordionItemButton}
				>
					<span className={styles.accordionItemTitle}>
						{props.item.props.title}
					</span>
					<span>{isOpen ? openedIcon : closedIcon}</span>
				</button>
			</FocusRing>
			<div {...regionProps} className={styles.accordionItemContent}>
				{props.item.props.children}
			</div>
		</div>
	);
}
