import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import styles from "./TheaterActivityTitle.module.scss";
import {useCsContext} from "../../state/CsInstance";

import ActivityDropDown from "../DropDownMenu/ActivityDropDown";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

import {faCube} from "@fortawesome/sharp-regular-svg-icons/faCube";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setCodeMirrorEditorValue} from "../CodeEditor/CodeMirrorEditor";

// This interface allows us to pass variables and callback function into a component
type Props = {};

/**
 * The title of the current activity.  Normally sits above the theater.
 */
const TheaterActivityTitle: React.FC<Props> = () => {
	const csInstance = useCsContext();
	const {theaterStore, codeStore, featureFlagStore} = csInstance;

	const switchProjRef = useRef<string>();

	async function moveToProject() {
		theaterStore.isRunning = false;
		if (switchProjRef.current) {
			codeStore.switchProject(switchProjRef.current);
			switchProjRef.current = "";
		} else {
			codeStore.nextProject();
		}
		// Update the editor with the focused file belonging to this project
		const fileData = codeStore.visibleFileData[codeStore.focusedFile];
		if (fileData) {
			setCodeMirrorEditorValue(fileData?.value);
		}
	}

	const standardTitleContent = (
		<div className={styles.classTitle}>{codeStore.project}</div>
	);

	// Available for testing purposes under a feature flag
	const projectSwitchingTitleContent = (
		<>
			<button
				className={styles.classTitleButton}
				onClick={() => moveToProject()}
			>
				<FontAwesomeIcon className={styles.classTitleIcon} icon={faCube} />
			</button>
			<div className={styles.classTitle}>
				<ActivityDropDown
					onClick={(key: string) => {
						switchProjRef.current = key;
						moveToProject();
					}}
				/>
			</div>
		</>
	);

	const titleContent = featureFlagStore.showActivityDropdown
		? projectSwitchingTitleContent
		: standardTitleContent;

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<h1 className={styles.classTitleHeader}>{titleContent}</h1>
		</ErrorBoundary>
	);
};

export default observer(TheaterActivityTitle);
