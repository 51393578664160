import React, {useEffect, useRef} from "react";
import {useCsContext} from "../../state/CsInstance";
import {ConsoleTabMode} from "../../state/theaterStore";
import {observer} from "mobx-react-lite";

import {faCaretUp} from "@fortawesome/sharp-solid-svg-icons/faCaretUp";
import {faCaretDown} from "@fortawesome/sharp-solid-svg-icons/faCaretDown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./LogConsole.module.scss";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import ConsoleToast from "./ConsoleToast";
import {getHeightStyle} from "../Ide/Ide";

type Props = {
	percentageHeight: number; // in percentage of PanelGroup height
	toggleConsole: (opts?: {show: boolean}) => void;
};

const LogConsole: React.FC<Props> = (props) => {
	const {compileStore, theaterStore, testsStore} = useCsContext();
	const {percentageHeight, toggleConsole} = props;

	// Calculate actual height of the console panel, accounting for resize bar
	const heightStyle = getHeightStyle(percentageHeight);

	const showConsole = theaterStore.showConsole;
	const consoleMode = theaterStore.consoleTabMode;

	const consoleMessage = compileStore.error || theaterStore.consoleLog;
	const testsMessage = theaterStore.testsOutput;
	const isRed =
		consoleMode === ConsoleTabMode.Console && compileStore.error !== "";
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const [showToast, setShowToast] = React.useState(false);
	const testsSuccessMessage = "You passed all the test cases. Hurray!";
	const numTestsPassed = testsStore.testResults?.summary.totalPassed || 0;
	const numTestsTotal = testsStore.testResults?.summary.totalTests || 0;
	const testsErrorMessage = `You passed ${numTestsPassed} out of ${numTestsTotal} test cases. Check the console for details.`;

	const testsSucceeded = numTestsPassed === numTestsTotal;

	useEffect(() => {
		if (testsStore.testResults) {
			setShowToast(true);
		} else {
			setShowToast(false);
		}
	}, [testsStore.testResults]);

	// On every re-render, determine where the console should be scrolled to
	// If in the Tests tab, scroll to the top. Otherwise, scroll to the bottom
	if (textAreaRef.current) {
		if (consoleMode === ConsoleTabMode.Tests) {
			textAreaRef.current.scrollTop = 0;
		} else {
			textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
		}
	}

	const ConsoleTabModeButton = (mode: ConsoleTabMode, text: string) => {
		return (
			<button
				className={[
					styles.consoleModeButton,
					consoleMode === mode ? styles.selectedMode : "",
				].join(" ")}
				tabIndex={consoleMode === mode ? -1 : undefined}
				onClick={() => {
					// Pop up the console only if selecting a new mode
					if (consoleMode === mode) return;
					theaterStore.consoleTabMode = mode;
					theaterStore.showConsole = true;
				}}
			>
				{text}
			</button>
		);
	};

	const ConsoleHeader = (
		<h2 className={styles.consoleLogBanner}>
			<div className={styles.modeButtonsContainer}>
				{ConsoleTabModeButton(ConsoleTabMode.Console, "Console")}
				{testsStore.hasTests &&
					ConsoleTabModeButton(ConsoleTabMode.Tests, "Tests")}
			</div>
			<div className={styles.buttonContainer}>
				<button
					className={styles.consoleLogButton}
					onClick={() => {
						theaterStore.showConsole = !theaterStore.showConsole;
						toggleConsole();
					}}
				>
					<FontAwesomeIcon icon={showConsole ? faCaretDown : faCaretUp} />
				</button>
			</div>
		</h2>
	);

	const OpenConsoleContent = (
		<>
			<textarea
				className={[
					styles.consoleLogOutput,
					showConsole ? styles.consoleLogOutputExpanded : "",
				].join(" ")}
				id="review-text"
				readOnly={true}
				placeholder="Results of your code will appear here."
				ref={textAreaRef}
				value={
					consoleMode === ConsoleTabMode.Console ? consoleMessage : testsMessage
				}
				style={{
					color: isRed ? "maroon" : "black",
					fontSize: theaterStore.editorFontSize + "px",
				}}
			/>
		</>
	);

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<div
				className={[
					styles.consoleLogContainer,
					showConsole ? styles.consoleLogContainerVisible : "",
					showConsole ? styles.consoleLogContainerExpanded : "",
				].join(" ")}
				style={{height: heightStyle}}
			>
				{testsStore.hasTests && showToast && (
					<ConsoleToast
						message={testsSucceeded ? testsSuccessMessage : testsErrorMessage}
						type={testsSucceeded ? "success" : "error"}
						onClickDismiss={() => setShowToast(false)}
					/>
				)}
				{ConsoleHeader}
				{showConsole && OpenConsoleContent}
			</div>
		</ErrorBoundary>
	);
};

export default observer(LogConsole);
