import {makeAutoObservable} from "mobx";

const FEATURE_FLAG_KEYS = [
	"showExtraButtons",
	"showActivityDropdown",
	"useTests",
	"showHiddenFiles",
	"useMonaco",
	"useReadOnlyRanges",
	"hideCompileButton",
	"showDiagnostics",
	"loadPrevProject",
] as const;

/**
 * Options to force feature flags to be enabled or disabled.
 */
export type FeatureFlagStoreOpts = {
	[key in (typeof FEATURE_FLAG_KEYS)[number]]?: boolean;
};

/**
 * This store is used to manage feature flags, toggles to control selectively
 * turning on features that are either experimental or only relevant for certain
 * consumers.
 *
 * Feature flags should:
 *   - not be mutable at runtime (except in dev mode maybe).
 *   - default to false.
 */
export class FeatureFlagStore {
	private _showExtraButtons = false;
	private _showActivityDropdown = false;
	private _useTests = false; // Not used, kept for backward compat with Torchboard
	private _showHiddenFiles = false; // allow curriculum to edit while viewing hidden files
	private _useMonaco = true;
	private _useReadOnlyRanges = false; // turn off snippets unless we want them
	private _hideCompileButton = false;
	private _showDiagnostics = false;
	private _loadPrevProject = false;

	constructor(opts?: FeatureFlagStoreOpts) {
		const query = new URLSearchParams(window.location.search);
		for (const key of FEATURE_FLAG_KEYS) {
			if (opts?.hasOwnProperty(key)) {
				this[`_${key}`] = !!opts[key];
			} else {
				// Feature flags may be set by query params if not already set via opts
				if (query.has(key)) {
					this[`_${key}`] = query.get(key) === "true";
				}
			}
		}

		makeAutoObservable(this);
	}

	// autogen getters for MobX actions
	// feature flags are NOT meant to be mutable at runtime, so no setters

	get showExtraButtons() {
		return this._showExtraButtons;
	}

	get showActivityDropdown() {
		return this._showActivityDropdown;
	}

	get useTests() {
		return this._useTests;
	}

	get showHiddenFiles() {
		return this._showHiddenFiles;
	}

	get useMonaco() {
		return this._useMonaco;
	}

	get useReadOnlyRanges() {
		return this._useReadOnlyRanges;
	}

	get hideCompileButton() {
		return this._hideCompileButton;
	}

	get showDiagnostics() {
		return this._showDiagnostics;
	}

	get loadPrevProject() {
		return this._loadPrevProject;
	}
}

export default FeatureFlagStore;
