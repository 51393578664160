import React, {PropsWithChildren} from "react";
import styles from "./WithIcon.module.css";

/**
 * Resize and align the first child to appear as an icon. Add space
 * between the icon and other children with respect to writing
 * direction.
 */
export function WithIcon({children}: PropsWithChildren) {
	return <span className={styles.withIcon}>{children}</span>;
}
