import React from "react";
import {Button} from "../Button/Button";
import {AriaNumberFieldProps, useLocale, useNumberField} from "react-aria";
import {NumberFieldStateOptions, useNumberFieldState} from "react-stately";
import styles from "./NumberField.module.css";
import {Stack} from "../../layout/Stack/Stack";
import {CSSWithVariables} from "../../types";
import clsx from "clsx";
import {StepButton} from "../../internal/NumberField/StepButton";
import {useMode} from "../../internal/Mode/useMode";

export interface INumberField
	extends Omit<NumberFieldStateOptions, "locale">,
		AriaNumberFieldProps {
	readonly locale?: NumberFieldStateOptions["locale"];
	readonly label: React.ReactNode;
	readonly showIncrementDecrement?: boolean;
	readonly maxInlineSize?: `${string}ch`;
	readonly inline?: boolean;
}

/**
 * Accept and format numeric user input with optional increment and decrement buttons.
 *
 * @param props.label - Assistive label for the NumberField
 * @param props.showIncrementDecrement - Show the increment and decrement buttons. Default is true
 * @param props.maxInlineSize - The maximum inline size for the input. Default is 20 characters
 * @returns
 */
export function NumberField({
	showIncrementDecrement = true,
	maxInlineSize,
	inline,
	...props
}: INumberField) {
	const {locale} = useLocale();
	const state = useNumberFieldState({...props, locale});
	const inputRef = React.useRef(null);
	const {
		labelProps,
		groupProps,
		inputProps,
		incrementButtonProps,
		decrementButtonProps,
	} = useNumberField(props, state, inputRef);

	const {style} = useMode("numberField");
	const mergedStyle = {
		"--numberField-max-inline-size": maxInlineSize,
		...style,
	} as CSSWithVariables;

	return (
		<div style={{flexGrow: inline ? 0 : undefined}}>
			<Stack gap={inline ? 0 : "space-2"}>
				<label {...labelProps}>{props.label}</label>
				<div
					{...groupProps}
					className={clsx(styles.numberField, {
						[styles.numberFieldDisabled]: props.isDisabled,
					})}
					style={mergedStyle}
				>
					{showIncrementDecrement && (
						<StepButton
							{...decrementButtonProps}
							className={styles.numberFieldDecrement}
						>
							−
						</StepButton>
					)}
					<input
						className={clsx(styles.numberFieldInput, {
							[styles.hideIncrementDecrement]: !showIncrementDecrement,
						})}
						{...inputProps}
						ref={inputRef}
					/>
					{showIncrementDecrement && (
						<StepButton
							{...incrementButtonProps}
							className={styles.numberFieldIncrement}
						>
							+
						</StepButton>
					)}
				</div>
			</Stack>
		</div>
	);
}
