import React, {useRef} from "react";
import type {PropsWithChildren} from "react";
import {
	useSwitch,
	useFocusRing,
	VisuallyHidden,
	AriaSwitchProps,
} from "react-aria";
import {useToggleState} from "react-stately";
import styles from "./Switch.module.css";
import {useMode} from "../../internal/Mode/useMode";

export interface ISwitch extends PropsWithChildren, AriaSwitchProps {}

/**
 * Accept on and off input.
 *
 * @param props.children
 * @returns
 */
export function Switch({children, ...props}: ISwitch) {
	const ref = useRef(null);

	const state = useToggleState(props);
	const {inputProps} = useSwitch(props, state, ref);
	const {isFocusVisible, focusProps} = useFocusRing();
	const {style} = useMode("switch");

	return (
		<label
			style={{
				opacity: props.isDisabled ? 0.4 : 1,
				...style,
			}}
			className={styles.label}
		>
			<VisuallyHidden>
				<input {...inputProps} {...focusProps} ref={ref} />
			</VisuallyHidden>
			<svg width={40} height={24} aria-hidden="true" className={styles.switch}>
				<rect
					x={4}
					y={4}
					width={32}
					height={16}
					rx={8}
					fill={
						state.isSelected ? "var(--switch-on-color)" : "var(--switch-color)"
					}
				/>
				<circle cx={state.isSelected ? 28 : 12} cy={12} r={5} fill="white" />
				{isFocusVisible && (
					<rect
						x={1}
						y={1}
						width={38}
						height={22}
						rx={11}
						fill="none"
						stroke="var(--switch-focus-outlineColor)"
						strokeWidth={2}
					/>
				)}
			</svg>
			{children}
		</label>
	);
}
