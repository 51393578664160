// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CsController-module__csControllerWrapper--Ep9sm {
  display: flex;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/CsController/CsController.module.css"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,MAAA;EACA,OAAA;AACD","sourcesContent":[".csControllerWrapper {\n\tdisplay: flex;\n\twidth: 100vw;\n\tmax-width: 100vw;\n\theight: 100vh;\n\tposition: absolute;\n\toverflow: hidden;\n\ttop: 0;\n\tleft: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"csControllerWrapper": `CsController-module__csControllerWrapper--Ep9sm`
};
export default ___CSS_LOADER_EXPORT___;
