import React from "react";
import {observer} from "mobx-react-lite";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {File} from "../../shared/Types";
import styles from "./Editor.module.scss";

type Props = {
	file: File;
};

const MarkdownViewer: React.FC<Props> = (props: Props) => {
	return (
		<div className={styles.markdownWrapper}>
			<Markdown rehypePlugins={[rehypeRaw] as any}>{props.file.value}</Markdown>
		</div>
	);
};

export default observer(MarkdownViewer);
