import React from "react";
import {observer} from "mobx-react-lite";

import styles from "./CompileErrorCurtain.module.scss";

const CompileErrorCurtain: React.FC<{}> = () => {
	return (
		<div className={styles.curtain}>
			<div className={styles.errorBox}>
				<div className={styles.errorTitle}>Compile Error</div>
				<div className={styles.errorText}>
					There was an error compiling your code. Please check your code and try
					again.
				</div>
			</div>
		</div>
	);
};

export default observer(CompileErrorCurtain);
