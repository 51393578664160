import React, {
	CSSProperties,
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from "react";
import {Overlay, useModalOverlay} from "react-aria";
import {OverlayTriggerState} from "react-stately";
import styles from "./Modal.module.css";
import {ThemeContext} from "../../theme/ThemeContext";
import {flattenObjectToCSSVariables} from "../flattenObjectToCSS";
import {merge} from "lodash";
import {CSSWithVariables} from "../../types";

export interface IModal extends PropsWithChildren {
	readonly state: OverlayTriggerState;
	readonly isDismissable?: boolean;
	readonly inlineSize?: CSSProperties["inlineSize"];
}
/**
 * Open a focus-trapping and optionally dismissable Modal with a semi-transparent underlay.
 *
 * @param isDismissable Whether to close the modal when the user clicks outside or presses Escape on the keyboard. Default is `false`
 */
export function Modal({
	state,
	children,
	isDismissable = false,
	inlineSize,
}: IModal) {
	const ref = useRef(null);
	const {modalProps, underlayProps} = useModalOverlay(
		{
			isDismissable,
			// react-aria separates click dismissal and keyboard dismissal. `isDismissable` controls both for a simpler interface.
			isKeyboardDismissDisabled: !isDismissable,
		},
		state,
		ref
	);
	const {palette} = useContext(ThemeContext);
	const style = useMemo(
		() =>
			merge({}, flattenObjectToCSSVariables(palette, {}, "--theme"), {
				"--modal-prop-inlineSize": inlineSize,
			}),
		[]
	) as CSSWithVariables;

	const underlayRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		// Patch for this known react-spectrum issue:
		// https://github.com/adobe/react-spectrum/issues/1513
		if (underlayRef.current) {
			underlayRef.current.addEventListener("touchstart", (e: TouchEvent) => {
				/**
				 * This workaround is described here:
				 * https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1641830053
				*/
				if (
					(e.currentTarget as HTMLElement)?.getAttribute('type') !== 'submit' &&
					typeof (e.currentTarget as HTMLElement)?.getAttribute('href') !== 'string'
				  ) {
					e.preventDefault();
				  }
			}, {passive: false});
		}
	}, []);

	return (
		<Overlay>
			<div ref={underlayRef} className={styles.underlay} style={style} {...underlayProps}>
				<div {...modalProps} ref={ref} className={styles.modal}>
					{children}
				</div>
			</div>
		</Overlay>
	);
}
