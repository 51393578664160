import React, {
	useEffect,
	useState,
	MouseEvent,
	useRef,
	useCallback,
} from "react";
import {observer} from "mobx-react-lite";
import styles from "./Theater.module.scss";
import {
	PIXI_RENDER_WIDTH,
	PIXI_RENDER_HEIGHT,
} from "../../state/userInputStore";
import {TheaterSize} from "../../state/theaterStore";
import {useCsContext} from "../../state/CsInstance";

import UseModal from "../UseModal";
import Sidebar from "../Sidebar/Sidebar";
import ModalUpload from "../Modals/ModalUpload";
import TheaterControls from "../TheaterControls/TheaterControls";
import CoordinateDisplay from "../CoordinateDisplay/CoordinateDisplay";
import PixiApp from "../PixiCanvas/PixiApp";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";

import CompileErrorCurtain from "../CompileErrorCurtain/CompileErrorCurtain";
import FileTree from "../FileTree/FileTree";
import TheaterActivityTitle from "./TheaterActivityTitle";

const STAGE_CLASS = "csStage";

// This interface allows us to pass variables and callback function into a component
type Props = {};

const Theater: React.FC<Props> = () => {
	const csInstance = useCsContext();
	const {
		compileStore,
		theaterStore,
		userInputStore,
		getOrCreateMessenger,
		uiStore,
	} = csInstance;

	const {isOpen: isOpenUpload, toggle: toggleUpload} = UseModal();

	// @ts-ignore no-unused-vars
	const [spriteManifest, setSpriteManifest] = useState("");

	const containerDivRef = useRef<HTMLDivElement | null>(null);

	const switchProjRef = useRef<string>();
	const [, rerender] = useState(0);

	const displayLoading =
		compileStore.isCompiling || theaterStore.messengerLoading;

	// #######################
	// ### useEffect Hooks ###
	// #######################

	useEffect(() => {
		if (theaterStore.justStartedRunning) {
			const element = document.querySelector(
				`.${STAGE_CLASS}`
			) as HTMLCanvasElement;
			element.setAttribute("tabindex", "0"); // needs to be in here, for some reason
			element.focus();
		}
	}, [theaterStore.justStartedRunning]);

	// Helpers for window visibility change
	const [wasPreviouslyRunning, setWasPreviouslyRunning] = useState(false);
	const visibleListener = useCallback(() => {
		if (!(document.visibilityState === "visible")) {
			// just became non-visible
			if (theaterStore.isRunning) {
				setWasPreviouslyRunning(true);
			}
			theaterStore.isRunning = false;
		} else {
			// just became visible
			if (wasPreviouslyRunning) {
				theaterStore.isRunning = true;
				setWasPreviouslyRunning(false);
			}
		}
	}, [theaterStore, wasPreviouslyRunning]);

	useEffect(() => {
		window.addEventListener("resize", rerenderListener);
		window.addEventListener("visibilitychange", visibleListener);

		userInputStore.addKeyboardListeners();

		return () => {
			userInputStore.removeKeyboardListeners();
			window.removeEventListener("resize", rerenderListener);
			window.removeEventListener("visibilitychange", visibleListener);
		};
	}, [visibleListener, getOrCreateMessenger, userInputStore]);

	// Force pixi to rerender when the theater size changes
	useEffect(rerenderListener, [theaterStore.theaterSize]);

	// #############################
	// ### Callbacks and Helpers ###
	// #############################

	function rerenderListener() {
		rerender((prev) => prev + 1);
	}

	function readFileContent(file: File) {
		const reader = new FileReader();
		return new Promise<string>((resolve, reject) => {
			reader.onload = (event) => {
				const content = event.target?.result;
				if (content && typeof content === "string") {
					resolve(content);
				} else {
					reject("File content is not a string");
				}
			};
			reader.onerror = (error) => reject(error);
			reader.readAsText(file);
		});
	}

	// Functions for the children Components to use to interact with this one
	function onMouseMovePixiCanvas(event: MouseEvent<HTMLDivElement>) {
		const target = event.target as HTMLCanvasElement;
		const {
			x: rectX,
			y: rectY,
			width: rectWidth,
			height: rectHeight,
		} = target.getBoundingClientRect();
		const {clientX: mouseX, clientY: mouseY} = event;

		const xPercent = (mouseX - rectX) / rectWidth;
		const yPercent = 1 - (mouseY - rectY) / rectHeight; // flip y-axis

		userInputStore.mouseX = (xPercent - 0.5) * PIXI_RENDER_WIDTH;
		userInputStore.mouseY = (yPercent - 0.5) * PIXI_RENDER_HEIGHT;
		userInputStore.mouseInBounds = true;
	}

	function onMouseOutPixiCanvas() {
		userInputStore.mouseInBounds = false;
	}

	function onPointerDownPixiCanvas() {
		userInputStore.isMouseDown = true;
		if (!theaterStore.isRunning) {
			getOrCreateMessenger().getMethodsJSON(
				userInputStore.mouseX,
				userInputStore.mouseY
			);
		}
	}

	function onPointerUpPixiCanvas() {
		userInputStore.isMouseDown = false;
	}

	const containerDivWidth = containerDivRef.current?.clientWidth || 0;
	const pixiScale = containerDivWidth / PIXI_RENDER_WIDTH;
	const pixiClientWidth = PIXI_RENDER_WIDTH * pixiScale;
	const pixiClientHeight = PIXI_RENDER_HEIGHT * pixiScale;
	if (userInputStore.pixiScale !== pixiScale) {
		// Update pixiScale after the render to prevent React state update conflicts
		setTimeout(() => {
			userInputStore.pixiScale = pixiScale;
		}, 0);
	}

	const theaterColumnClassnames = [styles.theaterColumnWrapper];
	switch (theaterStore.theaterSize) {
		case TheaterSize.None:
			theaterColumnClassnames.push(styles.theaterColumnSizeNone);
			break;
		case TheaterSize.Small:
			theaterColumnClassnames.push(styles.theaterColumnSizeSmall);
			break;
		case TheaterSize.Medium:
			theaterColumnClassnames.push(styles.theaterColumnSizeMedium);
			break;
		case TheaterSize.Large:
			theaterColumnClassnames.push(styles.theaterColumnSizeLarge);
			break;
		case TheaterSize.Fullscreen:
			theaterColumnClassnames.push(styles.theaterColumnSizeFullscreen);
			break;
	}

	// don't let students interact with the theater as it doesn't reflect code
	const theaterInvalid = compileStore.isCompiling;

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<React.Fragment>
				<ModalUpload
					isOpen={isOpenUpload}
					toggle={toggleUpload}
					title="Upload new code"
					text="Currently only for frontend testing purposes."
					onSuccess={(file?: File) => {
						if (file) {
							readFileContent(file)
								.then((content) => {
									if (content) {
										theaterStore.isRunning = false;
										csInstance.intakeProjectYaml(content);
									}
								})
								.catch((error) => console.log(error));
						}
					}}
					onCancel={() => {
						switchProjRef.current = "";
					}}
				/>
				{uiStore.isSidebarShown && <Sidebar showUploadModal={toggleUpload} />}
				<div
					ref={containerDivRef}
					className={theaterColumnClassnames.join(" ")}
				>
					<TheaterActivityTitle />
					{!pixiClientWidth && (
						<div
							className={styles.pixiCanvasPlaceholder}
							style={{
								aspectRatio: `${PIXI_RENDER_WIDTH}/${PIXI_RENDER_HEIGHT}`,
							}}
						/>
					)}
					<div
						id="canvas"
						onMouseMove={onMouseMovePixiCanvas}
						onMouseOut={onMouseOutPixiCanvas}
						onPointerDown={onPointerDownPixiCanvas}
						onPointerUp={onPointerUpPixiCanvas}
						className={[
							styles.pixiCanvas,
							theaterInvalid ? styles.theaterInvalid : "",
						].join(" ")}
						style={{
							width: pixiClientWidth,
							height: pixiClientHeight,
						}}
					>
						<CoordinateDisplay />
						{containerDivRef.current && (
							<PixiApp width={pixiClientWidth} height={pixiClientHeight} />
						)}
						{displayLoading && (
							<div className={styles.greyOut}>
								<LoadingScreen />
							</div>
						)}
						{compileStore.error && !theaterStore.runtimeErrorOccurred && (
							<CompileErrorCurtain />
						)}
					</div>
					<TheaterControls buttonClass={styles.buttonClass} />
					<FileTree />
				</div>
			</React.Fragment>
		</ErrorBoundary>
	);
};

export default observer(Theater);
