import React, {useState} from "react";
import {useCsContext} from "../../state/CsInstance";
import styles from "./FileTree.module.scss";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import ModalConfirm from "../Modals/ModalConfirm";
import {observer} from "mobx-react-lite";
import FileLeaf from "./FileLeaf";

const FileTree: React.FC = () => {
	const {codeStore} = useCsContext();
	const {visibleFileNames, visibleFileData} = codeStore;
	const [modalOpen, setModalOpen] = useState(false);
	const [resetFile, setResetFile] = useState("");
	const resetTitle = "Are you sure you want to reset this file?";
	const deleteTitle = "Are you sure you want to delete this file?";
	const resetText = "This will undo all changes you made to this file.";
	const deleteText =
		"Because this file does not exist in the original set of files, it will be deleted from the project.";
	const isOriginal = visibleFileData?.[resetFile]?.isOriginal;

	const onClickReset = (fileName: string) => {
		codeStore.resetFile(fileName);
	};

	// Sort files by superclass
	const superclassDictionary: {[superclass: string]: string[]} = {};
	visibleFileNames.forEach((filename) => {
		const data = visibleFileData[filename];
		if (!data) {
			return <></>; // Shouldn't happen, but just in case
		}
		// Check file data for "extends" keyword to determine superclass
		const superclassMatch = data.value.match(/extends (\w+)/);

		// Map the file to the correct superclass. If superclass is not found, default to Utilities.
		const superclass =
			superclassMatch &&
			(visibleFileNames.includes(superclassMatch[1] + ".java") || // Check if superclass file exists
				superclassMatch[1] === "Actor" || // Check if superclass is Actor (file doesn't appear in fileNames)
				superclassMatch[1] === "Stage") // Check if superclass is Stage
				? (superclassMatch[1] as string)
				: "Utilities";

		if (!superclassDictionary[superclass]) {
			superclassDictionary[superclass] = [];
		}

		superclassDictionary[superclass]?.push(filename);
		superclassDictionary[superclass]?.sort();
	});

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<ModalConfirm
				isOpen={modalOpen}
				title={isOriginal ? resetTitle : deleteTitle}
				text={isOriginal ? resetText : deleteText}
				toggle={() => setModalOpen(!modalOpen)}
				onSuccess={() => onClickReset(resetFile)}
			/>
			<div className={styles.fileTreeContainer}>
				<div className={styles.fileHeaderContainer}>
					<div className={styles.fileHeader}>Files</div>
				</div>
				<FileLeaf
					superclassDictionary={superclassDictionary}
					nodeName="Stage"
					setModalOpen={setModalOpen}
					setResetFile={setResetFile}
				/>
				<FileLeaf
					superclassDictionary={superclassDictionary}
					nodeName="Actor"
					setModalOpen={setModalOpen}
					setResetFile={setResetFile}
				/>
				<FileLeaf
					superclassDictionary={superclassDictionary}
					nodeName="Utilities"
					setModalOpen={setModalOpen}
					setResetFile={setResetFile}
				/>
			</div>
		</ErrorBoundary>
	);
};

export default observer(FileTree);
