import {makeAutoObservable} from "mobx";
import {ClassManifest} from "../@types/Types";

export enum TheaterSize {
	None = "None", // not yet implemented
	Small = "Small", // not yet implemented
	Medium = "Medium", // default
	Large = "Large",
	Fullscreen = "Fullscreen", // not yet implemented
}
export const DEFAULT_EDITOR_FONT_SIZE = 14;
const MAX_LOG_LINES = 1024;

export enum ConsoleTabMode {
	Console = "Console",
	Tests = "Tests",
}

/**
 * MobX store for global Theater state.
 */
class TheaterStore {
	private _theaterSize: TheaterSize = TheaterSize.Medium;
	private _editorFontSize = DEFAULT_EDITOR_FONT_SIZE;

	private _messengerLoading = true;
	private _isRunning = false;
	private _runtimeErrorOccurred = false;
	private _studentInputRequested = false;

	private _showManifest = false;
	private _methodManifest: ClassManifest = {};
	private _invokeResult: any = "";

	// Whether the code has just started running.  Used for focusing.
	private _justStartedRunning = false;

	// The contents of the log should be interpreted as _consoleLog[_logStart...] wrapping around
	private _consoleLog: string[] = [];
	private _logStart = 0;
	private _testsOutput: string[] = [];
	private _testsStart = 0;

	private _showConsole = false;
	private _consoleTabMode: ConsoleTabMode = ConsoleTabMode.Console;

	constructor() {
		this.clearLog();
		makeAutoObservable(this);
	}

	// methods
	expandTheater() {
		switch (this._theaterSize) {
			case TheaterSize.Small:
				this._theaterSize = TheaterSize.Medium;
				break;
			case TheaterSize.Medium:
				this._theaterSize = TheaterSize.Large;
				break;
			case TheaterSize.Large:
				this._theaterSize = TheaterSize.Fullscreen;
				break;
		}
	}

	collapseTheater() {
		switch (this._theaterSize) {
			case TheaterSize.Fullscreen:
				this._theaterSize = TheaterSize.Large;
				break;
			case TheaterSize.Large:
				this._theaterSize = TheaterSize.Medium;
				break;
			case TheaterSize.Medium:
				this._theaterSize = TheaterSize.Small;
				break;
		}
	}

	requestStudentInput() {
		this.studentInputRequested = true;
		this.isRunning = false;
	}

	resetTheaterState() {
		this.isRunning = false;
		this.runtimeErrorOccurred = false;
		this.studentInputRequested = false;
	}

	clearLog(mode: ConsoleTabMode = ConsoleTabMode.Console) {
		if (mode === ConsoleTabMode.Console) {
			this._logStart = 0;
			this._consoleLog = [];
		} else {
			this._testsStart = 0;
			this._testsOutput = [];
		}
		this._consoleTabMode = ConsoleTabMode.Console;
		for (let i = 0; i < MAX_LOG_LINES; ++i) {
			(mode === ConsoleTabMode.Console
				? this._consoleLog
				: this._testsOutput
			).push("");
		}
	}

	get consoleLog() {
		return this.getLogAsString(ConsoleTabMode.Console);
	}
	get testsOutput() {
		return this.getLogAsString(ConsoleTabMode.Tests);
	}

	private getLogAsString(mode: ConsoleTabMode) {
		// Join all of the entries in order
		let result = "";
		const initialI =
			mode === ConsoleTabMode.Console ? this._logStart : this._testsStart;
		const log =
			mode === ConsoleTabMode.Console ? this._consoleLog : this._testsOutput;

		// Concatenate all the way around
		let i = initialI;
		do {
			result += log[i];
			i = (i + 1) % MAX_LOG_LINES;
		} while (i !== initialI);
		return result;
	}

	addLine(line: string, mode: ConsoleTabMode = ConsoleTabMode.Console) {
		const currentStart =
			mode === ConsoleTabMode.Console ? this._logStart : this._testsStart;

		if (mode === ConsoleTabMode.Console) {
			this._consoleLog[currentStart] = line;
			this._logStart = (currentStart + 1) % MAX_LOG_LINES;
		} else {
			this._testsOutput[currentStart] = line;
			this._testsStart = (currentStart + 1) % MAX_LOG_LINES;
		}
		this._showConsole = true;
	}

	get isRunning() {
		return this._isRunning;
	}
	set isRunning(value) {
		this._isRunning = value;
		if (!value) {
			this.justStartedRunning = false;
		}
	}

	// autogen getters and setters for MobX actions

	get justStartedRunning() {
		return this._justStartedRunning;
	}
	set justStartedRunning(value) {
		this._justStartedRunning = value;
	}

	get theaterSize() {
		return this._theaterSize;
	}
	set theaterSize(value) {
		this._theaterSize = value;
	}

	get editorFontSize() {
		return this._editorFontSize;
	}
	set editorFontSize(value) {
		this._editorFontSize = value;
	}

	get messengerLoading() {
		return this._messengerLoading;
	}
	set messengerLoading(value) {
		this._messengerLoading = value;
	}

	get runtimeErrorOccurred() {
		return this._runtimeErrorOccurred;
	}
	set runtimeErrorOccurred(value) {
		this._runtimeErrorOccurred = value;
	}

	get studentInputRequested() {
		return this._studentInputRequested;
	}
	set studentInputRequested(value) {
		this._studentInputRequested = value;
	}

	get showConsole() {
		return this._showConsole;
	}
	set showConsole(value) {
		this._showConsole = value;
	}

	get showManifest() {
		return this._showManifest;
	}
	set showManifest(value) {
		this._showManifest = value;
	}

	toggleShowManifest() {
		this._showManifest = !this._showManifest;
	}

	get methodManifest() {
		return this._methodManifest;
	}
	set methodManifest(value) {
		this._methodManifest = value;
	}

	get invokeResult() {
		return this._invokeResult;
	}
	set invokeResult(value) {
		this._invokeResult = value;
	}

	get consoleTabMode() {
		return this._consoleTabMode;
	}

	set consoleTabMode(value) {
		this._consoleTabMode = value;
	}
}

export default TheaterStore;
