import React, {PropsWithChildren, useMemo} from "react";
import {InternalMode} from "../internal/Mode/InternalMode";
import {ComponentMode, ComponentTheme, Palette} from "../types";
import {flattenObjectToCSSVariables} from "../internal/flattenObjectToCSS";
import {ThemeContext} from "./ThemeContext";

export function useTheme<T extends string[], P extends Palette>(
	theme: ComponentTheme<T, P>,
	isDebug?: true
) {
	const paletteStyle = useMemo(
		() => flattenObjectToCSSVariables(theme.palette, {}, "--theme"),
		[theme.palette]
	);

	const ThemeDefaults: React.FC<PropsWithChildren> = ({
		children,
	}: PropsWithChildren) => (
		<ThemeContext.Provider value={{...theme, isDebug: isDebug ?? false}}>
			<div data-theme-palette style={paletteStyle}>
				<InternalMode
					debug={isDebug ? `${theme.name}-defaults` : undefined}
					mode={theme.defaults}
				>
					{children}
				</InternalMode>
			</div>
		</ThemeContext.Provider>
	);

	const memoizedThemeDefaults = useMemo(() => ThemeDefaults, []);

	const Mode: ITypedModeFC<T[number]> = ({children, ...otherProps}) => {
		const [selectedMode, selectedModeName]: [ComponentMode<P>, string] =
			useMemo(() => {
				if (theme.modes) {
					const modeNames = Object.keys(theme.modes ?? {});

					for (const propName of Object.keys(otherProps)) {
						if (modeNames.includes(propName)) {
							return [
								theme.modes[propName as T[number]],
								`${theme.name}-${propName}`,
							];
						}
					}
				}

				return [theme.defaults, `${theme.name}-defaults`];
			}, [otherProps]);

		return (
			<InternalMode
				debug={isDebug ? selectedModeName : undefined}
				mode={selectedMode}
			>
				{children}
			</InternalMode>
		);
	};

	const memoizedMode = useMemo(() => Mode, []);

	return {
		ThemeDefaults: memoizedThemeDefaults,
		Mode: memoizedMode,
		paletteStyle,
	};
}

type ITypedModeFC<T extends string> = React.FC<
	PropsWithChildren & {
		[K in T]?: true;
	}
>;
