import React, {useCallback, useEffect} from "react";
import {useCsContext} from "../../state/CsInstance";
import {observer} from "mobx-react-lite";
import CompileButton from "../CompileButton/CompileButton";
// Frontend
import styles from "./Editor.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/sharp-solid-svg-icons/faXmark";
import {faEdit} from "@fortawesome/sharp-regular-svg-icons/faEdit";
import {faPlus} from "@fortawesome/sharp-regular-svg-icons/faPlus";
import {faEllipsis} from "@fortawesome/sharp-regular-svg-icons/faEllipsis";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
// Stores
import {DEFAULT_EDITOR_FONT_SIZE} from "../../state/theaterStore";
// Monaco
import Editor from "@monaco-editor/react";
import {focusFile} from "../FileTree/File";
import CodeEditorMenu from "../CodeEditorMenu/CodeEditorMenu";
import UseModal from "../UseModal";
import AddActorModal from "../Modals/AddActorModal";

type TabProps = {
	key: string;
};

const NEW_FILE_BOILERPLATE = `package StudentCode;
import AopsTheater.*;
public class $classname extends Actor {
    public $classname() {
    
    }
    public void step() {
    
    }
}`;

const EditorWrapper: React.FC = () => {
	const {codeStore, theaterStore, compileStore, featureFlagStore} =
		useCsContext();
	const [codeEditorMenuOpen, setCodeEditorMenuOpen] = React.useState(false);
	const {isOpen: isAddActorOpen, toggle: addActorToggle} = UseModal();

	const addFileOpen = useCallback(
		(fileName: string) => {
			if (!codeStore.filesOpen.includes(fileName)) {
				codeStore.filesOpen = codeStore.filesOpen.concat(fileName);
			}
		},
		[codeStore]
	);

	useEffect(() => {
		if (
			codeStore.focusedFile &&
			!codeStore.filesOpen.includes(codeStore.focusedFile)
		) {
			// We must have just opened this file, so add it to the file list
			addFileOpen(codeStore.focusedFile);

			// TODO scroll to focused file if necessary
		}
	}, [codeStore.focusedFile, addFileOpen, codeStore]);

	let currFile = codeStore.visibleFileData[codeStore.focusedFile];

	// If no files are open in a tab
	if (!currFile) {
		currFile = {name: "", value: "\nPlease open a file to start editing\n"};
	}

	/**
	 * Renders a tab for a file.
	 * @param tabProps
	 * @returns
	 */
	const renderTab = (tabProps: TabProps) => {
		const currentFileInfo = codeStore.visibleFileData[tabProps.key];
		if (!currentFileInfo) {
			return null;
		}

		return (
			<ErrorBoundary key={tabProps.key} FallbackComponent={ErrorComponent}>
				<span
					key={tabProps.key}
					className={[
						styles.tabButtonWrapper,
						codeStore.focusedFile === currentFileInfo.name
							? styles.tabButtonWrapperFocused
							: "",
					].join(" ")}
					onClick={() => {
						focusFile(codeStore, currentFileInfo);
					}}
				>
					<button className={styles.tabButtonContent}>
						<FontAwesomeIcon
							icon={faEdit}
							className={[styles.icon, styles.fileIcon].join(" ")}
						/>
						<div className={styles.tabButtonText}>
							{currentFileInfo.name.split(".")[0]}
						</div>
					</button>
					<button
						className={styles.closeTabButton}
						onClick={(e) => {
							e.stopPropagation();
							removeFileOpen(currentFileInfo.name);
						}}
					>
						<FontAwesomeIcon icon={faXmark} className={styles.icon} />
					</button>
				</span>
			</ErrorBoundary>
		);
	};

	/**
	 * Renders the tabs and dividers for the open files
	 * @returns An array of tab elements and dividers
	 */
	const renderTabs = () => {
		const filesOpen = codeStore.filesOpen.filter((f) => !!f);
		let tabs = filesOpen
			.map((filename) => {
				return renderTab({key: filename});
			})
			.filter((tab) => !!tab);

		// Limit the number of tabs to 10
		tabs = tabs.splice(0, 10);

		tabs.push(
			<span
				key={"newFile"}
				className={[styles.tabButtonWrapper, styles.newFileTab].join(" ")}
				onClick={() => {
					addActorToggle();
				}}
			>
				<button
					className={[styles.tabButtonContent, styles.newFileTab].join(" ")}
				>
					<FontAwesomeIcon icon={faPlus} className={styles.icon} />
				</button>
			</span>
		);

		const focusedIndex = filesOpen.findIndex(
			(f) => f === codeStore.focusedFile
		);

		const tabsAndDividers = tabs.map((tab, i) => {
			// add dividers after every element, except for the element before the focused element and the
			// focused element
			if (
				i === focusedIndex ||
				i === focusedIndex - 1 ||
				i === tabs.length - 1
			) {
				return [tab, <div className={styles.hiddenTabDivider} key={i} />];
			}
			return [tab, <div className={styles.tabDivider} key={i} />];
		});

		return tabsAndDividers.flat();
	};

	function handleEditorChange(value: string | undefined) {
		codeStore.editorChanged = true;
		codeStore.setFileData(codeStore.focusedFile, {
			name: codeStore.focusedFile,
			value: value || "",
			isOriginal: codeStore.fileData[codeStore.focusedFile]?.isOriginal,
		});
		codeStore.saveFileDataLocally();
	}

	async function submitCode() {
		// Save local file list upon submitting code to the server
		codeStore.saveFileDataLocally();
		await codeStore.compileCode();
	}

	function removeFileOpen(fileName: string) {
		const filesOpenCopy = [...codeStore.filesOpen];
		const idx = codeStore.filesOpen.indexOf(fileName);
		filesOpenCopy.splice(idx, 1);
		codeStore.filesOpen = filesOpenCopy;

		// If all tabs are closed, set focused file to empty string
		if (filesOpenCopy.length === 0) {
			codeStore.focusedFile = "";
		} else if (codeStore.focusedFile === fileName) {
			if (idx - 1 < 0) {
				// If the focused file was the first tab, set focused file to the new first tab
				codeStore.focusedFile = filesOpenCopy[0]!;
			} else {
				// Otherwise, set focused file to the tab to the left of the closed tab
				codeStore.focusedFile = filesOpenCopy[idx - 1]!;
			}
		}
	}

	// Handle loading files and saving them locally
	useEffect(() => {
		const knownFiles = Object.keys(codeStore.fileData);
		if (knownFiles.length !== codeStore.fileNames.length) {
			// Update fileData when fileNames change
			// TODO can only handle adding files
			const newFiles = codeStore.fileNames.filter(
				(name) => !knownFiles.includes(name)
			);
			newFiles.forEach((fileName) => {
				codeStore.fileData = {
					...codeStore.fileData,
					[fileName]: {
						name: fileName,
						value: createNewFileContents(fileName.replace(".java", "")),
					},
				};
				addFileOpen(fileName);
			});
			codeStore.saveFileDataLocally();
		}
	}, [codeStore, compileStore, addFileOpen]);

	function createNewFileContents(fileName: string) {
		return NEW_FILE_BOILERPLATE.replace(/\$classname/g, fileName);
	}

	// customizable monaco editor options
	const options = {
		fontSize: theaterStore.editorFontSize,
		readOnly: codeStore.filesOpen.length === 0,
		automaticLayout: true, // automatically adjust editor size
		minimap: {enabled: false},
	};

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<AddActorModal isOpen={isAddActorOpen} toggle={addActorToggle} />
			<div className={styles.editorWrapper}>
				<div className={styles.editorHeader}>
					<div className={styles.fileTabContainer}>{renderTabs()}</div>
					<div
						className={styles.showTabsButton}
						onClick={() => {
							setCodeEditorMenuOpen(!codeEditorMenuOpen);
						}}
					>
						<FontAwesomeIcon icon={faEllipsis} className={styles.icon} />
					</div>
					<CodeEditorMenu
						setCodeEditorMenuOpen={setCodeEditorMenuOpen}
						codeEditorMenuOpen={codeEditorMenuOpen}
					/>
				</div>

				<div className={styles.editorToolbar}>
					{featureFlagStore.showExtraButtons && (
						<button
							className={styles.resetButton}
							onClick={codeStore.resetProject}
						>
							Reset
						</button>
					)}
					{!featureFlagStore.hideCompileButton && (
						<CompileButton
							onClick={() => {
								if (compileStore.isCompiling) return;
								theaterStore.isRunning = false;
								submitCode();
								codeStore.editorChanged = false;
							}}
							disabled={!codeStore.editorChanged}
						/>
					)}
				</div>
				<div className={styles.editor}>
					<Editor
						height="100vh"
						width="100%"
						theme="vs-light"
						onMount={(editor, monaco) => {
							// Decrease font size
							editor.addCommand(
								monaco.KeyMod.CtrlCmd |
									monaco.KeyMod.Shift |
									monaco.KeyCode.Minus,
								() => {
									theaterStore.editorFontSize--;
								}
							);
							editor.addCommand(
								monaco.KeyMod.CtrlCmd | monaco.KeyCode.Minus,
								() => {
									theaterStore.editorFontSize--;
								}
							);
							// Increase font size
							editor.addCommand(
								monaco.KeyMod.CtrlCmd |
									monaco.KeyMod.Shift |
									monaco.KeyCode.Equal,
								() => {
									theaterStore.editorFontSize++;
								}
							);
							editor.addCommand(
								monaco.KeyMod.CtrlCmd | monaco.KeyCode.Equal,
								() => {
									theaterStore.editorFontSize++;
								}
							);
							// Reset font size
							editor.addCommand(
								monaco.KeyMod.CtrlCmd | monaco.KeyCode.Digit0,
								() => {
									theaterStore.editorFontSize = DEFAULT_EDITOR_FONT_SIZE;
								}
							);
						}}
						path={currFile?.name}
						options={options}
						language="java"
						value={currFile?.value}
						onChange={handleEditorChange}
					/>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default observer(EditorWrapper);
