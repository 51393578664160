// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal-module__modalOverlay--yzkzO {
  z-index: 3;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Modal-module__modalOverlay--yzkzO.Modal-module__absoluteOverlay--XWbLl {
  position: absolute;
}

.Modal-module__modalBox--qskiB {
  display: block;
  background: white;
  width: 31.25rem;
  min-height: 12.5rem;
  max-height: 80vh;
  padding: 1.5rem;
  border-radius: 0.5rem;
  overflow-y: auto;
}
@media (max-width: 1024px) {
  .Modal-module__modalBox--qskiB {
    width: fit-content;
  }
}

.Modal-module__pinToTop--R39mg {
  border-radius: 0 0 0.5rem 0.5rem;
  position: absolute;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/Modal.module.scss"],"names":[],"mappings":"AAAA;EACC,UAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;AACC;EACC,kBAAA;AACF;;AAGA;EACC,cAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;AAAD;AACC;EATD;IAUE,kBAAA;EAEA;AACF;;AACA;EACC,gCAAA;EACA,kBAAA;EACA,MAAA;AAED","sourcesContent":[".modalOverlay {\n\tz-index: 3;\n\twidth: 100%;\n\theight: 100vh;\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\tbackground: rgba(0, 0, 0, 0.7);\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\t&.absoluteOverlay {\n\t\tposition: absolute;\n\t}\n}\n\n.modalBox {\n\tdisplay: block;\n\tbackground: white;\n\twidth: 31.25rem;\n\tmin-height: 12.5rem;\n\tmax-height: 80vh;\n\tpadding: 1.5rem;\n\tborder-radius: 0.5rem;\n\toverflow-y: auto;\n\t@media (max-width: 1024px) {\n\t\twidth: fit-content;\n\t}\n}\n\n.pinToTop {\n\tborder-radius: 0 0 0.5rem 0.5rem;\n\tposition: absolute;\n\ttop: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `Modal-module__modalOverlay--yzkzO`,
	"absoluteOverlay": `Modal-module__absoluteOverlay--XWbLl`,
	"modalBox": `Modal-module__modalBox--qskiB`,
	"pinToTop": `Modal-module__pinToTop--R39mg`
};
export default ___CSS_LOADER_EXPORT___;
