import {CSSWithVariables} from "../../types";
import React, {PropsWithChildren} from "react";
import styles from "./Cluster.module.css";
import {SpaceTokenName} from "../../types";
import {makeSpaceCSSVariable} from "../../internal/flattenObjectToCSS";

export interface ICluster extends PropsWithChildren {
	readonly gap?: 0 | SpaceTokenName;
	readonly justify?: CSSWithVariables["justifyContent"];
	readonly alignItems?: CSSWithVariables["alignItems"];
}

/** Fit as many children as possible in each row with a custom or default gap.
 * All other children wrap to avoid horizontal overflow.  */
export function Cluster({
	children,
	gap = "space-2",
	justify,
	alignItems,
}: ICluster) {
	return (
		<div
			className={styles.cluster}
			style={
				{
					"--cluster-gap": makeSpaceCSSVariable(gap),
					"--cluster-justify-content": justify,
					"--cluster-align-items": alignItems,
				} as CSSWithVariables
			}
		>
			{children}
		</div>
	);
}
