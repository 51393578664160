import {ComponentTheme, Palette} from "../types";

export const ExamplePalette = {
	color: {
		"primary-100": "rgb(3, 52, 144)",
		"primary-200": "rgb(50, 85, 212)",
		"soft-100": "rgb(238, 242, 255)",
		"soft-200": "rgb(224, 231, 255)",
		"accent-100": "#d4f4ff",
		"accent-200": "dodgerblue",
		foreground: "black",
		background: "white",
		soft: "#efefef",
		shiny: "silver",
		inactive: "grey",
		destroy: "red",
		danger: "orangered",
	},
	borderRadius: {
		input: "6px",
		round: "25px",
	},
	border: {
		transparent: "2px solid transparent",
		input: "2px solid grey",
		primary: "2px solid var(--theme-color-primary-200)",
		secondary: "1px solid rgb(229, 231, 235)",
		box: "1px solid black",
	},
	outline: {
		soft: "2px solid #efefef",
		inputSoft: "2px solid rgb(0, 95, 204)",
		primary: "2px solid var(--theme-color-primary-200)",
		danger: "2px dashed orangered",
	},
	space: {
		1: "0.25rem",
		2: "0.5rem",
		3: "1rem",
		4: "2rem",
		5: "3rem",
		6: "5rem",
		7: "6rem",
	},
	boxShadow: {
		light: "0 8px 20px rgba(0 0 0 / 0.1)",
	},
} satisfies Palette;

export const ExampleTheme: ComponentTheme<
	["secondary", "soft", "danger", "largeOutlineOffset", "transparent"],
	typeof ExamplePalette
> = {
	name: "example-theme",
	palette: ExamplePalette,
	defaults: {
		accordion: {
			item: {
				backgroundColor: "soft",
				color: "foreground",
				borderRadius: "input",
				boxShadow: "light",
				border: "transparent",
				focus: {
					border: "primary",
				},
				hover: {
					backgroundColor: "shiny",
				},
			},
		},
		box: {
			color: "foreground",
			backgroundColor: "background",
			border: "box",
		},
		breadcrumbs: {
			color: "primary-200",
			disabled: {
				color: "inactive",
			},
		},
		button: {
			color: "background",
			backgroundColor: "primary-100",
			borderRadius: "input",
			border: "none",
			hover: {
				color: "background",
				backgroundColor: "primary-200",
				border: "none",
			},
			focus: {
				outline: "primary",
				outlineOffset: 1,
			},
			disabled: {
				color: "soft",
				backgroundColor: "shiny",
			},
		},
		checkbox: {
			accentColor: "primary-100",
			focus: {
				outlineColor: "primary-200",
			},
			disabled: {
				color: "shiny",
			},
		},
		menuButton: {
			color: "background",
			backgroundColor: "primary-100",
			borderRadius: "input",
			border: "none",
			withOpenMenu: {
				backgroundColor: "primary-200",
			},
			menu: {
				backgroundColor: "background",
			},
			menuItem: {
				borderRadius: "input",
				color: "background",
				focus: {
					backgroundColor: "primary-200",
				},
			},
			hover: {
				color: "background",
				backgroundColor: "primary-200",
				border: "none",
			},
			focus: {
				outline: "primary",
				outlineOffset: 1,
			},
			disabled: {
				color: "shiny",
				backgroundColor: "soft",
			},
		},
		popover: {
			boxShadow: "light",
			outline: "soft",
			borderRadius: "input",
		},
		numberField: {
			borderColor: "inactive",
			borderRadius: "input",
			color: "foreground",
			stepButton: {
				color: "foreground",
				active: {color: "background"},
			},
			hover: {
				border: "primary",
			},
			focus: {
				borderColor: "primary-200",
			},
			disabled: {
				borderColor: "shiny",
				color: "inactive",
			},
		},
		radio: {
			accentColor: "primary-100",
			focus: {
				outlineColor: "primary-200",
			},
			disabled: {
				color: "inactive",
			},
		},
		select: {
			backgroundColor: "background",
			border: "input",
			maxInlineSize: "20ch",
			borderRadius: "input",
			color: "foreground",
			arrow: {
				color: "inactive",
			},
			placeholder: {
				color: "inactive",
			},
			hover: {
				border: "primary",
			},
			focus: {
				border: "primary",
				arrow: {
					color: "primary-100",
				},
				option: {
					color: "background",
					backgroundColor: "primary-200",
				},
			},
			disabled: {
				color: "inactive",
				border: "input",
			},
		},
		switch: {
			color: "inactive",
			on: {
				color: "primary-100",
			},
			focus: {
				outlineColor: "primary-200",
			},
		},
		textField: {
			color: "foreground",
			backgroundColor: "background",
			border: "input",
			borderRadius: "input",
			paddingInline: 2,
			paddingBlock: 2,
			error: {
				color: "danger",
			},
			hover: {
				border: "primary",
			},
			focus: {
				border: "primary",
			},
		},
	},
	modes: {
		secondary: {
			button: {
				color: "foreground",
				backgroundColor: "background",
				border: "secondary",
				hover: {
					color: "foreground",
					border: "secondary",
					backgroundColor: "soft",
				},
				focus: {
					outline: "primary",
					outlineOffset: "none",
				},
			},
		},
		soft: {
			button: {
				color: "primary-100",
				borderRadius: "round",
				backgroundColor: "soft-200",
				hover: {
					backgroundColor: "soft-100",
					color: "primary-100",
				},
				focus: {
					outline: "inputSoft",
					outlineOffset: "none",
				},
			},
		},
		largeOutlineOffset: {
			button: {
				focus: {
					outlineOffset: 1,
				},
			},
		},
		danger: {
			button: {
				color: "background",
				backgroundColor: "destroy",
				hover: {
					backgroundColor: "danger",
					color: "background",
				},
				focus: {
					outline: "danger",
				},
			},
		},
		transparent: {
			accordion: {
				item: {
					color: "foreground",
					backgroundColor: "background",
				},
			},
			box: {
				border: "transparent",
			},
		},
	},
};

export const ExamplePalette2 = {
	color: {
		"primary-100": "rgb(102, 3, 144)",
		"primary-200": "rgb(169, 50, 212)",
		"soft-100": "rgb(255, 238, 251)",
		"soft-200": "rgb(255, 224, 244)",
		foreground: "black",
		background: "white",
		soft: "#efefef",
		shiny: "silver",
		inactive: "grey",
		destroy: "red",
		danger: "orangered",
	},
	borderRadius: {
		input: "6px",
		round: "25px",
	},
	border: {
		transparent: "2px solid transparent",
		input: "2px solid grey",
		primary: "2px solid var(--theme-color-primary-200)",
		secondary: "1px solid rgb(229, 231, 235)",
		box: "1px solid black",
	},
	outline: {
		soft: "2px solid #efefef",
		inputSoft: "2px solid rgb(0, 95, 204)",
		primary: "2px solid var(--theme-color-primary-200)",
		danger: "2px dashed orangered",
	},
	space: {
		1: "0.25rem",
		2: "0.5rem",
		3: "1rem",
		4: "2rem",
		5: "3rem",
		6: "5rem",
		7: "6rem",
	},
	boxShadow: {
		light: "0 8px 20px rgba(0 0 0 / 0.1)",
	},
} satisfies Palette;

export const ExampleTheme2: ComponentTheme<[], typeof ExamplePalette2> = {
	name: "example-theme-2",
	palette: ExamplePalette2,
	defaults: {
		accordion: {
			item: {
				backgroundColor: "soft",
				color: "foreground",
				borderRadius: "input",
				border: "transparent",
				boxShadow: "light",
				focus: {
					border: "primary",
				},
				hover: {
					backgroundColor: "shiny",
				},
			},
		},
		box: {
			color: "foreground",
			backgroundColor: "background",
			border: "box",
			boxShadow: "light",
		},
		breadcrumbs: {
			color: "primary-200",
			disabled: {
				color: "inactive",
			},
		},
		button: {
			color: "background",
			backgroundColor: "primary-100",
			borderRadius: "input",
			border: "none",
			hover: {
				color: "background",
				backgroundColor: "primary-200",
				border: "none",
			},
			focus: {
				outline: "primary",
				outlineOffset: 1,
			},
			disabled: {
				color: "soft",
				backgroundColor: "shiny",
			},
		},
		checkbox: {
			accentColor: "primary-100",
			focus: {
				outlineColor: "primary-200",
			},
			disabled: {
				color: "shiny",
			},
		},
		menuButton: {
			color: "background",
			backgroundColor: "primary-100",
			borderRadius: "input",
			border: "none",
			withOpenMenu: {
				backgroundColor: "primary-200",
			},
			menu: {
				backgroundColor: "background",
			},
			menuItem: {
				borderRadius: "input",
				color: "background",
				focus: {
					backgroundColor: "primary-200",
				},
			},
			hover: {
				color: "background",
				backgroundColor: "primary-200",
				border: "none",
			},
			focus: {
				outline: "primary",
				outlineOffset: 1,
			},
			disabled: {
				color: "shiny",
				backgroundColor: "soft",
			},
		},
		popover: {
			boxShadow: "light",
			outline: "soft",
			borderRadius: "input",
		},
		numberField: {
			borderColor: "inactive",
			borderRadius: "input",
			color: "foreground",
			stepButton: {
				color: "foreground",
				active: {color: "background"},
			},
			hover: {
				border: "primary",
			},
			focus: {
				borderColor: "primary-200",
			},
			disabled: {
				borderColor: "shiny",
				color: "inactive",
			},
		},
		radio: {
			accentColor: "primary-100",
			focus: {
				outlineColor: "primary-200",
			},
			disabled: {
				color: "inactive",
			},
		},
		select: {
			backgroundColor: "background",
			border: "input",
			maxInlineSize: "20ch",
			borderRadius: "input",
			color: "foreground",
			arrow: {
				color: "inactive",
			},
			placeholder: {
				color: "inactive",
			},
			hover: {
				border: "primary",
			},
			focus: {
				border: "primary",
				arrow: {
					color: "primary-100",
				},
				option: {
					color: "background",
					backgroundColor: "primary-200",
				},
			},
			disabled: {
				color: "inactive",
				border: "input",
			},
		},
		switch: {
			color: "inactive",
			on: {
				color: "primary-100",
			},
			focus: {
				outlineColor: "primary-200",
			},
		},
		textField: {
			color: "foreground",
			backgroundColor: "background",
			border: "input",
			borderRadius: "input",
			paddingInline: 2,
			paddingBlock: 2,
			error: {
				color: "danger",
			},
			hover: {
				border: "primary",
			},
			focus: {
				border: "primary",
			},
		},
	},
	modes: {
		// secondary: {
		// 	button: {
		// 		color: "foreground",
		// 		backgroundColor: "background",
		// 		border: "secondary",
		// 		hover: {
		// 			color: "foreground",
		// 			border: "secondary",
		// 			backgroundColor: "soft",
		// 		},
		// 		focus: {
		// 			outline: "primary",
		// 			outlineOffset: "none",
		// 		},
		// 	},
		// },
		// soft: {
		// 	button: {
		// 		color: "primary-100",
		// 		borderRadius: "round",
		// 		backgroundColor: "soft-200",
		// 		hover: {
		// 			backgroundColor: "soft-100",
		// 			color: "primary-100",
		// 		},
		// 		focus: {
		// 			outline: "inputSoft",
		// 			outlineOffset: "none",
		// 		},
		// 	},
		// },
		// largeOutlineOffset: {
		// 	button: {
		// 		focus: {
		// 			outlineOffset: 1,
		// 		},
		// 	},
		// },
		// danger: {
		// 	button: {
		// 		color: "background",
		// 		backgroundColor: "destroy",
		// 		hover: {
		// 			backgroundColor: "danger",
		// 			color: "background",
		// 		},
		// 		focus: {
		// 			outline: "danger",
		// 		},
		// 	},
		// },
		// transparent: {
		// 	accordion: {
		// 		item: {
		// 			color: "foreground",
		// 			backgroundColor: "background",
		// 		},
		// 	},
		// },
	},
};
