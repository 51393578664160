import {makeAutoObservable, toJS} from "mobx";

import type {File} from "../shared/Types.d.ts";
import {validateClassName} from "../util/validation";
import {removeSnippetComments} from "../util/snippets";
import JAVA_TEMPLATE from "../util/NewActorTemplate.json";
import {CsProjectT} from "../@types/TypeboxTypes";
import yaml from "yaml";
import CsInstance from "./CsInstance";
import {runFetch} from "../util/runFetch";
import _ from "lodash";

export type EditableRange = readonly [number, number];

type FileData = {[key: string]: File};

/**
 * State of a saved project that can be saved and reloaded. Stored in localStorage on a per-project basis.
 */
export type ProjectSaveState = {
	fileData: FileData;
	filesOpen: string[];
	focusedFile: string;
};

/**
 * MobX store for local file and project handling.
 */
class CodeStore {
	private _project: string | null = null;

	/**
	 * Note that files can be hidden.  When accessing `fileNames` or `fileData`,
	 * make sure to use the correct getter (there are fancy ones like
	 * `hiddenFileNames` and `visibleFileData`).
	 */
	private _fileNames: string[] = [];
	private _fileData: FileData = {};

	private _filesOpen: string[] = [];
	private _focusedFile = "";
	private _focusedFileEditableRange: EditableRange = [0, 0]; // inclusive [start, end]; set later when opening files
	private _editorChanged = false; // Tracks if the editor has been changed so we can enable/disable the compile button
	private _serverUrl: string;
	private _projectTemplates: CsProjectT[] = [];
	private _projectNameToIndex: Record<string, number> = {};

	private _csInstance: CsInstance;

	constructor(props: {csInstance: CsInstance; serverUrl: string}) {
		this._csInstance = props.csInstance;
		this._serverUrl = props.serverUrl;
		if (this._csInstance.featureFlagStore.loadPrevProject) {
			this.loadProject();
		}
		makeAutoObservable(this);
	}

	/**
	 * Given a project name, load it from local storage if it exists, and otherwise fetch it from the server.
	 * @param project The name of the project; if not provided, then the last-opened project is used.
	 */
	private loadProject(project?: string) {
		// If local storage data exists, load it
		const {saveState, localProject} = this.loadProjectFromLocalStorage(project);

		// Set this._project before getFileData() uses it
		// `localProject` *should* be correct over `project`
		this._project = localProject || project || this._project;

		if (saveState === null || _.isEmpty(saveState.fileData)) {
			this.getFileData();
			return;
		}

		this.fileData = saveState.fileData;
		this.fileNames = Object.keys(this.fileData);
		this._filesOpen = saveState.filesOpen;
		this.focusedFile = saveState.focusedFile;
	}

	// Local Storage Functions

	/**
	 * Load the given project, returning null for missing entries.
	 *
	 * @param project The project to load; if not provided, defaults to the last open project.
	 */
	private loadProjectFromLocalStorage(project?: string) {
		const toLoad = project ?? localStorage.getItem("mostRecentProject");
		const storageLocation = "csProjectData-" + toLoad;

		const stored = localStorage.getItem(storageLocation);
		return {
			localProject: toLoad,
			saveState: stored ? (JSON.parse(stored) as ProjectSaveState) : null,
		};
	}

	/**
	 * Save the current project to local storage. The project data is stored in the local storage entry
	 * csProjectData-<name>. There are no restrictions on the name.
	 */
	saveFileDataLocally() {
		if (!this._project) {
			console.error("Attempted to save file data with no project");
			return;
		}
		const storageLocation = "csProjectData-" + this._project;

		// Save which project we're on
		localStorage.setItem("mostRecentProject", this._project);

		// Save per-project data
		const saveState: ProjectSaveState = {
			fileData: this.fileData,
			filesOpen: this.filesOpen,
			focusedFile: this.focusedFile,
		};

		localStorage.setItem(storageLocation, JSON.stringify(saveState));
	}

	/**
	 * Saves a project template to memory, so it can be switched to later.
	 *
	 * @param projectTemplate The project template to save.
	 */
	cacheProjectTemplate(projectTemplate: CsProjectT) {
		if (!projectTemplate.files?.length) {
			console.error(
				"Attempted to load activity with no files:",
				JSON.stringify(projectTemplate, null, 2)
			);
			return;
		}

		this._projectNameToIndex[projectTemplate.name] =
			this._projectTemplates.length;
		this._projectTemplates.push(projectTemplate);
	}

	/**
	 * Get the list of cached project templates.
	 * Includes any default projects native to the CS app.
	 */
	get validProjectNames(): string[] {
		return Object.keys(this._projectNameToIndex);
	}

	/**
	 * Save the current activity to a YAML.
	 */
	outputActivityYaml(): string {
		const yamlObj: CsProjectT = {
			name: this._project || "",
			"ide-mode": "full",
			files: this._fileNames.map((name) => ({
				name,
				attributes: {
					open: this._filesOpen.includes(name),
				},
				content: this._fileData[name]?.value || "",
			})),
		};

		return yaml.stringify(yamlObj);
	}

	/**
	 * Get the files of the current project from either a local template (if it
	 * exists) or the server. Then, overwrite all local file data, and open the
	 * default hidden file.
	 *
	 * Note: dependent on this._project, this._projectNameToIndex, and
	 * this._projectTemplates.
	 */
	getFileData() {
		if (!this._project) {
			console.error("Attempted to load files with no project");
			return;
		}
		const templateIndex = this._projectNameToIndex[this._project];

		// if template exists, load it from the template (has an index aka index is a number, 0 is ok)
		if (typeof templateIndex === "number") {
			const template = this._projectTemplates[templateIndex];

			if (!template) {
				console.error(
					"internal CS error: template index stored but template not found",
					toJS(this._projectNameToIndex),
					toJS(this._projectTemplates)
				);
				return;
			}

			// clear out old data by creating new objects, for mobx
			const newHiddenFileNames: string[] = [];
			const newFileNames: string[] = [];
			const newFilesOpen: string[] = [];
			const newFileData: FileData = {};

			// fill in new data
			this._project = template.name;
			template.files.forEach((file) => {
				// TODO if the student has preexisting data, load it in properly

				// Hidden files should not be shown to users
				if (file.attributes?.hidden) {
					newHiddenFileNames.push(file.name);
				} else {
					newFileNames.push(file.name);
				}

				// Strip out snippet markers
				const content = removeSnippetComments(file.content);
				newFileData[file.name] = {
					name: file.name,
					value: content,
					attributes: {
						isOriginal: true,
						isHidden: file.attributes?.hidden,
						isMarkdown: file.attributes?.isMarkdown,
						isReadOnly: file.attributes?.isReadOnly,
					},
				};

				if (!file.attributes?.hidden && file.attributes?.open) {
					newFilesOpen.push(file.name);
				}
			});

			this._fileNames = newFileNames;
			this._filesOpen = newFilesOpen;
			this._fileData = newFileData;
			this._focusedFile = this._filesOpen[0] || "";

			return;
		}
	}

	// Sets the value of one File in fileData
	setFileData(key: string, value: File) {
		if (this._fileData[key]) {
			this._fileData[key] = value;
		}
	}

	resetFile(fileName: string) {
		this.editorChanged = true;

		const isOriginal =
			this._fileData[fileName]?.attributes?.isOriginal ?? false;

		if (!isOriginal) {
			this.deleteFile(fileName);
			return;
		}

		if (!this._project) {
			console.error("Attempted to reset file with no project");
			return;
		}

		const template =
			this._projectTemplates[this._projectNameToIndex[this._project]!];

		if (!template) {
			console.error(
				"internal CS error: template index stored but template not found",
				toJS(this._projectNameToIndex),
				toJS(this._projectTemplates)
			);
			return;
		}

		template.files.forEach((file) => {
			if (file.name === fileName) {
				const content = removeSnippetComments(file.content);
				this._fileData[file.name] = {
					name: file.name,
					value: content,
					attributes: {
						isOriginal: true,
						isHidden: file.attributes?.hidden,
						isMarkdown: file.attributes?.isMarkdown,
						isReadOnly: file.attributes?.isReadOnly,
					},
				};
				return;
			}
		});
	}

	resetProject() {
		this.getFileData();
		this.saveFileDataLocally();
	}

	resetLocalStorageAndReload() {
		runFetch({
			url: `${this._csInstance.serverUrl}/api/v2/log`,
			method: "POST",
			body: {
				logGroup: "resetLocalStorage",
				value: {project: this._project, data: this._fileData},
			},
			securityToken: this._csInstance.securityToken,
		});
		localStorage.clear();
		if (this._project) {
			this.loadProject(this._project);
		}
	}

	// Switch to next project then grab the files
	// If current is last project, switch to first project
	nextProject() {
		if (!this._project) {
			console.error("Attempted to switch project with no project");
			return;
		}
		const currIdx = this._projectNameToIndex[this._project];
		const nextIdx = (currIdx! + 1) % this._projectTemplates.length;
		this.switchProject(this._projectTemplates[nextIdx]!.name);
	}

	/**
	 * Switches project to a specific project if it exists.
	 * @param project The project to switch to
	 * @param shouldCompile Whether to compile the code after switching
	 */
	switchProject(project: string, shouldCompile = true) {
		if (!this.validProjectNames.includes(project)) {
			console.error(
				"invalid project name",
				project,
				", need",
				this.validProjectNames
			);
			return;
		}
		this.loadProject(project);
		if (shouldCompile) {
			this.compileCode();
		}
	}

	/**
	 * Add a student class subclassing Actor.
	 * @param derived Name of the student class to add.
	 * @returns The Java code for the new class.
	 * @throws {Error} Error if the class name is invalid, or there already exists a file that defines the class.
	 */
	addActorClass(derived: string): string {
		const validate = validateClassName(derived, this._fileNames);
		if (validate?.type === "error") throw new Error(validate.message);

		const javaCode = JAVA_TEMPLATE.replace(/\$\{derived}/g, derived);
		const fileName = derived + ".java";
		this.fileData[fileName] = {name: fileName, value: javaCode};

		if (!this.filesOpen.includes(fileName)) {
			this.filesOpen.push(fileName);
			this.fileNames.push(fileName);
			this.focusedFile = fileName;
		}

		this.saveFileDataLocally();
		return javaCode;
	}

	/**
	 * @param fileName File to delete.
	 */
	deleteFile(fileName: string) {
		if (this.focusedFile === fileName) {
			// need to move our focus
			const i = this.filesOpen.indexOf(fileName);
			const l = this.filesOpen.length;
			if (i !== -1 && l > 1) {
				// Prefer the file to the right of the original
				this.focusedFile = this.filesOpen[i === l - 1 ? l - 2 : i + 1]!;
			} else {
				this.focusedFile = "";
			}
		}

		// Delete everywhere
		this.filesOpen = this.filesOpen.filter((name) => name !== fileName);
		this.fileNames = this.fileNames.filter((name) => name !== fileName);
		delete this.fileData[fileName];

		this.saveFileDataLocally();
	}

	/**
	 * Get the list of YAMLs from the server side.
	 */
	async getServerSideYamls() {
		const {yamls: currentYamls} = (await runFetch({
			url: `${this._csInstance.serverUrl}/api/v2/getCurrentYamls`,
			method: "GET",
			securityToken: this._csInstance.securityToken,
		})) as {yamls: string[]};
		return currentYamls;
	}

	/**
	 * Set the server side YAMLs.
	 */
	setServerSideYamls(yamls: string[]) {
		runFetch({
			url: `${this._csInstance.serverUrl}/api/v2/setYaml`,
			method: "POST",
			body: {
				yamls,
			},
			securityToken: this._csInstance.securityToken,
		});
	}

	async compileCode() {
		const fileDataToCompile = Object.fromEntries(
			Object.entries(this._fileData).filter(([fileName, file]) => {
				return !file.attributes?.isMarkdown;
			})
		);
		await this._csInstance.compileStore.compileCode(
			fileDataToCompile,
			this.hiddenFileNames
		);
	}

	get focusedFileEditableRange(): EditableRange {
		return [...this._focusedFileEditableRange];
	}

	get projectTemplates() {
		return [...this._projectTemplates];
	}

	/**
	 * Gets ONLY the names of files visible to the user.
	 */
	get visibleFileNames() {
		return Object.keys(this._fileData).filter(
			(fileName) =>
				this._csInstance.featureFlagStore.showHiddenFiles ||
				!(this._fileData[fileName] || {}).attributes?.isHidden
		);
	}

	/**
	 * Gets ONLY the names of hidden files.
	 */
	get hiddenFileNames() {
		return Object.keys(this._fileData).filter(
			(fileName) =>
				!this._csInstance.featureFlagStore.showHiddenFiles &&
				(this._fileData[fileName] || {}).attributes?.isHidden
		);
	}

	/**
	 * Gets file data for ONLY files visible to the user.
	 * If you want all files, use `fileData`.
	 */
	get visibleFileData(): FileData {
		return this.visibleFileNames.reduce((accum, fileName) => {
			accum[fileName] = this._fileData[fileName]!;
			return accum;
		}, {} as FileData);
	}

	// autogen getters and setters for MobX actions

	/**
	 * Gets ALL filenames, including hidden files.
	 */
	get fileNames() {
		return this._fileNames;
	}

	set fileNames(value) {
		this._fileNames = value;
	}

	/**
	 * Gets ALL file data, including hidden files.
	 * If you want only visible files, use `visibleFileData`.
	 */
	get fileData() {
		return this._fileData;
	}

	set fileData(value) {
		this._fileData = value;
	}

	get focusedFile() {
		return this._focusedFile;
	}

	set focusedFile(value) {
		this._focusedFile = value;
		this.saveFileDataLocally();
	}

	get filesOpen() {
		return this._filesOpen;
	}

	set filesOpen(value) {
		this._filesOpen = value;
		this.saveFileDataLocally();
	}

	get project() {
		return this._project;
	}

	set project(value) {
		this._project = value;
	}

	get editorChanged() {
		return this._editorChanged;
	}

	set editorChanged(value) {
		this._editorChanged = value;
	}

	get serverUrl() {
		return this._serverUrl;
	}

	set serverUrl(value) {
		this._serverUrl = value;
	}
}

export default CodeStore;
