import React from "react";
import styles from "./ContextMenu.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faC} from "@fortawesome/sharp-regular-svg-icons/faC";
import {faCommand} from "@fortawesome/sharp-regular-svg-icons/faCommand";
import {faCopy} from "@fortawesome/sharp-regular-svg-icons/faCopy";
import {faCut} from "@fortawesome/sharp-regular-svg-icons/faCut";
import {faFileLines} from "@fortawesome/sharp-regular-svg-icons/faFileLines";
import {faI} from "@fortawesome/sharp-regular-svg-icons/faI";
import {faIndent} from "@fortawesome/sharp-regular-svg-icons/faIndent";
import {faPaste} from "@fortawesome/sharp-regular-svg-icons/faPaste";
import {faPlus} from "@fortawesome/sharp-regular-svg-icons/faPlus";
import {faV} from "@fortawesome/sharp-regular-svg-icons/faV";
import {faX} from "@fortawesome/sharp-regular-svg-icons/faX";
import {faWindows} from "@fortawesome/free-brands-svg-icons/faWindows";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faCat} from "@fortawesome/sharp-solid-svg-icons/faCat";
import {selectAndIndentWholeDocument} from "./Ide/indentExtension";
import {EditorView} from "@uiw/react-codemirror";

export type ContextMenuProps = {
	setContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	contextMenuOpen: boolean;
	x: number;
	y: number;
};

type ContextMenuItemProps = {
	setContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	icon: IconProp;
	text: string;
	hotkey?: IconProp;
};

const ContextMenuItem = ({
	setContextMenuOpen,
	icon,
	text,
	hotkey,
}: ContextMenuItemProps) => {
	const view = EditorView.findFromDOM(document.body);

	if (!hotkey) {
		hotkey = faCat; // Dummy value, we still need to render the hotkey div for formatting purposes
	}

	// The text styles for the context menu item. If the text is "Autoformat", the text area will be smaller because the hotkey is longer.
	const textStyles = [
		styles.text,
		text === "Autoformat" ? styles.smallerTextArea : "",
	].join(" ");

	// Determines if the user is on an iOS device so we can show the correct metakey icon.
	function isIos() {
		return (
			navigator.userAgent.includes("Mac") ||
			navigator.userAgent.includes("iPhone") ||
			navigator.userAgent.includes("iPad")
		);
	}

	const metaKey = isIos() ? faCommand : faWindows;

	/**
	 * Gets the currently selected text in the editor.
	 * @param view The editor view to get the selection from.
	 * @returns The currently selected text in the editor.
	 */
	function getSelection(editorView: EditorView): string {
		return editorView.state.sliceDoc(
			editorView.state.selection.main.from,
			editorView.state.selection.main.to
		);
	}

	/**
	 * Determines if the option should be disabled based on the user's current selection or clipboard contents.
	 * @returns true if the option is disabled, false otherwise
	 */
	function disabled(): boolean {
		switch (text) {
			case "Cut":
				return (
					view?.state.selection.main.from === view?.state.selection.main.to
				);
			case "Copy":
				return (
					view?.state.selection.main.from === view?.state.selection.main.to
				);
			case "Paste":
				return false;
			default:
				return false;
		}
	}

	/**
	 * Handles the click event for the context menu item.
	 */
	function onClick() {
		if (!view) {
			return;
		}
		if (!disabled()) {
			setContextMenuOpen(false);
		}
		switch (text) {
			case "Cut":
				const textToCut = getSelection(view);
				navigator.clipboard.writeText(textToCut).then(() => {
					view.dispatch(view?.state.replaceSelection(""));
				});
				break;
			case "Copy":
				const textToCopy = getSelection(view);
				navigator.clipboard.writeText(textToCopy);
				break;
			case "Paste":
				const cursorLocation = view.state.selection.main.head;
				navigator.clipboard.readText().then((clipboardText) => {
					view.dispatch({
						changes: {from: cursorLocation, insert: clipboardText},
					});
					if (
						view.state.selection.main.to - view.state.selection.main.from >
						0
					) {
						view.dispatch(view?.state.replaceSelection(""));
					}
				});
				break;
			case "Autoformat":
				selectAndIndentWholeDocument(view!);
				break;
			case "Documentation":
				window.open(
					"https://aops-ba.github.io/cs-java-summer/AopsTheater/package-summary.html",
					"_blank"
				);
				setContextMenuOpen(false);
				break;
		}
	}

	return (
		<div
			className={[styles.option, disabled() ? styles.disabled : ""].join(" ")}
			onClick={onClick}
		>
			<FontAwesomeIcon icon={icon} className={styles.icon} />
			<div className={textStyles}>{text}</div>
			<div
				className={styles.hotkey}
				style={hotkey === faCat ? {visibility: "hidden"} : {}}
			>
				{text === "Autoformat" ? (
					<>
						SHIFT
						<FontAwesomeIcon icon={faPlus} className={styles.plusIcon} />
					</>
				) : null}
				<FontAwesomeIcon icon={metaKey as IconProp} className={styles.icon} />
				<FontAwesomeIcon icon={faPlus} className={styles.plusIcon} />
				<FontAwesomeIcon icon={hotkey} className={styles.icon} />
			</div>
		</div>
	);
};

const ContextMenu = ({
	setContextMenuOpen,
	contextMenuOpen,
	x,
	y,
}: ContextMenuProps) => {
	return (
		<div
			id="contextMenu"
			className={styles.contextMenu}
			style={{
				top: y + "px",
				left: x + "px",
				visibility: contextMenuOpen ? "visible" : "hidden",
			}}
		>
			<div className={styles.optionContainer}>
				<div className={styles.containerHeader}>Clipboard</div>
				<ContextMenuItem
					setContextMenuOpen={setContextMenuOpen}
					icon={faCut}
					text="Cut"
					hotkey={faX}
				/>
				<ContextMenuItem
					setContextMenuOpen={setContextMenuOpen}
					icon={faCopy}
					text="Copy"
					hotkey={faC}
				/>
				<ContextMenuItem
					setContextMenuOpen={setContextMenuOpen}
					icon={faPaste}
					text="Paste"
					hotkey={faV}
				/>
			</div>

			<div className={styles.divider}></div>

			<div className={styles.optionContainer}>
				<div className={styles.containerHeader}>Layout</div>
				<ContextMenuItem
					setContextMenuOpen={setContextMenuOpen}
					icon={faIndent}
					text="Autoformat"
					hotkey={faI}
				/>
			</div>

			<div className={styles.divider}></div>

			<div className={styles.optionContainer}>
				<div className={styles.containerHeader}>Resources</div>
				<ContextMenuItem
					setContextMenuOpen={setContextMenuOpen}
					icon={faFileLines}
					text="Documentation"
				/>
			</div>
		</div>
	);
};

export default ContextMenu;
