/**
 * Copied from vcvc-prototype-2
 *
 * TODO note that this util could end up in a Fastack package -- we should do a
 * small refactor to import runFetch from that package instead in that case to
 * keep things clean.
 *
 * Thread: https://aops.slack.com/archives/C05K3QX3UNM/p1695060548635489
 */

import "whatwg-fetch";

export type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";

export type FetchProps = {
	url: string;
	method?: FetchMethod;
	body?: null | object;
};

export type FetchFunc = (props: FetchProps) => Promise<any>;

export type FetchError = Error & {status: number; code?: string};

function defaultFetchRunner(props: FetchProps): Promise<any> {
	const {url, body, method = "POST"} = props;

	return global
		.fetch(url, {
			method,
			headers: {
				"Content-Type": "application/json",
			},
			body: body == null ? null : JSON.stringify(body),
		})
		.then((res) => {
			if (res && res.status >= 400 && res.status <= 599) {
				return res.text().then((e) => {
					const err: FetchError = new Error(e) as any;
					err.status = res.status;

					try {
						const jsonE: any = JSON.parse(e);
						err.code = jsonE.code ?? undefined;
						err.message = jsonE.message ?? undefined;
					} catch (error) {}

					throw err;
				});
			} else if (res && typeof res.json === "function") {
				return res.json();
			}
			const err: FetchError = new Error(res.statusText) as any;
			err.status = res ? res.status : 0;
			throw err;
		});
}

let fetchRunner: FetchFunc = defaultFetchRunner;

export function runFetch(props: FetchProps): Promise<any> {
	return fetchRunner(props);
}

export function setFetchRunner(f: FetchFunc) {
	fetchRunner = f;
}

export function setFetchRunnerToDefault() {
	fetchRunner = defaultFetchRunner;
}
