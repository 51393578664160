import {useMemo, useRef} from "react";
import {useTextField} from "react-aria";
import {Stack} from "../../layout/Stack/Stack";
import React from "react";
import styles from "./TextField.module.css";
import {useMode} from "../../internal/Mode/useMode";

export interface ITextField {
	readonly type?: "text" | "url" | "email";
	readonly name?: string;
	readonly value: string;
	readonly onChange: (value: string) => void;
	readonly errorMessage?: React.ReactNode;

	readonly isDisabled?: boolean;
	readonly isRequired?: boolean;
	readonly minLength?: number;
	readonly maxLength?: number;
	readonly placeholder?: string;

	readonly maxInlineSize?: `${string}ch`;
}

export interface ITextFieldLabel extends ITextField {
	readonly label: React.ReactNode;
}

export interface ITextFieldAriaLabel extends ITextField {
	readonly "aria-label": string;
	readonly label?: React.ReactNode;
}

export type ITextFieldProps = ITextFieldLabel | ITextFieldAriaLabel;

/**
 * Accept user input of type `text`, `url`, or `email` with assistive labeling and an optional error message.
 *
 * @param props Object of {@link ITextField}
 * @param props.type Type of input element. Default is `text`
 * @param props.name Name for input element when inside a form element
 * @param props.label Label for input
 * @param props.value Value of the input element
 * @param props.onChange Callback for change of input value
 * @param props.errorMessage
 * @param props.isDisabled
 * @param props.isRequired Mark input as required
 * @param props.minLength
 * @param props.maxLength
 * @param props.placeholder Placeholder for input
 * @param props.maxInlineSize - The maximum inline size for the input. Default is 20 characters
 */
export function TextField(props: ITextFieldProps) {
	const inputRef = useRef(null);
	const {labelProps, inputProps, errorMessageProps} = useTextField(
		props,
		inputRef
	);

	const {style} = useMode("textField");

	const mergedStyle = useMemo(
		() => ({
			"--textField-maxInlineSize": props.maxInlineSize,
			...style,
		}),
		[style, props.maxInlineSize]
	);

	return (
		<Stack>
			{props.label ? (
				<label
					{...labelProps}
					className={
						props.isRequired ? styles.requiredTextFieldLabel : undefined
					}
				>
					{props.label}
				</label>
			) : undefined}
			<input
				{...inputProps}
				width="auto"
				ref={inputRef}
				aria-label={"aria-label" in props ? props["aria-label"] : undefined}
				spellCheck="false"
				required={props.isRequired}
				className={styles.textFieldInput}
				style={mergedStyle}
			/>
			{props.errorMessage && (
				<div
					{...errorMessageProps}
					className={styles.textFieldErrorMessage}
					style={style}
				>
					{props.errorMessage}
				</div>
			)}
		</Stack>
	);
}
