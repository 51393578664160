import React, {useRef} from "react";
import {useCsContext} from "../../state/CsInstance";
import {observer} from "mobx-react-lite";

import {faUpRightAndDownLeftFromCenter} from "@fortawesome/sharp-solid-svg-icons/faUpRightAndDownLeftFromCenter";
import {faDownLeftAndUpRightToCenter} from "@fortawesome/sharp-solid-svg-icons/faDownLeftAndUpRightToCenter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./LogConsole.module.scss";
import {ErrorBoundary} from "react-error-boundary";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import {faBrowsers} from "@fortawesome/sharp-regular-svg-icons/faBrowsers";
import {faXmark} from "@fortawesome/sharp-solid-svg-icons/faXmark";
import {faExclamation} from "@fortawesome/sharp-solid-svg-icons/faExclamation";
import ConsoleToast from "./ConsoleToast";

type Props = {};

const LogConsole: React.FC<Props> = () => {
	const {compileStore, theaterStore, featureFlagStore} = useCsContext();
	const consoleMessage = compileStore.error || theaterStore.getLogAsString();
	const isRed = compileStore.error !== "";
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const consoleExpanded = theaterStore.consoleExpanded;
	const showConsole = theaterStore.showConsole;

	// TODO compute toast data based on test results
	const [showToast, setShowToast] = React.useState(false);
	const testsSuccessMessage = "You passed all the test cases. Hurray!";
	const passingTests = 3;
	const totalTests = 7;
	const testsErrorMessage = `You passed ${passingTests} out of ${totalTests} test cases. Check the console for details.`;

	const toastType = consoleExpanded ? "error" : "success";
	const toastMessage = consoleExpanded
		? testsErrorMessage
		: testsSuccessMessage;

	// On every re-render
	if (textAreaRef.current)
		textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;

	if (showConsole) {
		return (
			<ErrorBoundary FallbackComponent={ErrorComponent}>
				<div
					className={[
						styles.consoleLogContainer,
						consoleExpanded ? styles.consoleLogContainerExpanded : "",
					].join(" ")}
				>
					{featureFlagStore.useTests && showToast && (
						<ConsoleToast message={toastMessage} type={toastType} />
					)}
					<h2 className={styles.consoleLogBanner}>
						Console
						<div className={styles.buttonContainer}>
							{featureFlagStore.useTests && (
								<button
									className={styles.consoleLogButton}
									onClick={() => setShowToast(!showToast)}
								>
									<FontAwesomeIcon icon={faExclamation} />
								</button>
							)}
							<button
								className={styles.consoleLogButton}
								onClick={() => {
									theaterStore.consoleExpanded = !theaterStore.consoleExpanded;
								}}
							>
								<FontAwesomeIcon
									icon={
										consoleExpanded
											? faDownLeftAndUpRightToCenter
											: faUpRightAndDownLeftFromCenter
									}
								/>
							</button>

							<button
								className={styles.consoleLogButton}
								onClick={() => {
									theaterStore.showConsole = false;
								}}
							>
								<FontAwesomeIcon icon={faXmark} />
							</button>
						</div>
					</h2>
					<textarea
						className={[
							styles.consoleLogOutput,
							consoleExpanded ? styles.consoleLogOutputExpanded : "",
						].join(" ")}
						id="review-text"
						readOnly={true}
						placeholder="Results of your code will appear here."
						ref={textAreaRef}
						value={consoleMessage}
						style={{
							color: isRed ? "maroon" : "black",
						}}
					/>
				</div>
			</ErrorBoundary>
		);
	} else {
		return (
			<button
				className={styles.showConsoleButton}
				onClick={() => {
					theaterStore.showConsole = true;
				}}
			>
				<FontAwesomeIcon icon={faBrowsers} />
				Console
			</button>
		);
	}
};

export default observer(LogConsole);
