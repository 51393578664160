// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalUpload-module__uploadContainer--zAIFZ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.ModalUpload-module__uploadInput--vl3h6 {
  padding: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ModalUpload.module.css"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACD;;AAEA;EACC,eAAA;AACD","sourcesContent":[".uploadContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 100%;\n\theight: 100%;\n\tpadding: 1rem;\n}\n\n.uploadInput {\n\tpadding: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadContainer": `ModalUpload-module__uploadContainer--zAIFZ`,
	"uploadInput": `ModalUpload-module__uploadInput--vl3h6`
};
export default ___CSS_LOADER_EXPORT___;
