import React, {createContext} from "react";
import type {PropsWithChildren} from "react";
import {AriaBreadcrumbsProps, useBreadcrumbs} from "react-aria";
import styles from "./Breadcrumbs.module.css";
import {useMode} from "../../internal/Mode/useMode";

export interface IBreadcrumbs extends PropsWithChildren, AriaBreadcrumbsProps {
	/* Navigation label */
	"aria-label": string;
	separator?: JSX.Element | string;
}

export const BreadcrumbSeparatorContext = createContext<
	Pick<IBreadcrumbs, "separator">
>({
	separator: ">",
});

/**
 * Split `Crumb`s with `separator`s for a breadcrumb-shaped navigation.
 *
 * @param props.children
 * @returns
 */
export function Breadcrumbs({
	children,
	separator = ">",
	...props
}: IBreadcrumbs) {
	const {navProps} = useBreadcrumbs(props);
	const {style} = useMode("breadcrumbs");

	return (
		<BreadcrumbSeparatorContext.Provider value={{separator}}>
			<nav {...navProps} aria-label={props["aria-label"]} style={style}>
				<ol className={styles.breadcrumbsList}>{children}</ol>
			</nav>
		</BreadcrumbSeparatorContext.Provider>
	);
}
