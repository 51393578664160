import React from "react";

function ErrorComponent() {
	return (
		<div>
			<p>
				An error occurred when trying to render this component. Please try again
				later.
			</p>
		</div>
	);
}

export default ErrorComponent;
