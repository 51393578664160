import React, {useMemo} from "react";
import {PropsWithChildren} from "react";
import styles from "./Box.module.css";
import {CSSWithVariables} from "../../types";
import {SpaceTokenName} from "../../types";
import {makeSpaceCSSVariable} from "../../internal/flattenObjectToCSS";
import {useMode} from "../../internal/Mode/useMode";

export interface IBox extends PropsWithChildren {
	readonly padding?: 0 | SpaceTokenName;
}

/**
 * Surround children with a border and padding.
 */
export function Box({children, padding = "space-2"}: IBox) {
	const paddingStyle = {
		"--box-padding": makeSpaceCSSVariable(padding),
	} as CSSWithVariables;

	const {style} = useMode("box");
	const mergedStyle = useMemo(
		() => ({...paddingStyle, ...style}),
		[style, paddingStyle]
	);

	return (
		<div className={styles.box} style={mergedStyle}>
			{children}
		</div>
	);
}
