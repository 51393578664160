// ActivityDropDown.tsx
import React from "react";
import {useCsContext} from "../../state/CsInstance";
import {MenuButton, Item} from "@aops-trove/fast-components";
import {useTheme} from "../../util/theme";
import {observer} from "mobx-react-lite";

interface ActivityDropDownType {
	onClick: (key: string) => void;
}

const ActivityDropDown = (props: ActivityDropDownType) => {
	const {codeStore} = useCsContext();
	const {Mode} = useTheme();
	const {validProjectNames} = codeStore;

	const projects = validProjectNames;

	return (
		<>
			<Mode>
				<MenuButton
					label={codeStore.project ?? ""}
					onAction={(key) => props.onClick(key.toString())}
				>
					{projects.map((project) => {
						return <Item key={project}>{project}</Item>;
					})}
				</MenuButton>
			</Mode>
		</>
	);
};

export default observer(ActivityDropDown);
