import {CSSWithVariables} from "../../types";
import React, {
	CSSProperties,
	PropsWithChildren,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import styles from "./WithSidebar.module.css";
import throttle from "lodash/throttle";
import {WithSidebarContext} from "./WithSidebarContext";
import {SpaceTokenName} from "../../types";
import {makeSpaceCSSVariable} from "../../internal/flattenObjectToCSS";

export interface IWithSidebar extends PropsWithChildren {
	readonly contentBreakpointWidth?: CSSProperties["width"];
	readonly gap?: 0 | SpaceTokenName;
	readonly isObservingWrapping?: true;
	readonly sidebarOnRight?: true;
	readonly sidebarWidth?: CSSWithVariables["width"];
}

/**
 * Set the first child as Sidebar and the last as Content. If `sidebarOnRight` is true, the order is flipped.
 *
 * When `isObservingWrapping` is `true`, the wrap state is available from `WithSidebarContext`.
 *
 * ```tsx
 * import {WithSidebarContext} from "@aops-trove/fast-components"
 *
 * function ExampleInnerComponent() {
 * 	const {isSidebarWrapped} = useContext(WithSidebarContext);
 * }
 * ```
 *
 * @param contentBreakpointWidth When Content width is less than this value, wrap the Sidebar children and go vertical. Default is 50%
 * @param gap Space between Sidebar and Content.
 * @param isObservingWrapping Whether or not to observe the wrapping of Sidebar children. When true, wrap state is available from `WithSidebarContext`
 * @param sidebarOnRight Use last child as Sidebar and first as Content
 * @param sidebarWidth Minimum width for the Sidebar. Default is auto
 */
export function WithSidebar({
	children,
	contentBreakpointWidth,
	gap = "space-2",
	isObservingWrapping,
	sidebarOnRight,
	sidebarWidth,
}: IWithSidebar) {
	const wrappingDiv = useRef<HTMLDivElement>(null);
	const [isSidebarWrapped, setIsSidebarWrapped] = useState<boolean | null>(
		null
	);

	const className: string = sidebarOnRight
		? styles.withSidebarOnRight
		: styles.withSidebar;

	const style = {
		"--withSidebar-contentBreakpointWidth": contentBreakpointWidth,
		"--withSidebar-gap": makeSpaceCSSVariable(gap),
		"--withSidebar-sidebarWidth": sidebarWidth,
	} as CSSWithVariables;

	const collapseObserver = useMemo(
		() => getThrottledWrapObserver(setIsSidebarWrapped),
		[setIsSidebarWrapped]
	);

	useLayoutEffect(() => {
		if (wrappingDiv.current && isObservingWrapping) {
			collapseObserver.observe(wrappingDiv.current);
		}

		return () => {
			collapseObserver.disconnect();
		};
	}, []);

	return (
		<WithSidebarContext.Provider value={{isSidebarWrapped}}>
			<div className={className} style={style} ref={wrappingDiv}>
				{children}
			</div>
		</WithSidebarContext.Provider>
	);
}

/**
 * Create a throttled ResizeObserver to detect when the first or second child has
 * wrapped. With throttling, the wrap check is called at most once per 100
 * milliseconds.
 *
 * Wrapping is determined by the distance of each child element to the `top` of the viewport. If the `top` values
 * differ, the elements are wrapped.
 *
 * Read more:
 *  - [ResizeObserver](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/ResizeObserver)
 *  - [getBoundingClientRect](https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect#return_value)
 *
 * @param setIsSidebarWrapped Setter from useState hook
 * @returns
 */
const getThrottledWrapObserver = (
	setIsSidebarWrapped: React.Dispatch<React.SetStateAction<boolean | null>>
) => {
	return new ResizeObserver(
		throttle(
			(entries: ResizeObserverEntry[]) => {
				const observedElement = entries[0].target;

				if (observedElement.childElementCount > 1) {
					const first = observedElement.children[0];
					const second = observedElement.children[1];

					const firstDistance = Math.floor(first.getBoundingClientRect().top);
					const secondDistance = Math.floor(second.getBoundingClientRect().top);

					if (secondDistance - firstDistance != 0) {
						setIsSidebarWrapped(true);
					} else {
						setIsSidebarWrapped(false);
					}
				}
			},
			50,
			{leading: true}
		)
	);
};
