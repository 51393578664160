import {makeAutoObservable, runInAction} from "mobx";

import {
	CheckSuccessReply,
	CompilationFailureReply,
	CompilationSuccessReply,
	Files,
	FlattenedMetadata,
	InjectorMetadata,
} from "../shared/Types";
import CsInstance from "./CsInstance";

// linear state machine; can always jump to "None" or "Error"
// None -> Compiling -> Swappable -> None
export enum CompileStatus {
	None = "None",
	Compiling = "Compiling",
	Error = "Error",
}

function flattenMetadata(
	existing: FlattenedMetadata,
	metadata: InjectorMetadata[]
): FlattenedMetadata {
	// preserve old AopsTheater method IDs
	const flattened = {method_map: {...existing.method_map}} as FlattenedMetadata;

	for (const {method_map, class_name, source_file} of metadata)
		for (const [methodI, info] of Object.entries(method_map))
			flattened.method_map[methodI as unknown as number] = {
				...info,
				class_name,
				source_file,
			};

	return flattened;
}

/**
 * MobX store for compilation state.
 */
class CompileStore {
	private _compileStatus = CompileStatus.None;
	private _error = "";
	private _versionedJarData: [Uint8Array, number] = [new Uint8Array([]), -1];
	private _version = 1;
	private _hasHadSuccessfulCompile = false;
	private _serverUrl: string;
	private _abortController: AbortController;
	private _metadatas: FlattenedMetadata = {method_map: {}};

	private _csInstance: CsInstance;

	constructor(props: {csInstance: CsInstance; serverUrl: string}) {
		this._csInstance = props.csInstance;
		this._serverUrl = props.serverUrl;
		this._abortController = new AbortController();
		makeAutoObservable(this);
	}

	// methods
	async compileCode(code: Files, hiddenFileNames?: string[]) {
		this.compileStatus = CompileStatus.Compiling;
		try {
			const version = this._version++;
			const jar = await this.callCompileApi(code, version, hiddenFileNames);
			if (jar) {
				this.versionedJarData = [jar, version];
				this.compileStatus = CompileStatus.None;
			} else {
				this.compileStatus = CompileStatus.Error;
			}
		} catch (e) {
			console.error("failed to make API call to compile code", e);
			this.compileStatus = CompileStatus.Error;
		}

		// Finish compile
		this._csInstance.getOrCreateMessenger().stopPixi();

		// Automatically run tests (if they exist) on compile
		if (this._csInstance.testsStore.hasTests) {
			this._csInstance.getOrCreateMessenger().runTests();
		}
	}

	async callDiagnosticsApi(
		code: Files,
		hiddenFileNames?: string[]
	): Promise<CheckSuccessReply | null> {
		const response = await fetch(`${this._serverUrl}/api/v2/compileCode`, {
			mode: "cors",
			method: "POST",
			headers: {
				"Content-type": "application/json",
				"X-CSST-TOKEN": this._csInstance.securityToken,
			},
			body: JSON.stringify({
				project: this._csInstance.codeStore.project,
				code: code,
				version: 0,
				studentCodeOnly: true,
				hiddenFileNames: hiddenFileNames,
				checkOnly: true,
			}),
		});

		if (response.ok) {
			const check = (await response.json()) as CheckSuccessReply;
			console.log(check);
			return check;
		} else {
			return null;
		}
	}

	async callCompileApi(code: Files, version = 0, hiddenFileNames?: string[]) {
		this.error = "";
		// abort previous compile calls
		this._abortController.abort();
		this._abortController = new AbortController();
		const response = await fetch(`${this._serverUrl}/api/v2/compileCode`, {
			mode: "cors",
			method: "POST",
			headers: {
				"Content-type": "application/json",
				"X-CSST-TOKEN": this._csInstance.securityToken,
			},
			body: JSON.stringify({
				project: this._csInstance.codeStore.project,
				code: code,
				version,

				// If the user has had a successful compile, we only want to send the student code
				// for efficiency purposes
				studentCodeOnly: this.hasHadSuccessfulCompile,

				hiddenFileNames: hiddenFileNames,

				checkOnly: false,
			}),
			signal: this._abortController.signal,
		});

		let errorText: string;
		if (response.ok) {
			const reply = (await response.json()) as
				| CompilationSuccessReply
				| CompilationFailureReply;
			if (reply.type === "success") {
				this.metadatas = flattenMetadata(this.metadatas, reply.metadatas);

				// https://stackoverflow.com/questions/64770762 to handle async actions in MobX
				runInAction(() => {
					this._csInstance.testsStore.hasTests = reply.hasTests;
				});

				this.error = "";
				this.hasHadSuccessfulCompile = true;
				this._csInstance.theaterStore.clearLog();
				this._csInstance.theaterStore.showConsole = false;

				return new Uint8Array(
					// Convert base64 string to ArrayBuffer
					// Credit: https://stackoverflow.com/a/49273187#comment104073073_49273187
					await (
						await fetch("data:application/octet;base64," + reply.jarBase64)
					).arrayBuffer()
				);
			}
			// Compile error case
			errorText = reply.error;
		} else {
			errorText = await response.text();
		}
		this.compileStatus = CompileStatus.Error;
		this.setErrorStatusAndMessage(errorText);
	}

	get isCompiling() {
		return this.compileStatus === CompileStatus.Compiling;
	}

	setErrorStatusAndMessage(error?: string) {
		if (error) {
			this._error = error;
			this._csInstance.theaterStore.showConsole = true;
		}
	}

	// autogen getters and setters for MobX actions

	get compileStatus() {
		return this._compileStatus;
	}
	private set compileStatus(value) {
		this._compileStatus = value;
	}

	get metadatas() {
		return this._metadatas;
	}
	set metadatas(value) {
		this._metadatas = value;
	}

	get error() {
		return this._error;
	}
	set error(value) {
		this._error = value;
	}

	get versionedJarData() {
		return this._versionedJarData;
	}
	private set versionedJarData(value) {
		this._versionedJarData = value;
	}

	get hasHadSuccessfulCompile() {
		return this._hasHadSuccessfulCompile;
	}
	set hasHadSuccessfulCompile(value) {
		this._hasHadSuccessfulCompile = value;
	}

	get serverUrl() {
		return this._serverUrl;
	}

	set serverUrl(value) {
		this._serverUrl = value;
	}
}

export default CompileStore;
