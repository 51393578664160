import React, {PropsWithChildren, useContext, useMemo, useState} from "react";
import type {ComponentMode, Palette} from "../../types";
import {stringToColor} from "../stringToColor";
import {usePopover} from "../../action/UsePopover/UsePopover";
import {merge} from "lodash";
import {ThemeContext} from "../../theme/ThemeContext";
import {flattenObjectToCSSVariables} from "../flattenObjectToCSS";
import {ModeContext} from "./ModeContext";

interface IInternalMode<P extends Palette> extends PropsWithChildren {
	mode: ComponentMode<P>;
	debug?: string;
}

export function InternalMode<P extends Palette>({
	mode,
	children,
	debug,
}: IInternalMode<P>) {
	const context = useContext(ModeContext);
	const theme = useContext(ThemeContext);
	const layeredMode = useMemo(
		() => merge({}, context.layeredMode, mode),
		[mode, context.layeredMode]
	);

	const MaybeDebugWrapper = useMemo(
		() =>
			({children}: PropsWithChildren) => {
				const [isOpen, setIsOpen] = useState(false);
				const {Popover, triggerProps, ref} = usePopover({
					isOpen,
					setIsOpen,
					ariaLabel: "Text Popover",
					placement: "left top",
				});

				return debug ? (
					<div
						style={{
							border: `2px solid ${stringToColor(debug)}`,
						}}
					>
						<p
							{...triggerProps}
							ref={ref}
							style={{
								padding: 0,
								cursor: "pointer",
								margin: 0,
								maxInlineSize: "max-content",
								fontWeight: "bold",
								backgroundColor: stringToColor(debug),
							}}
							onClick={() => setIsOpen(!isOpen)}
						>
							{debug}
						</p>
						<InternalMode
							mode={{
								popover: {
									outline: `2px solid ${stringToColor(debug)}`,
									borderRadius: "0",
									boxShadow: "0",
								},
							}}
						>
							<Popover>
								<div
									style={{
										background: stringToColor(debug),
										fontFamily: "monospace",
										width: "90vw",
										padding: 0,
									}}
								>
									<pre
										style={{
											padding: 0,
											margin: 0,
											background: stringToColor(debug),
										}}
									>
										{JSON.stringify(
											flattenObjectToCSSVariables(mode, theme.palette),
											null,
											6
										)}
									</pre>
								</div>
							</Popover>
						</InternalMode>
						{children}
					</div>
				) : (
					children
				);
			},
		[debug]
	);

	return (
		<ModeContext.Provider value={{mode, layeredMode}}>
			<MaybeDebugWrapper>{children}</MaybeDebugWrapper>
		</ModeContext.Provider>
	);
}
