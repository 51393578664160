import {CjArray} from "../@types/Types";
import {
	AopsTheater_CodeTester$TestCategoryData,
	AopsTheater_CodeTester$TestData,
} from "./TheaterTypes";

/**
 * Convert a CheerpJ array proxy to a plain old Javascript array.
 *
 * @param cjArray A CheerpJ array proxy
 * @returns an actual usable array
 */
function cjArrayToArray<T>(cjArray: CjArray<T>): T[] {
	const a: T[] = [];
	for (
		let i = 0, element: T | undefined;
		(element = Reflect.get(cjArray, i)) != null;
		++i
	) {
		a.push(element);
	}
	return a;
}

/**
 * Generate a list of tests from a (broken CheerpJ) proxy object.
 *
 * Cheerp has some issues with their output Proxy objects (e.g. object spread
 * operator crashes, obj.attr fails), so we need to manually iterate over the
 * array/object.
 */
export async function listTests(
	testsListProxy: CjArray<AopsTheater_CodeTester$TestCategoryData>
): Promise<string[]> {
	const tests: string[] = [];
	for (const category of cjArrayToArray<AopsTheater_CodeTester$TestCategoryData>(
		testsListProxy
	)) {
		// eslint-disable-next-line no-await-in-loop -- we want to run tests sequentially
		const testsForCategory = await category.getTests();
		for (const test of cjArrayToArray<AopsTheater_CodeTester$TestData>(
			testsForCategory
		)) {
			// eslint-disable-next-line no-await-in-loop -- we want to run tests sequentially
			tests.push(await test.getName());
		}
	}
	return tests;
}
