// ModalUpload.tsx
import React from "react";
import styles from "./ModalUpload.module.css";
import SimpleModal, {SimpleModalType} from "./SimpleModal";
import {useCsContext} from "../../state/CsInstance";

interface ModalUploadType extends SimpleModalType {
	text?: string;
	onSuccess: (file?: File) => void;
}

export default function ModalUpload(props: ModalUploadType) {
	const options = {...props.options};
	const csInstance = useCsContext();
	if (!options?.submitText) {
		options.submitText = "Upload";
	}

	const [files, setFiles] = React.useState<FileList | undefined>();
	const [doUpload, setDoUpload] = React.useState(false);

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		const newFiles = event.target.files;
		if (newFiles !== null) {
			setFiles(newFiles);
		}
	}

	const onClickSuccess = async () => {
		props.onSuccess(files?.[0]);
		props.toggle();

		if (!files || !files.length) return;

		const yamls = await Promise.all(
			[...files].sort((a, b) => (a.name < b.name ? -1 : 1)).map((f) => f.text())
		);

		if (doUpload) csInstance.codeStore.setServerSideYamls(yamls);
	};

	return (
		<SimpleModal {...{...props, onSuccess: onClickSuccess, options}}>
			{props.text}
			<div className={styles.uploadContainer}>
				<input
					className={styles.uploadInput}
					type="file"
					onChange={handleChange}
					multiple={true}
				/>
			</div>
			<label htmlFor={"doupload"}>Upload to server</label>
			<input
				type={"checkbox"}
				onChange={(value) => {
					setDoUpload(value.target.checked);
				}}
			/>
		</SimpleModal>
	);
}
