// Taken from https://docs.oracle.com/javase/tutorial/java/nutsandbolts/_keywords.html
const KEYWORDS = [
	"abstract",
	"continue",
	"for",
	"new",
	"switch",
	"assert",
	"default",
	"goto",
	"package",
	"synchronized",
	"boolean",
	"do",
	"if",
	"private",
	"this",
	"break",
	"double",
	"implements",
	"protected",
	"throw",
	"byte",
	"else",
	"import",
	"public",
	"throws",
	"case",
	"enum",
	"instanceof",
	"return",
	"transient",
	"catch",
	"extends",
	"int",
	"short",
	"try",
	"char",
	"final",
	"interface",
	"static",
	"void",
	"class",
	"finally",
	"long",
	"strictfp",
	"volatile",
	"const",
	"float",
	"native",
	"super",
	"while",
	"true",
	"false",
	"null",
	"var",
	"Actor",
	"AopsTheaterComponent",
	"Director",
	"Key",
	"Mouse",
	"Shape",
	"StateManager",
	"AopsInputStream",
	"AopsTheaterSerializer",
	"Ellipse",
	"Line",
	"PixiConverter",
	"StackOverflowError",
	"Text",
	"ClosedShape",
	"Image",
	"Main",
	"Polygon",
	"Stage",
	"TimeLimitExceededException",
	"Collider",
	"JsonHandler",
	"MethodJSONBuilder",
	"Rectangle",
	"StageComponent",
	"Vec2",
];

export type ValidationError =
	| {
			type: "error";
			message: string;
	  }
	| {
			type: "suspicious";
			message: string;
	  };

/**
 * Validates a Java class name (as a source, so dollar signs etc. are banned).
 * @param name The class name
 * @param fileList The files to check against, in case the class already exists
 * @return {string} Intelligible error if the class name is invalid or suspicious; empty string otherwise.
 */
export function validateClassName(
	name: string,
	fileList: string[]
): ValidationError | null {
	const err = (s: string) => ({type: "error" as const, message: s});
	const sus = (s: string) => ({type: "suspicious" as const, message: s});

	if (name === "") {
		return err(`Class name cannot be empty.`);
	}

	if (fileList.includes(name + ".java")) {
		return err(`Class name is already in use by AopsTheater.`);
	}

	if (KEYWORDS.includes(name)) {
		return err(`Class name cannot be a reserved word ${name}.`);
	}

	if (/\s/g.test(name)) {
		return err(`Class name cannot have spaces.`);
	}

	if (/^[0-9]/.test(name)) {
		return err(`Class name cannot start with a number.`);
	}

	if (name.startsWith("_")) {
		return sus(`Class name probably should not start with an underscore.`);
	}

	if (!/^[a-zA-Z_]+[a-zA-Z0-9_]*$/.test(name)) {
		return err(
			`Class name must only contain letters, numbers, and underscores.`
		);
	}

	return null;
}
