// ModalConfirm.tsx
import React from "react";
import SimpleModal, {SimpleModalType} from "./SimpleModal";

interface ModalConfirmType extends SimpleModalType {
	text: string;
	isErrorVariant?: boolean;
	options?: {
		confirmText?: string;
		cancelText?: string;
	};
}

export default function ModalConfirm(props: ModalConfirmType) {
	return <SimpleModal {...props}>{props.text}</SimpleModal>;
}
