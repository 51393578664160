import React, {useCallback} from "react";
import {PropsWithChildren} from "react";
import {IModal, Modal} from "../../internal/Modal/Modal";
import {OverlayTriggerState} from "react-stately";
import {useOverlayTrigger} from "react-aria";
import {Dialog} from "../../internal/Dialog/Dialog";
import {SpaceTokenName} from "../../types";

export interface IUseModalDialog extends PropsWithChildren {
	/** Whether the ModalDialog is shown */
	readonly isOpen: boolean;
	/** Setter for `isOpen` */
	readonly setIsOpen: (newValue: boolean) => void;
	/**
	 * Whether to close the modal when the user click outside it or presses Escape on the keyboard. Default is `false`
	 */
	readonly isDismissable?: boolean;
	/**
	 * A title for the Dialog, which is required for accessibility.
	 */
	readonly dialogTitle: string;
	/** Padding inside the Dialog */
	readonly padding?: 0 | SpaceTokenName;

	/* Set a defined starting width for the modal */
	readonly inlineSize?: IModal["inlineSize"];
}

interface IModalDialog extends PropsWithChildren {
	/** Bottom element separate from the Dialog inner padding */
	readonly atBottom?: JSX.Element;
}

/**
 * Get a controlled ModalDialog with associated triggerProps to spread over the button which opens the ModalDialog.
 *
 * @returns `ModalDialog` and spreadable `triggerProps` which semantically connect the trigger to the ModalDialog
 */
export function useModalDialog({
	isOpen,
	setIsOpen,
	isDismissable = false,
	dialogTitle,
	padding = "space-3",
	inlineSize,
}: IUseModalDialog) {
	const state: OverlayTriggerState = {
		isOpen,
		setOpen: setIsOpen,
		open: () => setIsOpen(true),
		close: () => setIsOpen(false),
		toggle: () => setIsOpen(!isOpen),
	};

	const {overlayProps, triggerProps} = useOverlayTrigger(
		{type: "dialog"},
		state
	);

	const ModalDialog = useCallback(
		({children, atBottom}: IModalDialog) =>
			isOpen ? (
				<Modal
					inlineSize={inlineSize}
					state={state}
					isDismissable={isDismissable}
				>
					<Dialog
						title={dialogTitle}
						padding={padding}
						atBottom={atBottom}
						{...overlayProps}
					>
						{children}
					</Dialog>
				</Modal>
			) : (
				<></>
			),
		[isOpen]
	);

	return {
		triggerProps,
		ModalDialog,
	};
}
