import React, {useRef} from "react";
import {PropsWithChildren} from "react";
import {AriaDialogProps, useDialog} from "react-aria";
import {CSSWithVariables} from "../../types";
import styles from "./Dialog.module.css";
import {SpaceTokenName} from "../../types";
import {makeSpaceCSSVariable} from "../flattenObjectToCSS";

export interface IDialog extends PropsWithChildren, AriaDialogProps {
	readonly title?: string;
	readonly padding?: 0 | SpaceTokenName;
	readonly backgroundColor?: CSSWithVariables["backgroundColor"];
	readonly atBottom?: JSX.Element;
}

/**
 * Dialog
 *
 * @param props.children
 * @returns
 */
export function Dialog({
	title,
	children,
	padding = "space-2",
	backgroundColor = "var(--color-background, white)",
	atBottom,
	...props
}: IDialog) {
	const ref = useRef(null);
	const {dialogProps, titleProps} = useDialog(props, ref);

	return (
		<div {...dialogProps} ref={ref} className={styles.dialog}>
			<div
				style={{
					border: 0,
					padding: makeSpaceCSSVariable(padding),
					backgroundColor,
				}}
			>
				{title && (
					<h3 {...titleProps} style={{marginTop: 0}}>
						{title}
					</h3>
				)}
				{children}
			</div>
			{atBottom}
		</div>
	);
}
