// Modal.tsx

import React, {ReactNode} from "react";
import styles from "./Modal.module.scss";

interface ModalType {
	children?: ReactNode;
	isOpen: boolean;
	pinToTop?: boolean;
	toggle: () => void;
}

export default function Modal(props: ModalType) {
	return (
		<>
			{props.isOpen && (
				<div
					className={[
						styles.modalOverlay,
						props.pinToTop ? styles.absoluteOverlay : "",
					].join(" ")}
					onClick={props.toggle}
				>
					<div
						onClick={(e) => e.stopPropagation()}
						className={[
							styles.modalBox,
							props.pinToTop ? styles.pinToTop : "",
						].join(" ")}
					>
						{props.children}
					</div>
				</div>
			)}
		</>
	);
}
