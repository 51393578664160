import styles from "./ConsoleToast.module.scss";
import React from "react";

type Props = {
	message: string | JSX.Element;
	type: "error" | "success";
	onClickDismiss?: () => void;
};

/**
 * A toast message that appears right above the console.
 */
const ConsoleToast: React.FC<Props> = (props: Props) => {
	const {message, type, onClickDismiss} = props;

	const classNames = [styles.consoleToastContainer, styles[type]];

	return (
		<div className={classNames.join(" ")}>
			<div className={styles.messageContainer}>{message}</div>
			<button className={styles.dismissButton} onClick={onClickDismiss}>
				Dismiss
			</button>
		</div>
	);
};

export default ConsoleToast;
