import React, {useCallback, useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {
	Panel,
	PanelGroup,
	PanelResizeHandle,
	ImperativePanelHandle,
} from "react-resizable-panels";

import MonacoEditor from "../CodeEditor/MonacoEditor";
import CodeMirrorEditor from "../CodeEditor/CodeMirrorEditor";
import LogConsole from "../Console/LogConsole";
import MethodManifestModal from "../Modals/MethodManifestModal";
import styles from "./Ide.module.scss";
import {useCsContext} from "../../state/CsInstance";

export const RESIZE_BAR_HEIGHT_IN_PIXELS = 4;
export const COLLAPSED_CONSOLE_SIZE_IN_PIXELS = 48; // 3rem
export const MIN_CONSOLE_SIZE_IN_PIXELS = 200;
export const EDITOR_HEADER_HEIGHT_IN_PIXELS = 48; // 3rem

/**
 * Calculate the height style for a panel based on the percentage of the parent,
 * accounting for our single resize bar.
 * @param percentageHeight percentage of the parent height
 * @param extraHeightPx Optional extra pixels to subtract from the height
 */
export const getHeightStyle = (
	percentageHeight: number,
	extraHeightPx?: number
) => {
	const amtOfResizeBarTaken = Math.floor(
		(RESIZE_BAR_HEIGHT_IN_PIXELS * percentageHeight) / 100
	);
	let heightStyle = `calc(${percentageHeight}vh - ${amtOfResizeBarTaken}px)`;
	if (extraHeightPx) {
		// Remove closing paren, then add extra height and closing paren back
		heightStyle = heightStyle.slice(0, -1) + ` - ${extraHeightPx}px)`;
	}
	return heightStyle;
};

const Ide: React.FC = () => {
	const {theaterStore, featureFlagStore} = useCsContext();

	const wrapperRef = useRef<HTMLDivElement>(null);
	const consoleRef = useRef<ImperativePanelHandle>(null);

	const toggleConsole = useCallback(
		(opts?: {show: boolean}) => {
			const panel = consoleRef.current;
			if (panel) {
				if ((opts && opts.show) || (!opts && panel.isCollapsed())) {
					panel.expand();
				} else {
					panel.collapse();
				}
			}
		},
		[consoleRef]
	);

	// Manually calculate the minimum and collapsed sizes of the console panel in
	// percentage viewheight, since the resizable-panels library doesn't support
	// other units per https://github.com/bvaughn/react-resizable-panels/pull/176
	// https://github.com/bvaughn/react-resizable-panels/issues/46#issuecomment-1368108416
	const [collapsedConsolePercent, setCollapsedConsolePercent] =
		React.useState(10);
	const [minConsolePercent, setMinConsolePercent] = React.useState(20);
	const fitIde = () => {
		if (!wrapperRef.current) return;
		let height = wrapperRef.current.offsetHeight;
		height -= RESIZE_BAR_HEIGHT_IN_PIXELS; // we only have one resize bar
		setMinConsolePercent((MIN_CONSOLE_SIZE_IN_PIXELS * 100) / height);
		setCollapsedConsolePercent(
			(COLLAPSED_CONSOLE_SIZE_IN_PIXELS * 100) / height
		);
	};
	React.useEffect(() => {
		if (!wrapperRef.current) return;
		fitIde();
		const resizeObserver = new ResizeObserver(fitIde);
		resizeObserver.observe(wrapperRef.current);
		return () => {
			resizeObserver.disconnect();
		};
	}, [wrapperRef]);

	const [editorVh, setEditorVh] = React.useState<number>(10);
	const [consoleVh, setConsoleVh] = React.useState<number>(10);

	useEffect(() => {
		toggleConsole({show: theaterStore.showConsole});
	}, [theaterStore.showConsole, toggleConsole]);

	return (
		<div ref={wrapperRef} className={styles.ideWrapper}>
			<PanelGroup
				autoSaveId="ide"
				direction={"vertical"}
				onLayout={(layout) => {
					const [newEditorVh, newConsoleVh] = layout as [number, number]; // valid since we have two panels
					setEditorVh(newEditorVh);
					setConsoleVh(newConsoleVh);
				}}
			>
				<Panel defaultSize={100} minSize={20}>
					{featureFlagStore.useMonaco ? (
						<MonacoEditor percentageHeight={editorVh} />
					) : (
						<CodeMirrorEditor percentageHeight={editorVh} />
					)}
				</Panel>
				<PanelResizeHandle className={styles.resizeHandle} />
				<Panel
					ref={consoleRef}
					collapsible={true}
					collapsedSize={collapsedConsolePercent}
					minSize={minConsolePercent}
					onCollapse={() => {
						theaterStore.showConsole = false;
					}}
					onExpand={() => {
						theaterStore.showConsole = true;
					}}
				>
					<LogConsole
						percentageHeight={consoleVh}
						toggleConsole={toggleConsole}
					/>
				</Panel>
				<MethodManifestModal />
			</PanelGroup>
		</div>
	);
};

export default observer(Ide);
