/**
 * Typebox types used for runtime checks.  These cannot be declared in .d.ts files.
 */

import {Type, type Static} from "@sinclair/typebox";

// Tickets originating from the worker

export const WorkerTicketT = Type.Object({
	id: Type.Number(),
	func: Type.Union([
		Type.Literal("initCode"),
		Type.Literal("initCanvas"),
		Type.Literal("runTests"),
		Type.Literal("resetCode"),
		Type.Literal("togglePixi"),
		Type.Literal("stopPixi"),
		Type.Literal("requestUpdate"),
		Type.Literal("initialized"),
		Type.Literal("update"),
		Type.Literal("getMethodsJSON"),
		Type.Literal("invokeMethod"),
		Type.Literal("getJavaConsoleLog"),
		Type.Literal("resetJavaConsoleLog"),
		Type.Literal("forceClearLog"),
		Type.Literal("workerThreadSetSharedArray"),
		Type.Literal("sendStudentInput"),
		Type.Literal("requestStudentInput"),
	]),
	arg: Type.Union([Type.Number(), Type.String(), Type.Null()]),
});

export const CsProjectTypebox = Type.Object({
	name: Type.String(),
	"ide-mode": Type.String(),
	files: Type.Array(
		Type.Object({
			name: Type.String(),
			attributes: Type.Optional(
				Type.Object({
					hidden: Type.Optional(Type.Boolean()),
					isMarkdown: Type.Optional(Type.Boolean()),
					isReadOnly: Type.Optional(Type.Boolean()),
					open: Type.Optional(Type.Boolean()),
				})
			),
			content: Type.String(),
		})
	),
});

export type CsProjectT = Static<typeof CsProjectTypebox>;
