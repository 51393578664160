import React from "react";
import {useCsContext} from "../../state/CsInstance";
import {observer} from "mobx-react-lite";

import {
	PIXI_RENDER_HEIGHT,
	PIXI_RENDER_WIDTH,
} from "../../state/userInputStore";

import styles from "./CoordinateDisplay.module.css";

type Props = {
	style?: React.CSSProperties;
};

const OFFSET = 10;

function CoordinateDisplay({style}: Props) {
	const {userInputStore, uiStore, compileStore} = useCsContext();
	const {mouseX, mouseY, pixiScale} = userInputStore;

	if (
		!uiStore.showCoordinates ||
		compileStore.error ||
		!userInputStore.mouseInBounds
	) {
		return <></>;
	}

	let offsetTop = (PIXI_RENDER_HEIGHT / 2 - mouseY) * pixiScale + OFFSET;
	let offsetLeft = (PIXI_RENDER_WIDTH / 2 + mouseX) * pixiScale + OFFSET;

	if (offsetTop + 16 > PIXI_RENDER_HEIGHT * pixiScale) {
		offsetTop -= 32;
	}
	if (offsetLeft + 60 > PIXI_RENDER_WIDTH * pixiScale) {
		offsetLeft -= 120;
	}

	return (
		<div
			className={styles.text}
			style={{
				top: offsetTop,
				left: offsetLeft,
				zIndex: 1,
				...style,
			}}
		>
			{mouseX.toFixed(1)}, {mouseY.toFixed(1)}
		</div>
	);
}

export default observer(CoordinateDisplay);
