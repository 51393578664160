import React, {PropsWithChildren, useContext, useMemo, useRef} from "react";
import {
	AriaRadioProps,
	VisuallyHidden,
	useFocusRing,
	useRadio,
} from "react-aria";
import {RadioGroupContext} from "./RadioGroup";
import {RadioContext} from "./RadioContext";
import styles from "./Radio.module.css";
import {useMode} from "../../internal/Mode/useMode";

export interface IRadio extends PropsWithChildren, AriaRadioProps {
	isCustom?: boolean;
}

/**
 * A default or custom radio button for a RadioGroup. If custom,
 * wrap the component with {@link Radio} and consume the RadioContext:
 *
 * ```
 * <Radio isCustom value="exampleValue">
 *   <RadioCard value="exampleValue" />
 * </Radio>
 *
 * function RadioCard({value}: {value: string}) {
 *		const {isSelected, isKeyboardFocused, isFocused, isPressed} = useContext(RadioContext);
 * 		// Use values to style and render card
 * }
 * ```
 */
export function Radio({isCustom, ...props}: IRadio) {
	const ref = useRef(null);
	const state = useContext(RadioGroupContext);

	const VisuallyHiddenIfCustom = useMemo(
		() =>
			isCustom
				? ({children}: PropsWithChildren) => (
						<VisuallyHidden>{children}</VisuallyHidden>
				  )
				: ({children}: PropsWithChildren) => children,
		[isCustom]
	);
	const {inputProps, isDisabled, isPressed, isSelected} = useRadio(
		props,
		state,
		ref
	);
	const {isFocused, isFocusVisible, focusProps} = useFocusRing();
	const {style} = useMode("radio");

	return (
		<label
			className={!isCustom && isDisabled ? styles.disabledLabel : undefined}
		>
			<VisuallyHiddenIfCustom>
				<input
					{...inputProps}
					{...focusProps}
					required={state?.isRequired}
					ref={ref}
					className={styles.defaultRadio}
					style={style}
				/>
			</VisuallyHiddenIfCustom>
			<RadioContext.Provider
				value={{
					isFocused,
					isKeyboardFocused: isFocusVisible,
					isDisabled,
					isPressed,
					isSelected,
				}}
			>
				{props.children}
			</RadioContext.Provider>
		</label>
	);
}
