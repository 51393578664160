import {EditorView} from "@uiw/react-codemirror";
import {useCsContext} from "../state/CsInstance";
export type ThemeOptions = {
	dark?: boolean;
};

export default function useCodeMirrorTheme(opts?: ThemeOptions) {
	const {theaterStore} = useCsContext();

	const fontSize = theaterStore.editorFontSize + "px"; // handle font size changing

	// see https://codemirror.net/examples/styling/
	return EditorView.theme(
		{
			"&": {
				fontSize,
				backgroundColor: "white",
			},

			// gutters
			".cm-gutters": {
				backgroundColor: "var(--cm-gutters-background-color)",
				marginTop: "-2px", // align gutters with content including borders
				borderColor: "var(--cm-gutters-border-color)",
			},
			".cm-gutter.cm-lineNumbers .cm-gutterElement": {
				color: "var(--cm-linenumbers-gutterelement-color)",
				paddingLeft: "2rem",
			},
			".cm-gutter.cm-foldGutter .cm-gutterElement": {
				padding: "0 0.5rem",
			},
			".cm-gutter .cm-gutterElement.cm-activeLineGutter": {
				color: "black",
			},

			// content
			".cm-line, .cm-gutterElement": {
				sizing: "border-box",
				border: "1px solid var(--transparent)",
			},
			// active line
			".cm-activeLine.cm-line, .cm-activeLineGutter.cm-gutterElement": {
				borderTop: "1px solid var(--cm-activeline-border-color)",
				borderBottom: "1px solid var(--cm-activeline-border-color)",
			},
		},
		{dark: opts ? !!opts.dark : false}
	);
}
