import React from "react";
import {observer} from "mobx-react-lite";
import {useCsContext} from "../../state/CsInstance";
import styles from "./PixiCanvasButtons.module.scss";
import {faPlay} from "@fortawesome/sharp-solid-svg-icons/faPlay";
import {faPause} from "@fortawesome/sharp-solid-svg-icons/faPause";
import {faForwardStep} from "@fortawesome/sharp-solid-svg-icons/faForwardStep";
import {faArrowsRotate} from "@fortawesome/sharp-solid-svg-icons/faArrowsRotate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CompileStatus} from "../../state/compileStore";

// This interface allows us to pass variables and callback function into a component
type CustomInputProps = {
	onClickRunButton: React.MouseEventHandler<HTMLButtonElement>;
	onClickStepButton(): void;
	onClickResetButton(): void;
	buttonClass?: string;
};

const PixiCanvasButtons: React.FC<CustomInputProps> = (props) => {
	const {theaterStore, compileStore} = useCsContext();
	const {buttonClass} = props;
	const resetButtonDisabled =
		theaterStore.messengerLoading ||
		compileStore.isCompiling ||
		compileStore.compileStatus === CompileStatus.Error;
	const controlsDisabled =
		resetButtonDisabled ||
		theaterStore.studentInputRequested ||
		theaterStore.runtimeErrorOccurred;

	return (
		<div id="pixi-buttons" className={styles.pixiButtonsWrapper}>
			<button
				className={[
					styles.theaterControlButton,
					buttonClass || "",
					resetButtonDisabled ? styles.disabled : "",
				].join(" ")}
				id="stepButton"
				disabled={controlsDisabled}
				onClick={props.onClickStepButton}
			>
				<FontAwesomeIcon icon={faForwardStep} />
				<span>Step</span>
			</button>
			<button
				className={[
					styles.theaterControlButton,
					styles.playPauseButton,
					buttonClass || "",
					controlsDisabled ? styles.disabled : "",
				].join(" ")}
				id="runPauseButton"
				disabled={controlsDisabled}
				onClick={props.onClickRunButton}
			>
				{theaterStore.isRunning ? (
					<>
						<FontAwesomeIcon icon={faPause} />
						<span>Pause</span>
					</>
				) : (
					<>
						<FontAwesomeIcon icon={faPlay} />
						<span>Play</span>
					</>
				)}
			</button>
			<button
				className={[
					styles.theaterControlButton,
					buttonClass || "",
					resetButtonDisabled ? styles.disabled : "",
				].join(" ")}
				disabled={resetButtonDisabled}
				onClick={props.onClickResetButton}
			>
				<FontAwesomeIcon icon={faArrowsRotate} />
				<span>Reset</span>
			</button>
		</div>
	);
};

export default observer(PixiCanvasButtons);
