import {createContext} from "react";

export const ThemeContext = createContext({
	defaults: {},
	palette: {
		border: {},
		borderRadius: {},
		boxShadow: {},
		color: {},
		outline: {},
		space: {
			1: "0.25rem",
			2: "1rem",
			3: "2rem",
			4: "4rem",
			5: "5rem",
			6: "6rem",
			7: "7rem",
		},
	},
	name: "",
	modes: {},
	isDebug: false,
});
