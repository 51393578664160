import React, {useContext} from "react";
import {useRef} from "react";
import {AriaBreadcrumbItemProps, useBreadcrumbItem} from "react-aria";
import clsx from "clsx";
import {BreadcrumbSeparatorContext} from "./Breadcrumbs";
import styles from "./Breadcrumbs.module.css";

interface ICrumbBase extends Omit<AriaBreadcrumbItemProps, "isCurrent"> {}

interface ICrumbHref extends ICrumbBase {
	href: string;
	wrap?: undefined;
}

export interface ICrumbWrap extends ICrumbBase {
	wrap: React.JSX.Element;
	href?: undefined;
}

export type ICrumb = ICrumbHref | ICrumbWrap;

/**
 * A link inside `Breadcrumbs`. For custom links, use `wrap`.
 */
export function Crumb({wrap, ...props}: ICrumb) {
	const ref = useRef(null);
	const {itemProps} = useBreadcrumbItem(props, ref);
	const {separator} = useContext(BreadcrumbSeparatorContext);

	const linkElement = wrap ? (
		React.cloneElement(wrap, {children: props.children, ref, ...itemProps})
	) : (
		<a
			{...itemProps}
			ref={ref}
			className={styles.breadcrumbItemLink}
			href={props.href}
		>
			{props.children}
		</a>
	);

	return (
		<li
			className={clsx(styles.breadcrumbItem, {
				[styles.breadcrumbItemDisabled]: props.isDisabled,
			})}
		>
			{linkElement}
			<span aria-hidden="true" className={styles.breadcrumbItemArrow}>
				{separator}
			</span>
		</li>
	);
}
