import React, {ReactNode, useContext, useMemo, useRef} from "react";
import {DismissButton, Overlay, usePopover} from "react-aria";
import type {AriaPopoverProps} from "react-aria";
import type {OverlayTriggerState} from "react-stately";
import styles from "./Popover.module.css";
import {useMode} from "../Mode/useMode";
import {ThemeContext} from "../../theme/ThemeContext";
import {flattenObjectToCSSVariables} from "../flattenObjectToCSS";
import {merge} from "lodash";

export interface IPopover
	extends Omit<AriaPopoverProps, "popoverRef" | "hasArrow"> {
	readonly children: ReactNode;
	readonly state: OverlayTriggerState;
}

/**
 * Popover
 */
export function Popover({children, state, offset = 6, ...props}: IPopover) {
	const popoverRef = useRef(null);

	const {popoverProps, underlayProps} = usePopover(
		{
			...props,
			offset,
			popoverRef,
		},
		state
	);
	const {style} = useMode("popover");
	const {palette} = useContext(ThemeContext);
	const mergedStyle = useMemo(
		() =>
			merge(
				{},
				popoverProps.style,
				style,
				flattenObjectToCSSVariables(palette, {}, "--theme")
			),
		[popoverProps.style, style, palette]
	);

	return (
		<Overlay>
			<div {...underlayProps} className={styles.underlay} />
			<div
				{...popoverProps}
				ref={popoverRef}
				className={styles.popover}
				style={mergedStyle}
			>
				<DismissButton onDismiss={state.close} />
				{children}
				<DismissButton onDismiss={state.close} />
			</div>
		</Overlay>
	);
}
