import React, {useRef, useMemo} from "react";
import {AriaMenuProps, useMenu} from "react-aria";
import {useTreeState} from "react-stately";
import {MenuItem} from "./MenuItem";
import styles from "./Menu.module.css";
import {useMode} from "../Mode/useMode";
import {CSSWithVariables} from "../../types";

export function Menu<T extends object>(props: AriaMenuProps<T>) {
	const ref = useRef(null);
	const state = useTreeState(props);
	const {menuProps} = useMenu(props, state, ref);
	const items = useMemo(() => [...state.collection], [state.collection]);
	const {style} = useMode("menuButton");

	return (
		<ul {...menuProps} ref={ref} className={styles.menu} style={style}>
			{items.map((item) => (
				<MenuItem key={item.key} item={item} state={state} />
			))}
		</ul>
	);
}
