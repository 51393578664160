import React, {PropsWithChildren, useMemo, useRef} from "react";
import {AriaButtonProps, useButton} from "react-aria";
import {useMode} from "../Mode/useMode";

export function StepButton({
	className,
	...props
}: PropsWithChildren & AriaButtonProps & {className?: string}) {
	const ref = useRef(null);
	const {buttonProps} = useButton(props, ref);
	const {style} = useMode("select");

	return (
		<button {...buttonProps} ref={ref} style={style} className={className}>
			{props.children}
		</button>
	);
}
