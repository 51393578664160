import clsx from "clsx";
import React, {useRef} from "react";
import {useMenuItem} from "react-aria";
import {TreeState} from "react-stately";
import styles from "./Menu.module.css";

interface IMenuItem<T> {
	readonly item: any;
	readonly state: TreeState<T>;
}

export function MenuItem<T extends object>({item, state}: IMenuItem<T>) {
	const ref = useRef(null);
	const {menuItemProps, isFocused, isSelected, isDisabled} = useMenuItem(
		{key: item.key},
		state,
		ref
	);

	return (
		<li
			{...menuItemProps}
			ref={ref}
			className={clsx(styles.menuItem, {
				[styles.menuItemFocused]: isFocused,
				[styles.menuItemDisabled]: isDisabled,
			})}
		>
			{item.rendered}
			{isSelected && <span aria-hidden="true">✅</span>}
		</li>
	);
}
