import {makeAutoObservable} from "mobx";

export type SettingsModalModes = "settings" | "fontSubsettings";

/**
 * This store is used to manage the UI state. Things that should go here should
 * be things that don't affect how the Theater or Pixi play -- for example,
 * whether a modal is open, or whether to show coordinates.
 */
export class UiStore {
	private _theaterSettingsOpen = false;
	private _theaterSettingsModalMode: SettingsModalModes = "settings";
	private _shouldCloseTheaterSettingsModal = true;
	private _showCoordinates = true;
	private _isSidebarShown = true;
	private _showTheater = true;

	constructor() {
		makeAutoObservable(this);
	}

	toggleTheater() {
		this._showTheater = !this._showTheater;
	}

	// autogen getters and setters for MobX actions

	get theaterSettingsOpen() {
		return this._theaterSettingsOpen;
	}

	set theaterSettingsOpen(value) {
		// When opening/closing the modal, default to basic settings mode.
		if (value !== this._theaterSettingsOpen) {
			this.theaterSettingsModalMode = "settings";
		}
		this._theaterSettingsOpen = value;
	}

	get theaterSettingsModalMode() {
		return this._theaterSettingsModalMode;
	}

	set theaterSettingsModalMode(value) {
		this._theaterSettingsModalMode = value;
	}

	get shouldCloseTheaterSettingsModal() {
		return this._shouldCloseTheaterSettingsModal;
	}

	set shouldCloseTheaterSettingsModal(value) {
		this._shouldCloseTheaterSettingsModal = value;
	}

	get showCoordinates() {
		return this._showCoordinates;
	}

	set showCoordinates(value) {
		this._showCoordinates = value;
	}

	get isSidebarShown() {
		return this._isSidebarShown;
	}

	set isSidebarShown(value) {
		this._isSidebarShown = value;
	}
}

export default UiStore;
