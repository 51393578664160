import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useCsContext} from "../../state/CsInstance";
import styles from "./CompileButton.module.scss";
import {CompileStatus} from "../../state/compileStore";

export type Props = {
	onClick: () => void;
	disabled?: boolean;
};

const DOTS_INTERVAL = 200;
const TOTAL_DOTS = 3;

function CompileButton({onClick, disabled}: Props) {
	const {compileStore, testsStore} = useCsContext();
	const hasTests = testsStore.hasTests;

	const [dots, setDots] = React.useState(0);
	const dotIntervalRef = React.useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		// clear old interval
		dotIntervalRef.current && clearInterval(dotIntervalRef.current);
		// set a new one
		if (compileStore.compileStatus === CompileStatus.Compiling) {
			setDots(0);
			dotIntervalRef.current = setInterval(() => {
				setDots((_dots) => _dots + 1);
			}, DOTS_INTERVAL);
		}
	}, [compileStore.compileStatus]);

	const numShownDots = (dots % TOTAL_DOTS) + 1; // leave +1 for readability
	const dotElements = new Array(3).fill(0).map((_, i) => {
		return i < numShownDots ? (
			<span className={styles.shownDot} key={i}>
				.
			</span>
		) : (
			<span className={styles.hiddenDot} key={i}>
				.
			</span>
		);
	});

	// lie to kids so they don't get confused -- compiling = initializing
	const isCompiling = compileStore.compileStatus === CompileStatus.Compiling;

	let compilationDisplayElement: React.JSX.Element;
	if (isCompiling) {
		compilationDisplayElement = <>Compiling{dotElements}</>;
	} else {
		compilationDisplayElement = <>{"Compile" + (hasTests ? " & Test" : "")}</>;
	}

	return (
		<button
			className={[
				styles.compileButton,
				disabled ? styles.disabled : "",
				isCompiling ? styles.isCompiling : "",
				!isCompiling && hasTests ? styles.hasTests : "",
			].join(" ")}
			disabled={isCompiling || disabled}
			onClick={onClick}
		>
			{compilationDisplayElement}
		</button>
	);
}

export default observer(CompileButton);
