import CsInstance from "../state/CsInstance";

const KnownSidebarMethods = [
	"documentation",
	"toggleTheater",
	"imageAssets",
	"getReadMe",
	"settings",
] as const;

type SidebarMethods = {
	[K in (typeof KnownSidebarMethods)[number]]: {
		onClick: (csInstance: CsInstance) => void;
		eventListenerCallback?: () => void;
	};
};

const sidebarMethods = {
	toggleTheater: {
		onClick: (csInstance: CsInstance) => {
			csInstance.uiStore.toggleTheater();
		},
		eventListenerCallback: () => {
			//TODO
		},
	},
	documentation: {
		onClick: () => {
			window.open(
				"https://cs-curriculum-assets.artofproblemsolving.com/docs/AopsTheater/package-summary.html",
				"_blank"
			);
		},
	},
	imageAssets: {
		onClick: () => {
			window.open(
				"https://cs-curriculum-assets.artofproblemsolving.com/view/index.html",
				"_blank"
			);
		},
	},
	getReadMe: {
		onClick: () => {},
	},
	settings: {
		onClick: () => {},
	},
} satisfies SidebarMethods;

export default sidebarMethods;
