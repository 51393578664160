import {makeAutoObservable} from "mobx";
import {TestResults} from "../@types/Types";

/**
 * This store is used to manage user-run tests and their results.
 */
export class TestsStore {
	private _hasTests = false;
	private _testResults: TestResults | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	// autogen getters and setters for MobX actions

	get hasTests() {
		return this._hasTests;
	}

	set hasTests(value) {
		this._hasTests = value;
	}

	get testResults() {
		return this._testResults;
	}

	set testResults(value) {
		this._testResults = value;
	}
}

export default TestsStore;
