/**
 * Entry point for building the client code. Does not automatically start things
 * up; instead exports a render function that gets passed a DOM element.
 * Used to embed the client in non-React environments.
 */

import React from "react";
import CsHome, {CsHomeProps} from "./pages/Home";
import {createRoot} from "react-dom/client";
import {CsInstanceTypeEnum} from "./state/CsInstance";
// import TorchboardRouter from "./pages/Router";
//  import {useTorchAppState} from "./state/TorchboardAppState";
// import {Session} from "./shared/Session";

type StartupProps = {
	// pageData is json stringified data from the server
	pageData: string;
	serverUrl?: string;
};

function render(container: HTMLElement, props: StartupProps) {
	const pageData = JSON.parse(props.pageData);
	const csHomeProps: CsHomeProps = {serverUrl: props.serverUrl};
	if (pageData && typeof pageData.securityToken === "string") {
		csHomeProps.securityToken = pageData.securityToken;
	}

	const root = createRoot(container);
	const mainEl = React.createElement(CsHome, csHomeProps);
	root.render(mainEl);
}

/**
 * For rendering a snippet: no theater, just the editor and console.
 */
function renderSnippet(container: HTMLElement, props: StartupProps) {
	const root = createRoot(container);
	const mainEl = React.createElement(CsHome, {
		serverUrl: props.serverUrl,
		csInstanceType: CsInstanceTypeEnum.Editor,
	});
	root.render(mainEl);
}

export {render, renderSnippet};
