import React, {useMemo} from "react";
import type {AnchorHTMLAttributes, PropsWithChildren} from "react";
import styles from "../Button/Button.module.css";
import {CSSWithVariables, Padding} from "../../types";
import {useMode} from "../../internal/Mode/useMode";
import {makeSpaceCSSVariable} from "../../internal/flattenObjectToCSS";

export interface ILinkButton
	extends PropsWithChildren,
		AnchorHTMLAttributes<HTMLAnchorElement> {
	padding?: Padding;
	href?: string;
	wrap?: React.JSX.Element;
}

/**
 * Accept clicks and keyboard presses from user on a link styled like a button.
 *
 * @param props.children
 * @returns
 */
export function LinkButton({children, padding, wrap, ...props}: ILinkButton) {
	const paddingStyle = {
		"--button-padding": makeSpaceCSSVariable(padding ?? "space-2"),
	} as CSSWithVariables;

	const {style} = useMode("button");
	const mergedStyle = useMemo(
		() => ({...paddingStyle, ...style, textDecoration: "none"}),
		[style, paddingStyle]
	);

	const wrappedNoUnderlineAndInheritColor = useMemo(
		() =>
			wrap
				? React.cloneElement(wrap, {
						children,
						style: {
							...mergedStyle,
							...wrap.props.style,
						},
						className: styles.button
				  })
				: undefined,
		[wrap]
	);

	return wrap ? wrappedNoUnderlineAndInheritColor : (
		<a {...props} className={styles.button} style={mergedStyle}>
			{children}
		</a>
	);
}
