import {makeAutoObservable} from "mobx";
import type {FunctionTemplate} from "../@types/Types";

export const PIXI_RENDER_WIDTH = 800;
export const PIXI_RENDER_HEIGHT = 600;

/**
 * MobX store for state that is specific to PixiJS.
 */
class UserInputStore {
	private _isPixiFocused = false;
	private _mouseX = 0;
	private _mouseY = 0;
	private _mouseInBounds = false;
	private _isMouseDown = false;
	private _fps = 0;
	private _pixiScale = 1;

	// Map from key code to key name. Key is only present if the key is pressed.
	private _pressedEventCodeToName = new Map<string, string>();
	private _spriteUuid = "";

	// Pixi Interactive Variables
	private _functionChosen = {
		name: "",
		parameterTypes: [],
		returnType: "",
	} as FunctionTemplate;
	private _waitingForInvokeFunction = false;

	constructor() {
		makeAutoObservable(this);
	}

	keydownListener = (event: KeyboardEvent) => {
		if (this._isPixiFocused) {
			this.pressedEventCodeToName.set(event.code, event.key);
		}
	};

	keyupListener = (event: KeyboardEvent) => {
		// See https://stackoverflow.com/questions/11818637
		// Deselect all keys when the meta key is released as a best effort
		if (event.code.startsWith("Meta") /* MetaLeft | MetaRight */) {
			this.pressedEventCodeToName.clear();
		} else {
			if (this._isPixiFocused) {
				this.pressedEventCodeToName.delete(event.code);
			}
		}
	};

	blurListener = () => {
		this.pressedEventCodeToName.clear();
	};

	addKeyboardListeners() {
		document.addEventListener("keydown", this.keydownListener);
		document.addEventListener("keyup", this.keyupListener);
		window.addEventListener("blur", this.blurListener);
	}

	removeKeyboardListeners() {
		document.removeEventListener("keydown", this.keydownListener);
		document.removeEventListener("keyup", this.keyupListener);
		window.removeEventListener("blur", this.blurListener);
	}

	/**
	 * Return a list of keys pressed as an array of the form { code: string, key: string } -- as expected by the
	 * Java API.
	 */
	getKeysPressed() {
		return [...this._pressedEventCodeToName.entries()].map(([code, key]) => {
			return {key: key, code: code};
		});
	}

	// autogen getters and setters for MobX actions

	get isPixiFocused() {
		return this._isPixiFocused;
	}

	set isPixiFocused(value) {
		this._isPixiFocused = value;
	}

	get mouseX() {
		return this._mouseX;
	}

	set mouseX(value) {
		this._mouseX = value;
	}

	get mouseY() {
		return this._mouseY;
	}

	set mouseY(value) {
		this._mouseY = value;
	}

	get mouseInBounds() {
		return this._mouseInBounds;
	}

	set mouseInBounds(value) {
		this._mouseInBounds = value;
	}

	get isMouseDown() {
		return this._isMouseDown;
	}

	set isMouseDown(value) {
		this._isMouseDown = value;
	}

	get fps() {
		return this._fps;
	}

	set fps(value) {
		this._fps = value;
	}

	get pressedEventCodeToName() {
		return this._pressedEventCodeToName;
	}

	set pressedEventCodeToName(value) {
		this._pressedEventCodeToName = value;
	}

	get spriteUuid() {
		return this._spriteUuid;
	}

	set spriteUuid(value) {
		this._spriteUuid = value;
	}

	get functionChosen() {
		return this._functionChosen;
	}

	set functionChosen(value) {
		this._functionChosen = value;
	}

	get waitingForInvokeFunction() {
		return this._waitingForInvokeFunction;
	}

	set waitingForInvokeFunction(value) {
		this._waitingForInvokeFunction = value;
	}

	get pixiScale() {
		return this._pixiScale;
	}

	set pixiScale(value) {
		this._pixiScale = value;
	}
}

export default UserInputStore;
