// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DragBar-module__dragBar--obq0J {
  flex: 0 0 auto;
  width: var(--dragbar-width);
  display: flex;
  align-items: center;
  border-left: var(--thin) solid var(--dragbar-background-color);
  border-right: var(--thin) solid var(--dragbar-background-color);
  padding: 0.125rem;
  background-color: var(--dragbar-background-color);
  user-select: none;
}
.DragBar-module__dragBar--obq0J:hover {
  background-color: var(--dragbar-hover-background-color);
  border-left: var(--thin) solid var(--dragbar-hover-background-color);
  border-right: var(--thin) solid var(--dragbar-hover-background-color);
  cursor: pointer;
}

.DragBar-module__dragBar--obq0J:hover > .DragBar-module__dragBarHandle--tFEBC {
  visibility: visible;
}

.DragBar-module__dragBarHandle--tFEBC {
  position: absolute;
  margin-left: calc(-0.5rem - 1.25px);
  z-index: 2;
  visibility: hidden;
}

.DragBar-module__icon--VuKKz {
  font-size: 1.125rem;
  padding-left: 1px;
}

.DragBar-module__iconOutline--Yh4bK {
  color: white;
  font-size: x-large;
}`, "",{"version":3,"sources":["webpack://./src/components/Dragbar/DragBar.module.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EAEA,8DAAA;EACA,+DAAA;EACA,iBAAA;EACA,iDAAA;EACA,iBAAA;AAAD;AAEC;EACC,uDAAA;EACA,oEAAA;EACA,qEAAA;EACA,eAAA;AAAF;;AAIA;EACC,mBAAA;AADD;;AAIA;EACC,kBAAA;EACA,mCAAA;EAGA,UAAA;EACA,kBAAA;AAHD;;AAMA;EACC,mBAAA;EACA,iBAAA;AAHD;;AAMA;EACC,YAAA;EACA,kBAAA;AAHD","sourcesContent":[".dragBar {\n\tflex: 0 0 auto;\n\twidth: var(--dragbar-width);\n\tdisplay: flex;\n\talign-items: center;\n\n\tborder-left: var(--thin) solid var(--dragbar-background-color);\n\tborder-right: var(--thin) solid var(--dragbar-background-color);\n\tpadding: 0.125rem;\n\tbackground-color: var(--dragbar-background-color);\n\tuser-select: none;\n\n\t&:hover {\n\t\tbackground-color: var(--dragbar-hover-background-color);\n\t\tborder-left: var(--thin) solid var(--dragbar-hover-background-color);\n\t\tborder-right: var(--thin) solid var(--dragbar-hover-background-color);\n\t\tcursor: pointer;\n\t}\n}\n\n.dragBar:hover > .dragBarHandle {\n\tvisibility: visible;\n}\n\n.dragBarHandle {\n\tposition: absolute;\n\tmargin-left: calc(\n\t\t-0.5rem - 1.25px\n\t); // 1.25px is some offset because the outline is a little larger\n\tz-index: 2;\n\tvisibility: hidden;\n}\n\n.icon {\n\tfont-size: 1.125rem;\n\tpadding-left: 1px;\n}\n\n.iconOutline {\n\tcolor: white;\n\tfont-size: x-large;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragBar": `DragBar-module__dragBar--obq0J`,
	"dragBarHandle": `DragBar-module__dragBarHandle--tFEBC`,
	"icon": `DragBar-module__icon--VuKKz`,
	"iconOutline": `DragBar-module__iconOutline--Yh4bK`
};
export default ___CSS_LOADER_EXPORT___;
