import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import styles from "./MethodManifestModal.module.scss";

import type {FunctionTemplate} from "../../@types/Types";
import type {MetadataParameter} from "../../shared/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/sharp-solid-svg-icons/faXmark";
import {faCircleExclamation} from "@fortawesome/sharp-solid-svg-icons/faCircleExclamation";
import {faArrowTurnDownLeft} from "@fortawesome/sharp-solid-svg-icons/faArrowTurnDownLeft";
import {faDash} from "@fortawesome/sharp-solid-svg-icons/faDash";
import {useCsContext} from "../../state/CsInstance";

type Props = {
	setIsCalling: (isCalling: boolean) => void;
	functionCall: FunctionTemplate;
	setMethodCall: (methodObj: FunctionTemplate) => void;
	methodCallClass: string;
	setMethodCallClass: (methodClass: string) => void;
	toggle: () => void;
};

type FunctionInputs = {
	[key: string]: {
		value: string;
		error: boolean;
	};
};

type Parameter = {
	type: string;
	value: string | number | boolean | null;
};

function MethodCall(props: Props) {
	const {getOrCreateMessenger, compileStore, theaterStore} = useCsContext();

	const [functionCallString, setFunctionCallString] = useState("");
	const [paramInputs, setParamInputs] = useState<FunctionInputs>({});
	const {functionCall} = props;

	const methodParamNames: MetadataParameter[] = getMethodParameterNames();

	if (!functionCall || Object.keys(functionCall).length === 0) {
		goBack();
		return;
	}

	const canSubmit = validateParameters();

	const onKeyDownHandler = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case "Enter":
				// TODO This doesn't work because the focus element is not right
				if (functionCallString) {
					closeModal();
				} else if (canSubmit) {
					submit();
				}
				break;
			default:
				break;
		}
	};

	// Returns the parameter names for the method as an array of strings
	function getMethodParameterNames(): MetadataParameter[] {
		const allMethods = compileStore.metadatas.method_map;

		for (const method of Object.values(allMethods)) {
			if (
				method.method_name === functionCall.name &&
				method.source_file === `${props.methodCallClass}.java` &&
				method.parameters.length === functionCall.parameterTypes.length
			) {
				return method.parameters;
			}
		}
		return [];
	}

	// Updates the parameter input value and error state
	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		const {name, value} = event.target;

		const temp: FunctionInputs = {[name]: {value: value, error: false}};

		const type = functionCall.parameterTypes[parseInt(name)];

		temp[name]!.error = !checkInputType(value, type);

		setParamInputs({
			...paramInputs,
			...temp,
		});
	}

	// Check that all parameters are valid
	// Returns true if all parameters are valid, false otherwise
	function validateParameters(): boolean {
		for (let i = 0; i < functionCall.parameterTypes.length; i++) {
			const type = functionCall.parameterTypes[i];
			const value = paramInputs[i]?.value ?? "";

			if (!checkInputType(value, type)) {
				return false;
			}
		}

		return true;
	}

	// Check if the input value is valid for the given type
	// Returns true if the input is valid, false otherwise
	// TODO: Add more robust type checks for longs, shorts, bytes, and floats.
	function checkInputType(value: string, type: string | undefined): boolean {
		switch (type) {
			case "int" || "long" || "short" || "byte":
				return value !== "" && /^-?\d+$/.test(value);
			case "double" || "float":
				return value !== "" && /^-?\d*\.?\d*$/.test(value);
			case "boolean":
				return value !== "" && /^(true|false)$/.test(value);
			default:
				return value !== "";
		}
	}

	// Sets up the request object and stringifies it for the invokeMethod request
	function createInvokeMethodJsonString(): string {
		const parameters: Parameter[] = [];

		functionCall.parameterTypes.map((paramType, index) => {
			if (!paramInputs[index]) {
				return;
			}
			const value = paramInputs[index]?.value ?? "";

			switch (paramType) {
				case "boolean":
					const boolValue = value === "true";
					parameters.push({type: "boolean", value: boolValue});
					break;
				case "int" || "long" || "short" || "byte":
					const intValue = parseInt(value);
					parameters.push({type: "int", value: intValue});
					break;
				case "double" || "float":
					const doubleValue = parseFloat(value);
					parameters.push({type: "double", value: doubleValue});
					break;
				case "String":
					parameters.push({type: "java.lang.String", value: value});
					break;
				default:
					parameters.push({type: paramType, value: value});
			}
		});

		const data = {
			method: functionCall.name,
			parameters: parameters,
		};

		const jsonString = JSON.stringify(data);

		return jsonString;
	}

	// Builds the function call string and invokes the method
	// by sending the request to the server
	function submit() {
		let func = `${functionCall.name}(`;

		for (let i = 0; i < functionCall.parameterTypes.length; i++) {
			func += paramInputs[i]?.value;
			if (i !== functionCall.parameterTypes.length - 1) {
				func += ", ";
			}
		}
		func += ")";
		setFunctionCallString(func);

		getOrCreateMessenger().invokeMethod(createInvokeMethodJsonString());
	}

	// Resets the modal to its initial state
	// which effectively returns to the method list component
	function goBack() {
		props.setIsCalling(false);
		props.setMethodCall({} as FunctionTemplate);
		setFunctionCallString("");
		setParamInputs({});
	}

	// Resets the modal to its initial state
	// Then closes the modal
	function closeModal() {
		goBack();
		props.toggle();
	}

	function renderMethodParameters() {
		return (
			<div className={styles.methodParameterColumn}>
				{functionCall.parameterTypes.map((param, index) => (
					<div
						key={functionCall.name + index}
						className={styles.methodParameterColumn}
					>
						<div className={styles.methodParameter}>
							<input
								className={[
									styles.methodParameterInput,
									paramInputs[String(index)]?.error ? styles.error : "",
								].join(" ")}
								name={String(index)}
								placeholder={`${param} ${
									methodParamNames[index]?.name || "arg"
								}`}
								onChange={onChange}
								autoFocus={index === 0}
							/>
							{index === functionCall.parameterTypes.length - 1 ? ")" : ","}
						</div>
						{paramInputs[String(index)]?.error && (
							<div className={styles.errorText}>
								This parameter requires a {param}.{" "}
								<FontAwesomeIcon icon={faCircleExclamation} />
							</div>
						)}
					</div>
				))}
			</div>
		);
	}

	return (
		<div className={styles.modalContainer} onKeyDown={onKeyDownHandler}>
			<div className={styles.modalHeaderContainer}>
				<div className={styles.modalHeader}>Method Call</div>
				<button className={styles.modalXIconWrapper}>
					<FontAwesomeIcon
						icon={faXmark}
						className={styles.modalXIcon}
						onClick={closeModal}
					/>
				</button>
			</div>
			<div className={styles.methodCallContainer}>
				{functionCall.returnType === "void" ? (
					<FontAwesomeIcon className={styles.methodCallIcon} icon={faDash} />
				) : (
					<FontAwesomeIcon
						className={styles.methodCallIcon}
						icon={faArrowTurnDownLeft}
					/>
				)}
				{functionCall.returnType} {functionCall.name} (
				{functionCall.parameterTypes.join(", ")})
			</div>
			<div className={styles.methodDescription}>
				{/* TODO: Add Javadoc description when we get it */}
			</div>
			{functionCallString ? (
				<>
					<div className={styles.methodParameterContainer}>
						<div className={styles.methodNameCall}>Your call:</div>
						<input
							className={styles.methodParameterInput}
							value={functionCallString}
							disabled
						/>
					</div>
					{theaterStore.invokeResult && (
						<div className={styles.methodParameterContainer}>
							<div className={styles.methodNameCall}>Returned:</div>
							{typeof theaterStore.invokeResult === "string" ? (
								<div className={styles.methodInvokeResult}>
									{theaterStore.invokeResult}
								</div>
							) : (
								<button className={styles.methodInvokeResultObject}>
									{/* TODO: Display objects and implement invoke chaining */}
								</button>
							)}
						</div>
					)}
				</>
			) : (
				<div className={styles.methodParameterContainer}>
					<div className={styles.methodNameCall}>
						{functionCall.name}(
						{functionCall.parameterTypes.length === 0 ? ")" : ""}
					</div>
					{renderMethodParameters()}
				</div>
			)}
			<div className={styles.buttonContainer}>
				<button
					className={styles.cancelButton}
					onClick={() => {
						functionCallString ? closeModal() : goBack();
					}}
				>
					{functionCallString ? "Close" : "Go Back"}
				</button>
				{!functionCallString && (
					<button
						className={styles.submitButton}
						onClick={submit}
						disabled={!canSubmit}
					>
						Submit
					</button>
				)}
			</div>
		</div>
	);
}

export default observer(MethodCall);
