import React from "react";
import {useCsContext} from "../../state/CsInstance";

import styles from "./TheaterSettingsModal.module.css";

import {SettingsModalModes} from "../../state/uiStore";
import {observer} from "mobx-react-lite";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBracketsCurly} from "@fortawesome/sharp-solid-svg-icons/faBracketsCurly";
import {selectAndIndentWholeDocument} from "../Ide/indentExtension";
import {EditorView} from "@uiw/react-codemirror";
import {faLocationCrosshairs} from "@fortawesome/sharp-solid-svg-icons/faLocationCrosshairs";

type SettingsButton = {
	key: string;
	content: JSX.Element;
	onClick: () => void;
	extraClassName?: string;
};
function TheaterSettingsModal() {
	const {uiStore} = useCsContext();
	if (!uiStore.theaterSettingsOpen) {
		return <></>;
	}

	/**
	 * The buttons that appear in the settings modal for each mode.
	 * Assumes that each button is one row and formatted the same way.
	 */
	const settingsModalButtonsMap: Map<SettingsModalModes, SettingsButton[]> =
		new Map([
			[
				"settings",
				[
					{
						key: "toggleCoordinates",
						content: (
							<>
								<FontAwesomeIcon
									className={styles.icon}
									icon={faLocationCrosshairs}
								/>
								{uiStore.showCoordinates ? "Hide" : "Show"} Coordinates
							</>
						),
						onClick: () => {
							uiStore.shouldCloseTheaterSettingsModal = true;
							uiStore.showCoordinates = !uiStore.showCoordinates;
						},
					},
					{
						key: "autoIndentCode",
						content: (
							<>
								<FontAwesomeIcon
									className={styles.icon}
									icon={faBracketsCurly}
								/>
								Auto Indent Code
							</>
						),
						onClick: () => {
							const view = EditorView.findFromDOM(document.body);
							if (!view) {
								return;
							}
							selectAndIndentWholeDocument(view);
						},
					},
				],
			],
		]);

	const buttons = settingsModalButtonsMap.get(uiStore.theaterSettingsModalMode);
	if (!buttons) {
		throw new Error(
			`No buttons found for mode ${uiStore.theaterSettingsModalMode}`
		);
	}

	return (
		<div className={styles.modalOuter}>
			{buttons.map((button) => {
				const className = [styles.modalRow, button.extraClassName].join(" ");
				return (
					<button
						key={button.key}
						className={className}
						onClick={button.onClick}
					>
						{button.content}
					</button>
				);
			})}
		</div>
	);
}

export default observer(TheaterSettingsModal);
