/**
 * Converts the output of an exception's printStackTrace call to a list of StackTraceEntry objects.
 */

/**
 * A single line of a stack trace entry.
 */
export type StackTraceEntry = {
	line?: number;
	functionName?: string;
	isStudentCode: boolean;
};

/**
 * Parses the output of an exception's printStackTrace call to a list of StackTraceEntry objects.
 * @param lines The lines as outputted from console log.
 * @param reportedStack The stack trace as reported by the injector/AopsTheater. Cheerp gives us an Int32Array.
 */
export function parseStackTraceEntries(
	lines: string[],
	reportedStack: number[] | Int32Array
) {
	const entries: StackTraceEntry[] = [];

	let i = reportedStack.length - 1;
	for (const line of lines) {
		const studentCodeMatch = line.match(/(StudentCode\.[^(]+)\(.*\)?/);

		let packedLineNumber = reportedStack[i];
		if (packedLineNumber !== undefined) packedLineNumber &= 0xffff; // get low 16 bits, which contains line #

		if (studentCodeMatch) {
			// Full match, match group
			// e.g. ["StudentCode$$$1.foo(Unknown source)", "StudentCode$$$1.foo"]
			let [_, fnName] = studentCodeMatch;

			// For rather technical reasons, invokedynamic (which is how lambdas are implemented) creates an extra
			// trampoline entry on the stack that does not appear in the original code, containing the name "$$Lambda",
			// so we just skip it.
			//
			// This is actually a subtle difference from normal Java, which ignores these in the stack trace (as far
			// as I can tell).
			if (fnName?.includes("$$Lambda")) {
				continue;
			}

			// Remove suffix $$$<version> from function name
			fnName = fnName?.replace(/\$\$\$[0-9]+/g, "");

			i--;

			entries.push({
				line: packedLineNumber,
				functionName: fnName,
				isStudentCode: true,
			});
			continue;
		}

		const theaterMatch = line.match(/AopsTheater\.[^(]+/g);
		if (theaterMatch) {
			// Implies that the function is within AopsTheater and shouldn't be renamed
			const isInjectionExemptFunction = line.match(
				/AopsTheater\.(AopsTheater|TimeLimitExceededException)\./g
			);

			if (!isInjectionExemptFunction) i--;

			entries.push({
				line: packedLineNumber,
				functionName: theaterMatch[0],
				isStudentCode: false,
			});
		}
	}

	return entries;
}
